import Select from '@/lib/ui/select.tsx';
import { useMemo } from 'react';
import { useTranslation } from '@/hooks/useTranslation.ts';

export enum PaymentMethodEnum {
  CASH = 'CASH',
  TRANSFER = 'TRANSFER',
  TARGET = 'TARGET',
  STRIPE = 'STRIPE',
  PAYMENT_LINK = 'PAYMENT_LINK',
  BITCOIN = 'BITCOIN'
}

export default function PaymentMethodSelect(props: { label?: string }) {
  const { t } = useTranslation();

  const options = useMemo(() => {
    return [
      {
        value: PaymentMethodEnum.STRIPE,
        label: t(`methods_payment.${PaymentMethodEnum.STRIPE}` as LanguageKeys)
      },
      {
        label: t(`methods_payment.${PaymentMethodEnum.CASH}` as LanguageKeys),
        value: PaymentMethodEnum.CASH
      },
      {
        value: PaymentMethodEnum.TARGET,
        label: t(`methods_payment.${PaymentMethodEnum.TARGET}` as LanguageKeys)
      },
      {
        value: PaymentMethodEnum.TRANSFER,
        label: t(
          `methods_payment.${PaymentMethodEnum.TRANSFER}` as LanguageKeys
        )
      },
      {
        value: PaymentMethodEnum.BITCOIN,
        label: t(`methods_payment.${PaymentMethodEnum.BITCOIN}` as LanguageKeys)
      },
      {
        value: PaymentMethodEnum.PAYMENT_LINK,
        label: t(
          `methods_payment.${PaymentMethodEnum.PAYMENT_LINK}` as LanguageKeys
        )
      }
    ];
  }, [t]);

  return <Select {...props} options={options} />;
}
