import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Divider,
  CircularProgress,
  Button
} from '@nextui-org/react';

import Text from '@/lib/ui/text.tsx';
import { cn } from '@/lib/utils.ts';
import { useMemo } from 'react';
import dayjs from 'dayjs';
import AddOrEditCredits from '@/app/app/clients/components/CreditsCard/AddOrEditCredits.tsx';
import useToggle from '@/hooks/useToggle.ts';
import { TheLastCredit } from '@/app/app/clients/components/CreditsCard/type.ts';
import useSelectEditItem from '@/hooks/useSelectEditItem.ts';
import { CREDIT_STATUS_ENUM } from '@/app/app/clients/common/enums/credit.enum.ts';
import { useTranslation } from '@/hooks/useTranslation.ts';

const getColorIndicator = (percentage: number) => {
  if (percentage <= 25) {
    return 'stroke-danger';
  } else if (percentage <= 50) {
    return 'stroke-warning';
  }
  return 'stroke-success';
};

const getTextColorIndicator = (percentage: number) => {
  if (percentage <= 25) {
    return 'text-danger';
  } else if (percentage <= 50) {
    return 'text-warning';
  }
  return 'text-success';
};

const getTrackColorIndicator = (percentage: number) => {
  if (percentage <= 25) {
    return 'stroke-danger/10';
  } else if (percentage <= 50) {
    return 'stroke-warning/10';
  }
  return 'stroke-success/10';
};

export default function CreditsCard({
  lastCredit,
  clientId,
  paymentPreference
}: {
  clientId: string;
  paymentPreference?: string | null;
  lastCredit: TheLastCredit | null;
}) {
  const { t } = useTranslation();
  const [isOpen, actionOpen] = useToggle();
  const [editRecord, onEditItem] = useSelectEditItem<TheLastCredit>(actionOpen);

  const { creditsAvailable, percentage } = useMemo(() => {
    if (!lastCredit)
      return {
        percentage: 0,
        creditsAvailable: 0
      };

    const creditUsed = lastCredit?.creditUsed ?? 0;
    const buy = lastCredit?.creditBuy ?? 0;
    const creditsAvailable = buy - creditUsed;

    return {
      creditsAvailable,
      percentage: (1 - creditUsed / buy) * 100
    };
  }, [lastCredit]);

  const isExpired = useMemo(() => {
    if (!lastCredit?.expiredAt) return true;
    if (lastCredit?.status === CREDIT_STATUS_ENUM.EXPIRED) return true;
    return dayjs(lastCredit?.expiredAt).isBefore(dayjs());
  }, [lastCredit?.expiredAt, lastCredit?.status]);

  return (
    <>
      <Card className="p-2 md:p-5 h-full">
        <CardHeader>
          <Text className="font-bold text-2xl">
            {t('subscription_package')}
          </Text>
        </CardHeader>
        <CardBody>
          <Divider />
        </CardBody>
        {isExpired ? (
          <CardBody className="flex-auto gap-4">
            <Text className="text-center">{t('not_credits_available')}</Text>
            <Button
              color="primary"
              variant="flat"
              className="w-fit mx-auto"
              onClick={actionOpen.onVisible}
            >
              {t('add_subscription_package')}
            </Button>
          </CardBody>
        ) : (
          <>
            <CardBody
              className={cn('flex justify-center items-center flex-col gap-2')}
            >
              <CircularProgress
                value={percentage}
                showValueLabel
                classNames={{
                  svg: 'w-36 h-36 drop-shadow-md',
                  indicator: getColorIndicator(percentage),
                  track: getTrackColorIndicator(percentage),
                  value: cn(
                    'text-3xl font-semibold',
                    getTextColorIndicator(percentage)
                  )
                }}
              />
              <Text className="font-bold">
                {creditsAvailable} {t('credits_available')}
              </Text>
            </CardBody>
            <CardFooter className="flex flex-col justify-center items-center w-full">
              <Button
                variant="flat"
                color="primary"
                onClick={() => onEditItem(lastCredit)}
              >
                {t('edit_class_credits')}
              </Button>
            </CardFooter>
          </>
        )}
      </Card>

      <AddOrEditCredits
        isOpen={isOpen}
        clientId={clientId}
        lastCredit={lastCredit}
        editRecord={editRecord}
        paymentPreference={paymentPreference}
        onClose={() => {
          actionOpen.onHidden();
          onEditItem(null);
        }}
      />
    </>
  );
}
