import BreadcrumbsApp from '@/app/app/shared/components/BreadcrumbsApp.tsx';
import Container, { ContainerProps } from '@/lib/ui/container.tsx';
import { cn } from '@/lib/utils.ts';

export default function AppContainer({
  children,
  layoutClassName,
  showBreadcrumbs = true,
  ...props
}: ContainerProps & { showBreadcrumbs?: boolean; layoutClassName?: string }) {
  return (
    <Container {...props}>
      {showBreadcrumbs && <BreadcrumbsApp />}
      <div className={cn(showBreadcrumbs ? 'mt-10' : '', layoutClassName)}>
        {children}
      </div>
    </Container>
  );
}
