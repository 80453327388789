import { RouteObject } from 'react-router/dist/lib/context';
import { PublicRotes } from '@/routes/public-rotes.ts';
import { cleanUrl } from '@/routes/utils.ts';
import { AUTH_ROUTES } from '@/app/auth/_common/routes.ts';
import LoginPage from '@/app/auth/login/page.tsx';
import CompleteProfilePage from '@/app/auth/complete-profile/page.tsx';
import SignupPage from '@/app/auth/signup/page.tsx';
import LogoutPage from '@/app/auth/logout/page.tsx';
import SelectSpacePage from '@/app/auth/select-space/page.tsx';
import ResetPasswordPage from '@/app/auth/reset-password/page.tsx';
import layout from './layout.tsx';
import CreateSpacePage from '@/app/auth/create-space/page.tsx';

const routes: RouteObject[] = [
  {
    path: cleanUrl(AUTH_ROUTES.LOGIN, PublicRotes.AUTH),
    element: <LoginPage />
  },
  {
    path: cleanUrl(AUTH_ROUTES.COMPLETE_PROFILE, PublicRotes.AUTH),
    element: <CompleteProfilePage />
  },
  {
    path: cleanUrl(AUTH_ROUTES.SIGNUP, PublicRotes.AUTH),
    element: <SignupPage />
  },
  {
    path: cleanUrl(AUTH_ROUTES.LOGOUT, PublicRotes.AUTH),
    element: <LogoutPage />
  },
  {
    path: cleanUrl(AUTH_ROUTES.SELECT_SPACE, PublicRotes.AUTH),
    element: <SelectSpacePage />
  },
  {
    path: cleanUrl(AUTH_ROUTES.RESET_PASSWORD, PublicRotes.AUTH),
    element: <ResetPasswordPage />
  },
  {
    path: cleanUrl(AUTH_ROUTES.CREATE_SPACE, PublicRotes.AUTH),
    element: <CreateSpacePage />
  }
];

export { routes, layout };
