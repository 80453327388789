import { createBrowserRouter, RouterProvider } from 'react-router-dom';

import { PublicRotes } from '@/routes/public-rotes.ts';
import { PrivateRotes } from '@/routes/private-rotes.ts';
import InitPage from '@/app/page.tsx';
import PrivacyPage from '@/app/privacy';
import * as app from '@/app/app';
import * as auth from '@/app/auth';

const routes = createBrowserRouter([
  {
    path: PublicRotes.HOME,
    element: <InitPage />
  },
  {
    path: PublicRotes.PRIVACY,
    element: <PrivacyPage />
  },
  {
    path: PublicRotes.AUTH,
    Component: auth.layout,
    children: auth.routes
  },
  {
    path: PrivateRotes.APP,
    Component: app.layout,
    children: app.routes
  }
]);

export default function Routes() {
  return <RouterProvider router={routes} />;
}
