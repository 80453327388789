import { FormItem } from 'reactivity-hook-form';

import { useTranslation } from '@/hooks/useTranslation';
import { NewSessionFormType } from '@/app/app/sessions/common/types.ts';
import { DatePicker } from '@/lib/ui/date-picker.tsx';

export function DateField() {
  const { t } = useTranslation();

  return (
    <FormItem<NewSessionFormType> name="date">
      <DatePicker label={t('form.date')} />
    </FormItem>
  );
}
