import {
  Radio,
  RadioGroup as NextUIRadioGroup,
  RadioGroupProps
} from '@nextui-org/react';
import { cn } from '@/lib/utils.ts';
import { ReactNode } from 'react';

export const CustomRadio = (props: any) => {
  const { children, ...otherProps } = props;

  return (
    <Radio
      {...otherProps}
      classNames={{
        base: cn(
          'inline-flex m-0 bg-default-100 hover:bg-content2 items-center justify-between',
          'flex-row-reverse max-w-full cursor-pointer rounded-lg gap-4 p-4 border-2 border-transparent',
          'data-[selected=true]:border-primary'
        )
      }}
    >
      {children}
    </Radio>
  );
};

export type RadioInternalGroupProps = RadioGroupProps & {
  label?: ReactNode;
  description?: ReactNode;
  items: {
    label: ReactNode;
    value: string;
    description: ReactNode;
  }[];
};

export default function RadioGroup({
  items,
  description,
  label,
  ...rest
}: RadioInternalGroupProps) {
  return (
    <NextUIRadioGroup {...rest} label={label} description={description}>
      {items.map((item, index) => {
        const key = `${item.label?.toString?.()}-${index}`;

        return (
          <CustomRadio key={key} description={item.label} value={item.value}>
            {item.description}
          </CustomRadio>
        );
      })}
    </NextUIRadioGroup>
  );
}
