import * as React from 'react';
import { cn } from '@/lib/utils.ts';

interface GridProps {
  children: React.ReactNode;
  spacing?: number;
  className?: string;
  style?: React.CSSProperties;
  xs?: number;
  sm?: number;
  md?: number;
  lg?: number;
  xl?: number;
  '2xl'?: number;
}

const GridColumn: React.FC<GridProps> = ({
  children,
  spacing,
  className,
  xs,
  sm,
  md,
  lg,
  xl,
  '2xl': twoXl,
  ...rest
}) => {
  const spacingClass = spacing ? `gap-${spacing}` : '';

  const classes = {
    [`grid-cols-${xs}`]: !!xs,
    [`sm:grid-cols-${sm}`]: !!sm,
    [`md:grid-cols-${md}`]: !!md,
    [`lg:grid-cols-${lg}`]: !!lg,
    [`xl:grid-cols-${xl}`]: !!xs,
    [`2xl:grid-cols-${twoXl}`]: !!twoXl
  };

  return (
    <div className={cn('grid', classes, spacingClass, className)} {...rest}>
      {children}
    </div>
  );
};

export default GridColumn;
