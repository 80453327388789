import Form from 'reactivity-hook-form';
import { useForm } from 'react-hook-form';

import { UseToggleActions } from '@/hooks/useToggle';
import { useTranslation } from '@/hooks/useTranslation';
import {
  useCreateRepeatSession,
  useCreateSession,
  useUpdateRepeatSession,
  useUpdateSession
} from '@/app/app/sessions/store/mutation/session.mutation';
import { useValidations } from '@/app/app/sessions/components/create-edit-session/settings/useValidations';
import NewSessionForm from '@/app/app/sessions/components/create-edit-session/new-session-form';
import { NewSessionFormType } from '@/app/app/sessions/common/types';
import Container from '@/lib/ui/container.tsx';
import { useDependencies } from '@/app/app/sessions/components/create-edit-session/settings/useDependencies.ts';
import { CREATE_DEFAULT_VALUES } from '@/app/app/sessions/common/constant/default-values.ts';
import Modal from '@/lib/ui/modal.tsx';
import { SessionFragmentFragment } from '@/__generated__/graphql.ts';
import { useDialog } from '@/lib/ui/Dialog/Provider.tsx';

export type NewSessionProps = Pick<UseToggleActions, 'onHidden'> & {
  open: boolean;
  session?: DeepNonNullable<SessionFragmentFragment> | null;
};

const NEW_FORM_KEY = 'new-session';

export default function NewSession({
  open,
  onHidden,
  session
}: NewSessionProps) {
  const dialog = useDialog();
  const { t } = useTranslation();
  const validations = useValidations();
  const dependencies = useDependencies();
  const context = useForm<NewSessionFormType>({
    mode: 'onChange',
    defaultValues: CREATE_DEFAULT_VALUES(session || null)
  });

  // UPDATE MUTATIONS
  const { onUpdateRepeatSession, loading: loadingUpdateR } =
    useUpdateRepeatSession();
  const { onUpdateSession, loading: loadingS } = useUpdateSession();

  // CREATE MUTATIONS
  const { onCreateSession, loading } = useCreateSession();
  const { onCreateRepeatSession, loading: loadingRepeat } =
    useCreateRepeatSession();

  const fullLoading = loadingRepeat || loading || loadingUpdateR || loadingS;

  const onSubmit = async (values: NewSessionFormType) => {
    if (session?.rootEvent) {
      dialog.open({
        title: t('update_session_alert.title'),
        children: t('update_session_alert.message'),
        actions: [
          {
            label: t('update_session_alert.update_this'),
            color: 'primary',
            variant: 'light',
            onClick: async () => {
              await onUpdateSession(session, values);
              onHidden();
            }
          },
          {
            label: t('update_session_alert.update_all'),
            color: 'primary',
            onClick: async () => {
              await onUpdateRepeatSession(session, values);
              onHidden();
            }
          }
        ]
      });
    } else if (session) {
      await onUpdateSession(session, values);
    } else {
      if (!values.isRepeatEvent) {
        await onCreateSession(values);
      } else {
        await onCreateRepeatSession(values);
      }
    }

    if (!session) {
      onHidden();
    }
  };

  return (
    <Modal
      size="full"
      isOpen={open}
      onClose={onHidden}
      scrollBehavior="outside"
      title={t('sessions.new_session')}
      actions={[
        {
          label: t('cancel'),
          variant: 'light',
          onPress: onHidden,
          isDisabled: fullLoading
        },
        {
          type: 'submit',
          color: 'primary',
          form: NEW_FORM_KEY,
          isLoading: fullLoading,
          label: t(session ? 'update' : 'save'),
          isDisabled:
            !Object.keys(context.formState.dirtyFields).length ||
            !!Object.keys(context.formState?.errors).length
        }
      ]}
    >
      <Container className="w-[850px] max-w-full">
        <Form<NewSessionFormType>
          id={NEW_FORM_KEY}
          onSubmit={onSubmit}
          formContext={context}
          validations={validations}
          dependencies={dependencies}
        >
          <NewSessionForm />
        </Form>
      </Container>
    </Modal>
  );
}
