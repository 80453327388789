import { toast } from 'sonner';
import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import Form, { FormItem } from 'reactivity-hook-form';

import Input from '@/lib/ui/input.tsx';
import {
  NewOrEditCreditsPayload,
  TheLastCredit
} from '@/app/app/clients/components/CreditsCard/type.ts';
import RadioGroup from '@/app/app/clients/components/CreditsCard/RadioGroup.tsx';
import { useGetPlans } from '@/app/app/settings/store/query/plans.query.ts';
import { formatMoney } from '@/lib/utils.ts';
import useDependency from '@/app/app/clients/components/CreditsCard/hooks/useDependency.ts';
import { DatePicker } from '@/lib/ui/date-picker.tsx';
import { PlanFragmentFragment } from '@/__generated__/graphql.ts';
import Text from '@/lib/ui/text.tsx';
import {
  SETTINGS_ROUTES,
  SETTINGS_TAB_ENUM
} from '@/app/app/settings/common/routes.ts';
import useValidation from '@/app/app/clients/components/CreditsCard/hooks/useValidation.ts';
import {
  useAddNewSubscription,
  useEditSubscription
} from '@/app/app/clients/store/mutation/clients.mutation.ts';
import useDefaultValues from '@/app/app/clients/components/CreditsCard/hooks/useDefaultValues.ts';
import GridColumn from '@/lib/ui/grid-column.tsx';
import Modal from '@/lib/ui/modal.tsx';
import PaymentMethodSelect from '@/app/app/clients/components/PaymentMethodSelect';
import { useTranslation } from '@/hooks/useTranslation.ts';

const FORM_NAME = 'new-edit-credits-subscription';

type AddOrEditCreditsProps = {
  clientId: string;
  paymentPreference?: string | null;
  isOpen: boolean;
  onClose: () => void;
  lastCredit: TheLastCredit | null;
  editRecord?: TheLastCredit | null;
};

export default function AddOrEditCredits({
  isOpen,
  onClose,
  clientId,
  lastCredit,
  editRecord,
  paymentPreference
}: AddOrEditCreditsProps) {
  const { t } = useTranslation();
  const validations = useValidation();
  const dependencies = useDependency();
  const defaultValues = useDefaultValues(editRecord, paymentPreference);

  const navigate = useNavigate();
  const { data, loading: loadingPlans } = useGetPlans();
  const {
    onNewSubscription,
    loading,
    error: errorNewSub
  } = useAddNewSubscription({ clientId });
  const {
    onEditSubscription,
    loading: loadingEdit,
    error: errorEditSub
  } = useEditSubscription({
    clientId
  });

  const plans = useMemo(
    () => (data?.plans ?? []) as PlanFragmentFragment[],
    [data?.plans]
  );

  const plansOptions = useMemo(() => {
    return plans.map((item) => ({
      value: item.id,
      description: item.name,
      label: `${item.points} ${t((item?.points ?? 0) <= 1 ? 'class' : 'classes')}. ${t('for')} ${formatMoney(item?.price ?? '')}`
    }));
  }, [plans, t]);

  const onSubmit = async (values: NewOrEditCreditsPayload) => {
    const totalCredits =
      Number(values?.selectedPlan?.points ?? 0) + Number(values.extra ?? 0);

    const payload = {
      openAt: values.openAt,
      expiredAt: values.expiredAt,
      totalPrice: values.totalPrice ?? '0',
      user: {
        connect: {
          id: clientId
        }
      },
      creditBuy: totalCredits,
      paymentMethod: values.paymentMethod,
      plan: {
        connect: {
          id: values.selectedPlan?.id
        }
      }
    };

    try {
      if (editRecord) {
        await onEditSubscription({
          data: payload,
          creditId: editRecord.id
        });
      } else {
        await onNewSubscription({ data: payload, plan: values.selectedPlan });
      }
      onClose();
      toast.success(
        editRecord ? 'Subscription edited' : 'Subscription created'
      );
    } catch (e) {
      const message = (e as Error)?.message;
      console.log(message);
    }
  };

  const errorMessage = useMemo(() => {
    const errText = errorNewSub?.message || errorEditSub?.message;
    if (errText?.includes('user does not have a stripe customer id')) {
      return t('errors_message.user_not_have_stripe_settings')
    }

    if (errText?.includes('user does not have a payment method')) {
      return t('errors_message.user_not_have_payment_methods')
    }
    return errText
  }, [errorNewSub?.message, errorEditSub?.message, t])

  const isLoading = loading || loadingEdit;

  if (!plansOptions.length && !loadingPlans) {
    return (
      <Modal
        backdrop="blur"
        isOpen={isOpen}
        onClose={onClose}
        isDismissable={false}
        title={t('add_subscription')}
        actions={[
          {
            label: t('cancel'),
            onClick: onClose,
            color: 'danger',
            variant: 'light'
          },
          {
            label: t('go'),
            onClick: () =>
              navigate(SETTINGS_ROUTES.TAB(SETTINGS_TAB_ENUM.plans)),
            color: 'primary'
          }
        ]}
      >
        <Text>{t('no_have_plan')}</Text>
      </Modal>
    );
  }

  return (
    <Modal
      size="xl"
      isOpen={isOpen}
      onClose={onClose}
      maskClosable={false}
      title={editRecord ? t('edit_subscription') : t('add_subscription')}
      actions={[
        {
          label: t('cancel'),
          onClick: onClose,
          color: 'danger',
          variant: 'light'
        },
        {
          label: editRecord ? t('save') : t('add'),
          type: 'submit',
          color: 'primary',
          form: FORM_NAME,
          isLoading
        }
      ]}
    >
      <Form<NewOrEditCreditsPayload>
        id={FORM_NAME}
        onSubmit={onSubmit}
        validations={validations}
        dependencies={dependencies}
        defaultValues={defaultValues}
      >
        {lastCredit?.plan && (
          <FormItem<NewOrEditCreditsPayload>
            name="lastCredit"
            onChange={(event, { field, context }) => {
              const { value } = event.target;
              context.setValue('selectedPlan', lastCredit?.plan ?? null);
              field.onChange(value);
            }}
          >
            <RadioGroup
              label={t('the_last_subscription')}
              items={[
                {
                  value: lastCredit?.plan?.id,
                  description: lastCredit?.plan?.name,
                  label: `${lastCredit?.plan?.points} ${t((lastCredit?.plan?.points ?? 0) <= 1 ? 'class' : 'classes')}. ${t('for')} ${formatMoney(lastCredit?.plan?.price ?? '')}`
                }
              ]}
            />
          </FormItem>
        )}
        <FormItem<NewOrEditCreditsPayload>
          name="plan"
          onChange={(event, { field, context }) => {
            const { value } = event.target;
            const plan = plans.find((item) => item.id === value);
            context.setValue('selectedPlan', plan ?? null);
            field.onChange(value);
          }}
        >
          <RadioGroup label={t('plans')} items={plansOptions} />
        </FormItem>
        <FormItem<NewOrEditCreditsPayload> name="paymentMethod">
          <PaymentMethodSelect label={t('payment_methods')} />
        </FormItem>
        <GridColumn xs={1} md={2} spacing={2}>
          <FormItem<NewOrEditCreditsPayload> name="openAt">
            <DatePicker label={t('start_date')} />
          </FormItem>
          <FormItem<NewOrEditCreditsPayload> name="expiredAt">
            <DatePicker label={t('end_date')} />
          </FormItem>
        </GridColumn>
        <GridColumn xs={1} md={12} spacing={2}>
          <FormItem<NewOrEditCreditsPayload>
            name="totalPrice"
            className="md:col-span-7"
          >
            <Input
              label={t('total_price')}
              startContent={<Text className="text-sm text-default-600">$</Text>}
            />
          </FormItem>
          <FormItem<NewOrEditCreditsPayload>
            name="extra"
            className="md:col-span-5"
          >
            <Input label={t('extra_classes')} />
          </FormItem>
        </GridColumn>
        {errorMessage && (
          <GridColumn xs={24}>
            <Text className="text-xs text-danger">{errorMessage}</Text>
          </GridColumn>
        )}
      </Form>
    </Modal>
  );
}
