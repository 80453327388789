import { Button } from '@nextui-org/react';
import { ChevronLeft } from 'lucide-react';
import { useNavigate } from 'react-router-dom';

import { useAuth } from '@/app/auth/_context';
import { AUTH_ROUTES } from '@/app/auth/_common/routes.ts';
import { useTranslation } from '@/hooks/useTranslation.ts';

export default function LogoutButton() {
  const { logout } = useAuth();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleLogout = async () => {
    await logout();
    navigate(AUTH_ROUTES.LOGIN);
  };

  return (
    <Button
      onClick={handleLogout}
      className="mt-2 flex gap-1"
      variant="bordered"
    >
      <ChevronLeft />
      {t('signup_back')}
    </Button>
  );
}
