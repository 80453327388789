import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { useAuth } from '../_context';
import { AUTH_ROUTES } from '@/app/auth/_common/routes';
import ScreenLoading from '@/lib/ui/screen-loading.tsx';

export default function LogoutPage() {
  const { logout } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    logout().then(() => {
      navigate(AUTH_ROUTES.LOGIN);
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [logout]);

  return <ScreenLoading />;
}
