import { PropsWithChildren, ReactNode } from 'react';

import Loading from '@/lib/ui/loading.tsx';
import { cn } from '@/lib/utils.ts';
import Title from '@/lib/ui/title.tsx';

export default function TabContainer({
  children,
  loading,
  header,
  title,
  action,
  className
}: PropsWithChildren<{
  loading?: boolean;
  header?: ReactNode;
  title: string;
  className?: string;
  action?: ReactNode;
}>) {
  return (
    <div
      className={cn(
        'gap-5 rounded-xl min-h-[50vh] flex flex-col px-0',
        className
      )}
    >
      {header}
      <div className="flex justify-between items-center">
        <Title size="lg">{title}</Title>
        {action}
      </div>

      <div
        className={cn({
          'flex flex-auto items-center justify-center h-full': loading
        })}
      >
        {loading ? <Loading /> : children}
      </div>
    </div>
  );
}
