import NavbarItem from '@/app/app/shared/components/navbar/navbar-item.tsx';
import { DASHBOARD_ROUTES } from '@/app/app/dashboard/common/routes.ts';
import { SESSION_ROUTES } from '@/app/app/sessions/common/routes.ts';
import { COACHES_ROUTES } from '@/app/app/coaches/common/routes.ts';
import { CLIENT_ROUTES } from '@/app/app/clients/common/routes.ts';
import { SETTINGS_ROUTES } from '@/app/app/settings/common/routes.ts';
import { useTranslation } from '@/hooks/useTranslation.ts';
import { cn } from '@/lib/utils.ts';
import { NOTIFICATIONS_ROUTES } from '@/app/app/notifications/common/routes.ts';

export default function NavbarItems({
  className,
  trigger,
  ...restCommonProps
}: CommonComponentProps & { trigger?: () => void }) {
  const { t } = useTranslation();

  return (
    <nav
      className={cn('flex items-center gap-6 text-sm', className)}
      {...restCommonProps}
    >
      <NavbarItem trigger={trigger} href={DASHBOARD_ROUTES.DASHBOARD}>
        {t('nav.dashboard')}
      </NavbarItem>
      <NavbarItem trigger={trigger} href={SESSION_ROUTES.HOME}>
        {t('nav.sessions')}
      </NavbarItem>
      <NavbarItem trigger={trigger} href={COACHES_ROUTES.HOME}>
        {t('nav.coaches')}
      </NavbarItem>
      <NavbarItem trigger={trigger} href={CLIENT_ROUTES.HOME}>
        {t('nav.clients')}
      </NavbarItem>
      <NavbarItem trigger={trigger} href={NOTIFICATIONS_ROUTES.HOME}>
        {t('nav.notifications')}
      </NavbarItem>

      <NavbarItem
        trigger={trigger}
        href={SETTINGS_ROUTES.BASE}
        replace={{
          key: '/:tab',
          value: ''
        }}
      >
        {t(`nav.settings`)}
      </NavbarItem>

      {/*<Dropdown*/}
      {/*  onOpenChange={setOpen}*/}
      {/*  items={Object.values(SETTINGS_TAB_ENUM).map((key) => ({*/}
      {/*    key,*/}
      {/*    children: (*/}
      {/*      <NavbarItem trigger={trigger} href={SETTINGS_ROUTES.TAB(key)}>*/}
      {/*        {t(`settings.${key}` as LanguageKeys)}*/}
      {/*      </NavbarItem>*/}
      {/*    )*/}
      {/*  }))}*/}
      {/*>*/}
      {/*  <div className="flex gap-1 items-center">*/}
      {/*    {t('nav.settings')}{' '}*/}
      {/*    {open ? (*/}
      {/*      <ChevronUp size={16} strokeWidth={3} />*/}
      {/*    ) : (*/}
      {/*      <ChevronDown size={16} strokeWidth={3} />*/}
      {/*    )}*/}
      {/*  </div>*/}
      {/*</Dropdown>*/}
    </nav>
  );
}
