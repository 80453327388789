import { useEffect } from 'react';
import type {
  NoInfer,
  QueryHookOptions,
  QueryResult
} from '@apollo/client/react/types/types';
import type {
  DocumentNode,
  OperationVariables,
  TypedDocumentNode
} from '@apollo/client/core';
import { useQuery } from '@apollo/client';

export default function useApolloInterval<
  TData = any,
  TVariables extends OperationVariables = OperationVariables
>(
  query: DocumentNode | TypedDocumentNode<TData, TVariables>,
  options?: Omit<
    QueryHookOptions<NoInfer<TData>, NoInfer<TVariables>>,
    'pollInterval'
  >,
  intervalInMs?: number
): QueryResult<TData, TVariables> {
  const queryOperation = useQuery<TData, TVariables>(query, options);

  // Maneja el cambio de visibilidad
  const handleVisibilityChange = () => {
    if (document.visibilityState === 'hidden') {
      queryOperation.stopPolling(); // Detiene el polling cuando el usuario cambia de pestaña
    } else {
      queryOperation.refetch(); // Hace un fetch inmediato de los datos al regresar
      queryOperation.startPolling(intervalInMs || 0); // Reanuda el polling
    }
  };

  // Agrega y remueve el event listener
  useEffect(() => {
    document.addEventListener('visibilitychange', handleVisibilityChange);

    // Limpieza al desmontar el componente
    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return queryOperation;
}
