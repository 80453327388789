import SessionsList from '@/app/app/sessions/components/sessions-list/sessions-list';
import { useTranslation } from '@/hooks/useTranslation.ts';
import HeaderPage from '@/app/app/shared/components/HeaderPage.tsx';
import AppContainer from '@/app/app/shared/components/AppContainer.tsx';
import { useCreateOrEditSession } from '@/app/app/sessions/components/create-edit-session/context';

export default function SessionPage() {
  const { t } = useTranslation();
  const { onOpen } = useCreateOrEditSession();

  return (
    <AppContainer>
      <HeaderPage
        title={t('sessions.title')}
        primaryAction={{
          text: t('sessions.new_session'),
          onClick: onOpen
        }}
      />

      <SessionsList />
    </AppContainer>
  );
}
