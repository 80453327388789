import { useEffect } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { VERIFY_TOKEN_QUERY } from '@/app/auth/_store/mutation/graphql/query.graphql.ts';
import { GET_USER_FIREBASE_MUTATION } from '@/app/auth/_store/mutation/graphql/mutation.graphql.ts';
import { UserCredential } from '@firebase/auth';

export function useVerifyToken(token: string | null) {
  return useQuery(VERIFY_TOKEN_QUERY, {
    skip: !token,
    fetchPolicy: 'network-only',
    variables: {
      token: token as string
    }
  });
}

export function useGetFirebaseUser(email?: string | null) {
  const [onGetFirebaseUser, rest] = useMutation(GET_USER_FIREBASE_MUTATION);

  useEffect(() => {
    if (email) {
      onGetFirebaseUser({
        variables: {
          email
        }
      }).then();
    }
  }, [email, onGetFirebaseUser]);

  return {
    ...rest,
    data: (rest?.data?.verifyExistEmail as UserCredential['user']) || null
  };
}
