export function isNullOrUndefined(value: unknown) {
  return value === null || value === undefined;
}

export function renderContentOrFallback<T = string, K = unknown>(
  value: unknown,
  fallback: T
) {
  return (isNullOrUndefined(value) ? fallback : value) as K;
}

export function deepClone<T = unknown>(obj: any): T {
  if (obj === null || typeof obj !== 'object') {
    return obj;
  }
  if (Array.isArray(obj)) {
    const arrCopy: any = [];
    obj.forEach((item, index) => {
      arrCopy[index] = deepClone(item);
    });
    return arrCopy;
  }
  const objCopy: any = {};
  Object.keys(obj).forEach((key) => {
    objCopy[key] = deepClone(obj?.[key]);
  });
  return objCopy;
}

export function isEmail(value: string) {
  return /\S+@\S+\.\S+/.test(value);
}

export function isSameObject<T = Record<string, unknown>>(a: T, b: T) {
  return JSON.stringify(a || {}) === JSON.stringify(b || {});
}

export const copyToClipboard = async (text: string) => {
  const el = document.createElement('textarea');
  el.value = text;
  document.body.appendChild(el);
  el.select();
  el.setSelectionRange(0, 99999);

  await navigator.clipboard.writeText(el.value);
  document.body.removeChild(el);
};
