import Loading from '@/lib/ui/loading.tsx';
import GenericCard from '@/lib/ui/generic-card.tsx';
import { useGetTotalRevenue } from '@/app/app/dashboard/store/query/dashboard.query.ts';
import { useTranslation } from '@/hooks/useTranslation.ts';
import { formatMoney } from '@/lib/utils.ts';
import dayjs from 'dayjs';

export default function TotalRevenueByClient() {
  const { t } = useTranslation();
  const { data, loading } = useGetTotalRevenue();

  const diff =
    Number(data?.currentTotalByClient || 0) -
    Number(data?.beforeTotalByClient || 0);

  return (
    <GenericCard
      variant="secondary"
      title={`${t('total_by_client')} (${dayjs().format('MMM YYYY')})`}
      className="w-full"
      classNameBody="text-4xl"
      footer={
        !loading &&
        `${formatMoney(diff, { withSign: true })} respecto al mes anterior`
      }
      subTitle={
        loading ? (
          <Loading className="min-h-[auto]" />
        ) : (
          <span>
            {formatMoney(data?.currentTotalByClient)}
            <span className="text-lg font-light text-default-500">USD</span>
          </span>
        )
      }
    />
  );
}
