import dayjs from 'dayjs';

import { NewSessionFormType } from '@/app/app/sessions/common/types.ts';
import {
  FinishEnum,
  SESSION_FREQUENCY_ENUM
} from '@/app/app/sessions/common/enum.ts';
import { SessionFragmentFragment } from '@/__generated__/graphql.ts';

export const CREATE_DEFAULT_VALUES: (
  s: DeepNonNullable<SessionFragmentFragment> | null
) => Partial<NewSessionFormType> = (session) => {
  return {
    name: session?.name || '',
    date: session?.startedAt || '',
    summary: session?.description || '',
    finishTime: session?.finishedAt || null,
    coach: session?.coach?.id || undefined,
    color: session?.color || undefined,
    limitQuote: session?.limitQuote || undefined,
    startTime: session?.startedAt || null,
    isRepeatEvent: !!session?.rootEvent || false,
    categories: session?.categories?.map?.((category) => category.id) || [],
    repeatSettings: {
      frequency:
        (session?.rootEvent?.frequency as SESSION_FREQUENCY_ENUM) || SESSION_FREQUENCY_ENUM.EVERY_DAY,
      daysRepeat: session?.rootEvent?.daysRepeat || [],
      interval: session?.rootEvent?.interval || 1,
      count: session?.rootEvent?.count || 1,
      finishType: (session?.rootEvent?.finishType as FinishEnum) || FinishEnum.NEVER,
      finishedRepeatAt:
        session?.rootEvent?.finishedRepeatAt ||
        dayjs().add(1, 'weeks').toISOString()
    }
  }
};
