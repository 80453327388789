import CoachList from '@/app/app/coaches/page.tsx';
import CoachDetailPage from '@/app/app/coaches/[id].tsx';
import { RouteObject } from 'react-router/dist/lib/context';
import { PrivateRotes } from '@/routes/private-rotes.ts';

const routes: RouteObject[] = [
  {
    path: PrivateRotes.COACHES,
    Component: CoachList
  },
  {
    path: PrivateRotes.COACHES_DETAILS,
    Component: CoachDetailPage
  }
];

export default routes;
