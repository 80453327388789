import { toast } from 'sonner';
import { useEffect } from 'react';
import { Button } from '@nextui-org/react';
import { useForm } from 'react-hook-form';

import AppContainer from '@/app/app/shared/components/AppContainer.tsx';
import FormNewOrEditClient, {
  FormUserType
} from '@/app/app/clients/components/NewOrEditClient/form.tsx';
import { ImageFieldInput, InputMaybe } from '@/__generated__/graphql.ts';
import { useAuth } from '@/app/auth/_context';
import { useUpdateClient } from '@/app/app/clients/store/mutation/clients.mutation.ts';
import { useTranslation } from '@/hooks/useTranslation.ts';

const FORM_ID = 'profile-form';

export default function ProfilePage() {
  const { user } = useAuth();
  const { t } = useTranslation();

  const form = useForm<FormUserType>({
    defaultValues: {
      id: user?.item?.id,
      email: user?.item?.email,
      firstName: user?.item?.firstName,
      lastName: user?.item?.lastName,
      paymentPreference: user?.item?.paymentPreference,
      avatar: user?.item?.avatar as InputMaybe<ImageFieldInput>,
      file: user?.item?.avatar?.url || null
    }
  });

  const { onUpdateUser, ...restUpdateMutation } = useUpdateClient(
    user?.item?.id as string
  );

  const fullLoading = restUpdateMutation?.loading;
  const error = restUpdateMutation?.error;

  const onSubmit = async (values: FormUserType) => {
    let file: File | undefined = undefined;

    if (typeof values?.file === 'string') {
      file = undefined;
    } else if (values?.file instanceof File) {
      file = values?.file;
    }

    delete values.id;
    delete values.file;

    const payload = {
      ...values,
      avatar: file
        ? {
            upload: file
          }
        : undefined
    };

    await onUpdateUser({
      data: payload
    });
  };

  useEffect(() => {
    if (error) {
      toast.error(error.message);
      if (
        error.message.includes(
          'Unique constraint failed on the fields: (`email`)'
        )
      ) {
        form.setError('email', {
          message: error.message
        });
      }
    }
  }, [error, form]);

  return (
    <AppContainer size="xl">
      <FormNewOrEditClient
        isProfile
        id={FORM_ID}
        formContext={form}
        onSubmit={onSubmit}
      />

      <div className="flex justify-center mt-10">
        <Button
          type="submit"
          form={FORM_ID}
          color="primary"
          isLoading={fullLoading}
          isDisabled={!form?.formState?.isDirty}
        >
          {t('save')}
        </Button>
      </div>
    </AppContainer>
  );
}
