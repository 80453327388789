import { FormItem } from 'reactivity-hook-form';
import RadioGroupButton from '@/lib/ui/radio-button';
import Input from '@/lib/ui/input';
import { useTranslation } from '@/hooks/useTranslation';
import { useFormContext } from 'react-hook-form';
import { DatePicker } from '@/lib/ui/date-picker';
import { NewSessionFormType } from '@/app/app/sessions/common/types';
import { FinishEnum } from '@/app/app/sessions/common/enum';
import { cn } from '@/lib/utils.ts';
import Text from '@/lib/ui/text.tsx';

export function RepeatFinishField() {
  const { t } = useTranslation();
  const context = useFormContext<NewSessionFormType>();

  const finishType = context.watch('repeatSettings.finishType');

  return (
    <div>
      <FormItem<NewSessionFormType> name="repeatSettings.finishType">
        <RadioGroupButton
          orientation="vertical"
          options={[
            {
              label: (
                <Text
                  className={cn(
                    'text-sm',
                    finishType !== FinishEnum.NEVER && 'opacity-50'
                  )}
                >
                  {t('never')}
                </Text>
              ),
              value: FinishEnum.NEVER
            },
            {
              label: (
                <div
                  className={cn(
                    'flex items-center gap-5 w-[250px]',
                    finishType !== FinishEnum.DATE && 'opacity-60'
                  )}
                >
                  <Text className="text-sm">{t('at')}</Text>

                  <FormItem<NewSessionFormType>
                    name="repeatSettings.finishedRepeatAt"
                    className="flex-shrink-0 w-full"
                  >
                    <DatePicker
                      size="sm"
                      isDisabled={finishType !== FinishEnum.DATE}
                    />
                  </FormItem>
                </div>
              ),
              value: FinishEnum.DATE
            },
            {
              label: (
                <div
                  className={cn(
                    'flex items-center gap-5',
                    finishType !== FinishEnum.COUNT && 'opacity-60'
                  )}
                >
                  <Text className="text-sm">{t('after_at')}</Text>
                </div>
              ),
              value: FinishEnum.COUNT
            }
          ]}
        />
      </FormItem>

      {finishType === FinishEnum.COUNT && (
        <FormItem<NewSessionFormType> name="repeatSettings.count" className="mt-2">
          <Input
            size="sm"
            type="number"
            className="bg-background w-64"
            placeholder={t('form_placeholder.occurrences')}
          />
        </FormItem>
      )}
    </div>
  );
}
