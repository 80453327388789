import { gql } from '@/__generated__/gql.ts';

export const GET_ALL_SESSIONS = gql(`
  query SessionsListBackoffice(
    $spaceId: ID!
    $day: String!
    $time: FilterTimeEnum!
  ) {
    sessionsListBackoffice(spaceId: $spaceId, day: $day, time: $time) {
      ...SessionBoardFragment
    }
  }
`);
