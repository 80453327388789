import { useEffect, useState } from 'react';
import RcUpload from 'rc-upload';
import { Upload as UploadIcon } from 'lucide-react';
import { Avatar } from '@nextui-org/react';
import { cn } from '@/lib/utils.ts';
import Text from '@/lib/ui/text.tsx';

export type UploadProps = {
  label?: string;
  style?: React.CSSProperties;
  className?: string;
  shape?: 'circle' | 'square';
  value?: string | File | null;
  onChange?: (file: string | File | null) => void;
  wrapper?: {
    style?: React.CSSProperties;
    className?: string;
  };
};

export default function Upload({
  label,
  value,
  className,
  style,
  onChange,
  shape = 'circle',
  wrapper = {},
  ...restRcProps
}: UploadProps) {
  const [file, setFile] = useState<File | string | null>(null);

  useEffect(() => {
    if (value !== undefined) {
      setFile(value);
    }
  }, [value]);

  return (
    <RcUpload
      {...restRcProps}
      {...wrapper}
      component="div"
      accept={'.png,.jpg,.jpeg,.gif,.svg,.webp'}
      className={cn('w-fit', wrapper?.className)}
      beforeUpload={(selectedFile) => {
        setFile(selectedFile);
        onChange?.(selectedFile);
      }}
    >
      {label && (
        <Text className="pointer-events-none subpixel-antialiased block text-foreground-600 cursor-text will-change-auto scale-85 text-small -translate-y-[calc(50%_+_theme(fontSize.small)/2_-_6px)] pe-2 max-w-full text-ellipsis overflow-hidden">
          {label}
        </Text>
      )}

      <div
        className={cn(
          'upload overflow-hidden',
          shape === 'circle' ? 'rounded-full' : 'rounded-2xl'
        )}
      >
        <Avatar
          style={style}
          src={renderSrc(file)}
          className={cn('h-32 w-32 rounded-[inherit]', className)}
          imgProps={{
            className: 'object-cover h-full'
          }}
          icon={
            <div className="flex flex-col gap-2 items-center justify-center">
              <UploadIcon />
              Upload
            </div>
          }
        />

        {!!file && (
          <div
            className={cn(
              'absolute inset-0',
              'flex items-center justify-center',
              'cursor-pointer layer-wrapper'
            )}
          >
            <div className="flex flex-col gap-2 items-center justify-center text-white text-xs">
              <UploadIcon />
              Change
            </div>
          </div>
        )}
      </div>
    </RcUpload>
  );
}

const renderSrc = (value: string | File | null) => {
  if (!value) return undefined;
  if (typeof value === 'string') return value;

  return URL.createObjectURL(value);
};
