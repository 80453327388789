import Select, { SelectSingleProps } from '@/lib/ui/select.tsx';
import { renderContentOrFallback } from '@/utils';
import { useGetClientsToSelect } from '@/app/app/clients/store/query/clients.query.ts';

export type ClientSelectorProps = Omit<SelectSingleProps, 'options'> & {
  disabledIds?: string[];
};

export default function ClientSelector(props: ClientSelectorProps) {
  const { options, loading } = useGetClientsToSelect();

  return (
    <Select
      {...props}
      size="lg"
      options={options}
      isLoading={loading}
      disabledKeys={props?.disabledIds}
      placeholder={renderContentOrFallback(props?.value, 'Select a client')}
    />
  );
}
