import { Button, ModalProps as NextUIModalProps } from '@nextui-org/react';
import Form, { FormItem } from 'reactivity-hook-form';
import { useForm } from 'react-hook-form';

import ClientSelector from '@/app/app/clients/components/ClientSelector';
import SubscriptionStatusSelect from '@/app/app/sessions/components/SubscriptionStatusSelect';
import { CreateSessionSubscriptionMutationVariables } from '@/__generated__/graphql.ts';
import { useCreateSubscription } from '@/app/app/sessions/store/mutation/subscription.mutation.ts';
import { useTranslation } from '@/hooks/useTranslation.ts';
import Modal from '@/lib/ui/modal.tsx';
import { SUBSCRIPTION_STATUS_ENUM } from '@/app/app/sessions/components/SubscriptionStatusSelect/SubscriptionStatusSelect.tsx';

type DataValuesForm = Omit<
  CreateSessionSubscriptionMutationVariables['data'],
  'sessionId'
>;

const FORM_ID = 'add-customer-form';

export default function AddCustomer({
  onClose,
  sessionId,
  isOpen,
  isExpiredClass,
  disabledUserIds = []
}: Pick<NextUIModalProps, 'onClose' | 'isOpen'> & {
  isExpiredClass?: boolean;
  sessionId: string;
  disabledUserIds: string[];
}) {
  const { t } = useTranslation();
  const context = useForm<DataValuesForm>({
    defaultValues: {
      status: isExpiredClass
        ? SUBSCRIPTION_STATUS_ENUM.CONFIRMED
        : SUBSCRIPTION_STATUS_ENUM.PENDING
    }
  });
  const { onCreateSubscription, loading } = useCreateSubscription();

  const onSubmit = async (values: DataValuesForm) => {
    const { errors } = await onCreateSubscription({
      ...values,
      sessionId
    });
    if (errors) {
      const errorText =
        errors?.[0]?.message ||
        (errors as unknown as Error)?.message ||
        'Something went wrong';
      context.setError('userId', {
        message: errorText
      });
    } else {
      onClose?.();
      context.reset();
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} title={t('add')}>
      <Form<DataValuesForm>
        id={FORM_ID}
        className="w-full pt-2 pb-5"
        onSubmit={onSubmit}
        formContext={context}
      >
        <FormItem<DataValuesForm> name="userId">
          <ClientSelector disabledIds={disabledUserIds} />
        </FormItem>
        <FormItem<DataValuesForm> name="status">
          <SubscriptionStatusSelect />
        </FormItem>

        <Button
          type="submit"
          variant="flat"
          color="primary"
          isLoading={loading}
        >
          {t('add')}
        </Button>
      </Form>
    </Modal>
  );
}
