import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Divider,
  Button
} from '@nextui-org/react';
import { RotateCcw } from 'lucide-react';
import { QueryResult } from '@apollo/client/react/types/types';

import Text from '@/lib/ui/text.tsx';
import Loading from '@/lib/ui/loading.tsx';
import SubscriptionCard from '@/app/app/clients/components/SubscriptionsCard/SubscriptionCard.tsx';
import {
  CreditFragmentFragment,
  GetCreditsByClientQuery,
  PlanFragmentFragment
} from '@/__generated__/graphql.ts';
import { useTranslation } from '@/hooks/useTranslation.ts';

export default function SubscriptionsCard({
  loading,
  data,
  refetch,
  id
}: Pick<
  QueryResult<GetCreditsByClientQuery>,
  'data' | 'loading' | 'refetch'
> & {
  id: string;
}) {
  const { t } = useTranslation();

  return (
    <Card className="p-2 md:p-5">
      <CardHeader>
        <Text className="font-bold text-2xl">{t('subscriptions')}:</Text>
      </CardHeader>
      <CardBody className="flex-none">
        <Divider />
      </CardBody>
      <CardBody>
        <div className="flex flex-col gap-4">
          {loading && <Loading className="mx-auto text-3xl min-h-fit" />}
          {!data?.credits?.length ? (
            <Text>Not subscriptions yet</Text>
          ) : (
            data.credits?.map?.((subscription) => {
              const sub = subscription as CreditFragmentFragment & {
                plan: PlanFragmentFragment;
              };

              return <SubscriptionCard key={sub.id} {...sub} />;
            })
          )}
        </div>
      </CardBody>
      <CardFooter className="flex flex-col justify-center items-end w-full">
        <Button
          onClick={() =>
            refetch({
              clientId: id
            })
          }
          variant="flat"
          endContent={<RotateCcw size={16} />}
        >
          {t('refresh')}
        </Button>
      </CardFooter>
    </Card>
  );
}
