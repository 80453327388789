import { useCallback, useEffect, useState } from 'react';

export function useDebounce<T>(
  cb: (value: T) => any,
  delay: number = 500
): (value: T) => void {
  const [value, setValue] = useState<T | null>(null);

  const setDebounced = useCallback((newValue: T) => {
    setValue(newValue);
  }, []);

  useEffect(() => {
    if (value === null) return;

    const handler = setTimeout(() => {
      cb(value);
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [value, delay, cb]);

  return setDebounced;
}
