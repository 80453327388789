import { useState } from 'react';

export default function useManySelect<T>() {
  const [selection, setSelection] = useState<T[]>([]);

  const onSelect = (item: T | null | undefined) => {
    if (!item) return;
    setSelection((prev) => {
      if (prev.includes(item)) {
        return prev.filter((i) => i !== item);
      }
      return [...prev, item];
    });
  };

  const onSelectAll = (items: Array<T>) => {
    if (!items) return;
    setSelection(items);
  };

  const onRemoveAll = () => {
    setSelection([]);
  };

  return {
    selection,
    onSelect,
    onSelectAll,
    onRemoveAll,
  };
}
