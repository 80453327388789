import dayjs from 'dayjs';
import { FormDependencies } from 'reactivity-hook-form';
import { UseFormReturn } from 'react-hook-form';

import { NewOrEditCreditsPayload } from '@/app/app/clients/components/CreditsCard/type.ts';

function onSetDefaultValues(
  setValue: UseFormReturn<NewOrEditCreditsPayload>['setValue'],
  selectedPlan: NewOrEditCreditsPayload['selectedPlan'] | null | undefined
) {
  const startDate = dayjs();
  const endDate = startDate.add(selectedPlan?.duration ?? 30, 'days');
  setValue('openAt', startDate.toISOString(), { shouldValidate: true });
  setValue('expiredAt', endDate.toISOString(), { shouldValidate: true });
  setValue('totalPrice', selectedPlan?.price ?? '0', {
    shouldValidate: true
  });
}

export default function useDependency(): FormDependencies<NewOrEditCreditsPayload> {
  return [
    {
      dependencies: ['lastCredit'],
      callback(values, { setValue }) {
        const { selectedPlan } = values;
        setValue('plan', '', { shouldValidate: true });
        onSetDefaultValues(setValue, selectedPlan);
      }
    },
    {
      dependencies: ['plan'],
      callback(values, { setValue }) {
        const { selectedPlan } = values;

        setValue('lastCredit', '', { shouldValidate: true });
        onSetDefaultValues(setValue, selectedPlan);
      }
    }
  ];
}
