import { FormItem } from 'reactivity-hook-form';

import { useTranslation } from '@/hooks/useTranslation';
import { TimePicker } from '@/lib/ui/time-picker.tsx';
import { NewSessionFormType } from '@/app/app/sessions/common/types.ts';

export function StartAndEndTimeField() {
  const { t } = useTranslation();

  return (
    <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
      <FormItem<NewSessionFormType> name="startTime">
        <TimePicker placeholder="HH:MM A" label={t('form.start_time')} />
      </FormItem>
      <FormItem<NewSessionFormType> name="finishTime">
        <TimePicker placeholder="HH:MM A" label={t('form.end_time')} />
      </FormItem>
    </div>
  );
}
