import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Divider,
  Avatar,
  Chip,
  Button,
  Spinner
} from '@nextui-org/react';

import Text from '@/lib/ui/text.tsx';
import { ClientCompleteFragmentFragment } from '@/__generated__/graphql.ts';
import { PencilLine } from 'lucide-react';
import { CLIENT_STATUS_COLOR } from '@/app/app/clients/common/colors/status.colors.ts';
import StatusColor from '@/lib/ui/status-color.tsx';
import { useTranslation } from '@/hooks/useTranslation.ts';
import NewOrEditClient from '@/app/app/clients/components/NewOrEditClient';
import useToggle from '@/hooks/useToggle.ts';
import { useAddOrRemoveBadgeClient } from '@/app/app/clients/store/mutation/clients.mutation.ts';
import AddBadgeToClient from '@/app/app/clients/components/AddBadgeToClient';
import { getTextColor } from '@/utils/colors.ts';

export default function ClientCard({
  id,
  avatar,
  email,
  fullName,
  firstName,
  lastName,
  paymentPreference,
  badges,
  state
}: DeepNonNullable<ClientCompleteFragmentFragment>) {
  const { t } = useTranslation();
  const [isOpen, actionOpen] = useToggle();
  const { onUpdateBadgeClient, loading } = useAddOrRemoveBadgeClient(id);

  return (
    <>
      <Card className="p-2 md:p-5">
        <CardHeader className="flex flex-col justify-center items-center relative">
          <Avatar
            className="w-36 h-36 lg:w-60 lg:h-60 text-5xl"
            src={avatar?.url}
            name={fullName?.toString?.()}
          />

          <Button
            isIconOnly
            size="md"
            variant="light"
            onClick={actionOpen.onVisible}
            className="absolute top-0 right-0"
          >
            <PencilLine size={18} />
          </Button>
        </CardHeader>
        <CardBody className="flex flex-col items-center">
          <div className="flex gap-2 justify-center items-center flex-wrap">
            <Text className="font-bold text-2xl">{fullName?.toString?.()}</Text>
            <StatusColor
              severity={CLIENT_STATUS_COLOR[state as string]}
              label={t(
                state?.toLowerCase() as LanguageKeys,
                state?.toString?.()
              )}
            />
          </div>
          <Text className="text-gray-500">{email?.toString?.()}</Text>
        </CardBody>
        <Divider />
        <CardFooter className="flex justify-center items-center w-full gap-2">
          {loading && <Spinner size="sm" />}
          {badges ? (
            <Chip
              style={{
                backgroundColor: badges?.color || '',
                color: getTextColor(badges?.color)
              }}
              onClose={() =>
                onUpdateBadgeClient({
                  badges: {
                    disconnect: true
                  }
                })
              }
              classNames={{
                content: 'flex items-center gap-1'
              }}
            >
              <span className="text-xl">{badges?.icon}</span>
              <span>{badges?.name}</span>
            </Chip>
          ) : (
            /* add badge button and modal */
            <AddBadgeToClient clientId={id} />
          )}
        </CardFooter>
      </Card>

      <NewOrEditClient
        isOpen={isOpen}
        key={String(isOpen)}
        onClose={actionOpen.onHidden}
        client={{
          id,
          file: avatar?.url || null,
          email: email || '',
          firstName: firstName || '',
          lastName: lastName || '',
          paymentPreference: paymentPreference || null
        }}
      />
    </>
  );
}
