import { useTranslation } from '@/hooks/useTranslation.ts';
import { SESSION_FREQUENCY_ENUM } from '@/app/app/sessions/common/enum.ts';

export default function useFrequencyOptions() {
  const { t } = useTranslation();

  return [
    {
      label: t('day'),
      value: SESSION_FREQUENCY_ENUM.EVERY_DAY
    },
    {
      label: t('week'),
      value: SESSION_FREQUENCY_ENUM.EVERY_WEEK
    }
  ];
}
