import { Link, useNavigate } from 'react-router-dom';
import { Button } from '@nextui-org/react';
import { UseFormReturn } from 'react-hook-form';
import Form, { FormItem } from 'reactivity-hook-form';

import Input from '@/lib/ui/input';
import { AUTH_ROUTES } from '@/app/auth/_common/routes';
import { useTranslation } from '@/hooks/useTranslation';
import {
  signInWithEmailAndPassword,
  signInWithGooglePopup
} from '@/lib/firebase';
import Text from '@/lib/ui/text.tsx';

type LoginFormType = {
  email: string;
  password: string;
};

export default function LoginForm() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const onGoogleLogin = async () => {
    await signInWithGooglePopup();
  };

  const onEmailAndPasswordLogin = async (
    payload: LoginFormType,
    context: UseFormReturn<LoginFormType>
  ) => {
    try {
      await signInWithEmailAndPassword(payload);
    } catch (e) {
      const error = e as { code?: string; message?: string };
      context.setError('password', {
        type: 'validate',
        message: t(
          `errors_message.${error?.code}` as LanguageKeys,
          error?.message
        )
      });
    }
  };

  return (
    <Form<LoginFormType>>
      <FormItem<LoginFormType> name="email">
        <Input
          label={t('form.email')}
          placeholder={t('form_placeholder.email')}
        />
      </FormItem>
      <FormItem<LoginFormType> name="password">
        <Input
          type="password"
          label={t('form.password')}
          placeholder={t('form_placeholder.password')}
        />
      </FormItem>

      <div className="w-full flex justify-end gap-3">
        <Button
          type="button"
          variant="bordered"
          onClick={() => navigate(AUTH_ROUTES.SIGNUP)}
          className="w-[50%] mt-2 border-primary text-primary"
        >
          {t('create_account')}
        </Button>

        <FormItem<LoginFormType>>
          {(context) => (
            <Button
              color="primary"
              className="mt-2 w-[50%]"
              onClick={context.handleSubmit((values) =>
                onEmailAndPasswordLogin(values, context)
              )}
            >
              {t('login')}
            </Button>
          )}
        </FormItem>
      </div>

      <Text className="text-sm text-center">
        {t('forgotten_password')}{' '}
        <Link
          to={AUTH_ROUTES.RESET_PASSWORD}
          className="text-primary hover:underline"
        >
          {t('reset_it')}
        </Link>
      </Text>

      <div className="mt-20 flex flex-col items-center gap-10">
        <div className="h-[2px] bg-gray-200 relative w-full">
          <span className="text-gray-600 absolute top-[-12px] left-0 right-0 m-auto w-fit px-4 bg-background">
            {t('or')}
          </span>
        </div>

        <Button
          type="button"
          variant="ghost"
          onClick={onGoogleLogin}
          className="bg-background text-text gap-5 shadow-lg"
        >
          <img
            loading="lazy"
            alt="google logo"
            className="w-6 h-6"
            src="https://www.svgrepo.com/show/475656/google-color.svg"
          />
          <span>{t('continue_with_google')}</span>
        </Button>
      </div>
    </Form>
  );
}
