import { Tooltip } from '@nextui-org/react';
import Text from '@/lib/ui/text';

export default function ShortId({
  children,
  slice = 6
}: {
  children: string;
  slice?: number;
}) {
  return (
    <Tooltip content={children}>
      <Text>
        {children?.slice(0, slice)}...{children?.slice(-slice)}
      </Text>
    </Tooltip>
  );
}
