import { gql } from '@/__generated__/gql.ts';

export const SIGNING_WITH_FIREBASE_MUTATION = gql(`
  mutation AuthenticateUserWithFirebase($firebaseToken: String!) {
    authenticateBackofficeUserWithFirebase(token: $firebaseToken) {
      ... on UserAuthenticationWithPasswordSuccess {
        sessionToken
        scope {
          id
          isAdmin
          isBlocked
          isOwner
          space {
            id
            name
            summary
            email
            phoneCountryCode
            phoneNumber
            address
            paymentLink
            website
            amenities
            gallery1 {
              id
              url
            }
            gallery2 {
              id
              url
            }
            gallery3 {
              id
              url
            }
            classOffered {
              ...ClassOfferedFragment
            }
            badges {
              id
              name
              items {
                ...BadgeItemFragment
              }
            }
            thumbnail {
              id
              url
            }
            paymentsProviderCount(where: { provider: { equals: "stripe" }, status: { equals: "completed" } })
            paymentsProvider(where: { provider: { equals: "stripe" } }) {
              id
              provider
              referenceId
              status
            }
          }
        }
        item {
          id
          firstName
          lastName
          fullName
          email
          state
          avatar {
            id
            url
          }
        }
      }
      ... on UserAuthenticationWithPasswordFailure {
        message
      }
    }
  }
`);

export const UPDATE_SPACE_MUTATION = gql(`
  mutation UpdateSpace($spaceId: ID!, $data: SpaceUpdateInput!) {
    updateSpace(where: { id: $spaceId }, data: $data) {
      id
      name
      summary
      email
      phoneCountryCode
      phoneNumber
      address
      paymentLink
      website
      amenities
      gallery1 {
        id
        url
      }
      gallery2 {
        id
        url
      }
      gallery3 {
        id
        url
      }
      classOffered {
        ...ClassOfferedFragment
      }
      badges {
        id
        name
        items {
          ...BadgeItemFragment
        }
      }
      thumbnail {
        id
        url
      }
      paymentsProviderCount(where: { provider: { equals: "stripe" }, status: { equals: "completed" } })
      paymentsProvider(where: { provider: { equals: "stripe" } }) {
        id
        provider
        referenceId
        status
      }
    }
  }
`);

export const CREATE_USER_MUTATION = gql(`
    mutation CreateUser($data: UserCreateInput!) {
      createUser(data: $data) {
        ...UserFragment
    }
  }
`);

export const UPDATE_USER_MUTATION = gql(`
    mutation UpdateFirebaseUID($token: String!, $firebaseUID: String!) {
      completeProfileBackoffice(firebaseUID: $firebaseUID, token: $token)
  }
`);

export const GET_USER_FIREBASE_MUTATION = gql(`
  mutation GetFirebaseUserByEmail($email: String!) {
    verifyExistEmail(email: $email) {
      uid
      email
      displayName
      photoURL
      emailVerified
      providerData {
        uid
        providerId
        displayName
      }
    }
  }
`);

export const CREATE_REQUEST_SPACE_MUTATION = gql(`
  mutation CreateSpaceRequest($data: SpaceRequestCreateInput!) {
    createSpaceRequest(data: $data) {
      id
    }
  }
`);
