import { Dispatch, SetStateAction, useState } from 'react';

export type UseToggleActions = {
  onVisible: () => void;
  onHidden: () => void;
  onToggle: () => void;
  setVisible: Dispatch<SetStateAction<boolean>>;
};

export default function useToggle(
  initialState: boolean = false
): [boolean, UseToggleActions] {
  const [visible, setVisible] = useState<boolean>(initialState);

  const onHidden = () => setVisible(false);

  const onVisible = () => setVisible(true);

  const onToggle = () => setVisible((prev) => !prev);

  return [visible, { onHidden, onVisible, setVisible, onToggle }];
}
