export function createUrl(path: string, base: string) {
  const baseWithDomain = new URL(base, window.location.origin).toString();
  return new URL(path, baseWithDomain).pathname;
}

export function cleanUrl(path: string, removePath: string) {
  return path.replace(removePath, '');
}

export function cleanUrlModule(path: string) {
  const slash = path[0];
  return slash ? path.slice(1) : path;
}
