import { TriangleAlert } from 'lucide-react';
import { useFormContext } from 'react-hook-form';

import Text from '@/lib/ui/text.tsx';
import Alert from '@/lib/ui/alert.tsx';
import { useTranslation } from '@/hooks/useTranslation.ts';
import { useCreateOrEditSession } from '@/app/app/sessions/components/create-edit-session/context';
import { NewSessionFormType } from '@/app/app/sessions/common/types';
import {
  RepeatFinishField,
  StartAndEndTimeField,
  LimitQuoteField,
  ColorField,
  EnabledRepeatField,
  RepeatIntervalAndFrequencyField,
  NameField,
  CoachField,
  DescriptionField,
  DateField,
  RepeatOnField,
  CategoryField
} from './fields';
import { cn } from '@/lib/utils.ts';

export default function NewSessionForm() {
  const { t } = useTranslation();
  const { session } = useCreateOrEditSession();
  const { watch } = useFormContext<NewSessionFormType>();

  const isRepeatEvent = watch('isRepeatEvent');
  const disabledRepeatEvent = !!session?.rootEvent;

  return (
    <>
      <Text className="font-bold">{t('details')}</Text>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
        <NameField />

        <CoachField />

        <LimitQuoteField />

        <ColorField />
      </div>

      <CategoryField />

      <EnabledRepeatField />

      <StartAndEndTimeField />

      {!isRepeatEvent && <DateField />}

      <DescriptionField />

      {disabledRepeatEvent && (
        <Alert severity="warning" className="gap-2 flex items-center">
          <TriangleAlert />
          <div>
            {/*<Text className="font-bold text-sm">Este es un evento repetido</Text>*/}
            <Text className="text-xs">{t('session_alert_edit')}</Text>
          </div>
        </Alert>
      )}

      {isRepeatEvent && (
        <div
          className={cn(
            'flex flex-col gap-8 mt-5',
            disabledRepeatEvent && 'opacity-50 pointer-events-none'
          )}
        >
          <div className="flex flex-col gap-2">
            <Text className="font-bold">{t('form.repeat_every_number')}</Text>
            <RepeatIntervalAndFrequencyField />
          </div>

          <RepeatOnField />

          <div className="flex flex-col gap-2">
            <Text className="font-bold">{t('form.custom_repeat')}</Text>
            <RepeatFinishField />
          </div>
        </div>
      )}
    </>
  );
}
