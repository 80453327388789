import { Table } from '@/lib/ui/table';
import { useGetCoachList } from '@/app/app/coaches/store/query/coaches.query';
import { useColumnSettings } from '@/app/app/coaches/components/CoachList/settings/useSettingsTable';
import { CoachFragmentFragment } from '@/__generated__/graphql.ts';

export default function CoachList() {
  const { data, loading } = useGetCoachList();
  const columns = useColumnSettings();

  return (
    <Table
      columns={columns}
      isLoading={loading}
      rows={(data?.coaches ?? []) as CoachFragmentFragment[]}
    />
  );
}
