import fetch from 'cross-fetch';
import { PropsWithChildren } from 'react';
import { ApolloClient, ApolloProvider, InMemoryCache } from '@apollo/client';
import { API_URL_GRAPHQL } from '@/config/env.ts';
import { createFragmentRegistry } from '@apollo/client/cache';
import {
  BADGE_BASIC_FRAGMENT,
  BADGE_ITEM_BASIC_FRAGMENT,
  USER_BASIC_FRAGMENT,
  CLIENT_COMPLETE_FRAGMENT,
  COACH_BASIC_FRAGMENT,
  CREDIT_BASIC_FRAGMENT,
  PLAN_BASIC_FRAGMENT,
  SESSION_BOARD_FRAGMENT,
  SESSION_FRAGMENT,
  USER_BACKOFFICE_FRAGMENT,
  CLASS_OFFERED_BASIC_FRAGMENT, CLIENT_ASSOCIATED_BASIC_FRAGMENT
} from './fragment.ts';

// @ts-ignore
import createUploadLink from 'apollo-upload-client/createUploadLink.mjs';
import { SESSION_KEY } from '@/config/constants.ts';

const enchancedFetch = (url: string, init: any) => {
  const sessionToken = window?.localStorage?.getItem(SESSION_KEY) || '';

  return fetch(url, {
    ...init,
    headers: {
      ...init.headers,
      Authorization: 'Bearer ' + sessionToken,
      'Access-Control-Allow-Origin': '*'
    }
  }).then((response) => response);
};

function createApolloClient() {
  const linkUpload = createUploadLink({
    uri: API_URL_GRAPHQL,
    credentials: 'include',
    fetchOptions: {
      mode: 'cors'
    },
    fetch: enchancedFetch as never,
    headers: {
      'Apollo-Require-Preflight': 'true'
    }
  });

  return new ApolloClient({
    credentials: 'include',
    ssrMode: typeof window === 'undefined', // Ejecutar en servidor durante el SSR
    link: linkUpload,
    cache: new InMemoryCache({
      fragments: createFragmentRegistry(
        CLASS_OFFERED_BASIC_FRAGMENT,
        BADGE_BASIC_FRAGMENT,
        BADGE_ITEM_BASIC_FRAGMENT,
        PLAN_BASIC_FRAGMENT,
        USER_BASIC_FRAGMENT,
        CLIENT_COMPLETE_FRAGMENT,
        CREDIT_BASIC_FRAGMENT,
        SESSION_FRAGMENT,
        SESSION_BOARD_FRAGMENT,
        COACH_BASIC_FRAGMENT,
        USER_BACKOFFICE_FRAGMENT,
        CLIENT_ASSOCIATED_BASIC_FRAGMENT,
      )
    })
  });
}

export const client = createApolloClient();

export function ApolloProviderClient({ children }: PropsWithChildren) {
  return <ApolloProvider client={client}>{children}</ApolloProvider>;
}
