import { useMutation } from '@apollo/client';
import * as MUTATION from '@/app/app/notifications/store/mutation/graphql/notifications.graphql.ts';
import { useAuth } from '@/app/auth/_context';
import { CreateNotificationsInput } from '@/__generated__/graphql.ts';

export function useNewNotification() {
  const { activeSpace } = useAuth();
  const spaceId = activeSpace?.space?.id || '';

  const [onFunction, restMutation] = useMutation(
    MUTATION.CREATE_NOTIFICATIONS_QUERY
  );

  const onNewNotification = async (
    payload: Omit<CreateNotificationsInput, 'spaceId'>
  ) => {
    return onFunction({
      variables: {
        data: {
          ...payload,
          spaceId
        }
      }
    });
  };

  return { onNewNotification, ...restMutation };
}
