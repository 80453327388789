import Color from 'color';

export function getTextColor(bgColor?: string | null) {
  if (!bgColor) return 'currentColor';
  return new Color(bgColor).luminosity() >= 0.6 ? 'black' : 'white';
}

export const darkenColor = (hex: string, percent: number): string => {
  hex = hex.replace('#', '');

  let r = parseInt(hex.substring(0, 2), 16);
  let g = parseInt(hex.substring(2, 4), 16);
  let b = parseInt(hex.substring(4, 6), 16);

  r = Math.max(0, Math.min(255, r + Math.round((percent / 100) * r)));
  g = Math.max(0, Math.min(255, g + Math.round((percent / 100) * g)));
  b = Math.max(0, Math.min(255, b + Math.round((percent / 100) * b)));

  const toHex = (value: number) => value.toString(16).padStart(2, '0');
  return `#${toHex(r)}${toHex(g)}${toHex(b)}`;
};

export function stringToHexColor(name: string, palette?: string[]): string {
  if (!palette || !palette?.length) {
    let hash = 0;
    for (let i = 0; i < name.length; i++) {
      hash = name.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = '#';
    for (let i = 0; i < 3; i++) {
      const value = (hash >> (i * 8)) & 0xff;
      color += value.toString(16).padStart(2, '0');
    }

    return color;
  }

  let hash = 0;
  for (let i = 0; i < name.length; i++) {
    hash = name.charCodeAt(i) + ((hash << 5) - hash);
  }

  const index = Math.abs(hash) % palette.length;

  return palette[index];
}
