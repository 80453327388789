import { gql } from '@/__generated__';

export const CREATE_SESSION_SUBSCRIPTION = gql(`
  mutation CreateSessionSubscription($data: CreateSubscriptionForSessionData!) {
    createSubscriptionForSession(data: $data) {
      id
      sessionForSubscribe {
        id
        name
      }
    }
  }
`);

export const UPDATE_SESSION_SUBSCRIPTION = gql(`
  mutation UpdateSessionSubscription($data: [SessionSubscriptionUpdateArgs!]!) {
    updateSessionSubscriptions(data: $data) {
      id
    }
  }
`);

export const DELETE_SESSION_SUBSCRIPTION = gql(`
  mutation DeleteSessionSubscription($sessionId: ID!, $userId: ID!) {
    deleteSubscriptionForSession(id: $sessionId, userId: $userId) {
      id
    }
  }
`);
