import { forwardRef } from 'react';
import {
  Textarea as NextTextArea,
  TextAreaProps as NextTextAreaProps
} from '@nextui-org/react';

import { cn } from '@/lib/utils';

export interface TextareaProps extends NextTextAreaProps {}

const Textarea = forwardRef<HTMLTextAreaElement, TextareaProps>(
  ({ className, ...props }, ref) => {
    return <NextTextArea className={cn(className)} ref={ref} {...props} />;
  }
);
Textarea.displayName = 'Textarea';

export { Textarea };
