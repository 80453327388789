import { FormItem } from 'reactivity-hook-form';

import { useTranslation } from '@/hooks/useTranslation';
import { ColorPicker } from '@/lib/ui/color-picker';
import { NewSessionFormType } from '@/app/app/sessions/common/types.ts';

export function ColorField() {
  const { t } = useTranslation();

  return (
    <FormItem<NewSessionFormType> name="color">
      <ColorPicker
        label={t('form.color')}
        placeholder={t('form_placeholder.color')}
      />
    </FormItem>
  );
}
