import { CSSProperties } from 'react';
import { Spinner, SpinnerProps } from '@nextui-org/react';
import { cn } from '@/lib/utils.ts';

export default function Loading({
  className,
  spinnerClassName,
  size,
  ...rest
}: {
  className?: string;
  spinnerClassName?: string;
  style?: CSSProperties;
  size?: SpinnerProps['size'];
}) {
  return (
    <div
      className={cn(
        'min-h-[150px] flex flex-col justify-center items-center w-full',
        className
      )}
      {...rest}
    >
      <Spinner size={size} className={spinnerClassName} />
    </div>
  );
}
