import dayjs from 'dayjs';
import { createContext, PropsWithChildren, useContext, useState } from 'react';

import { FilterTimeEnum } from '@/__generated__/graphql.ts';

export type CalendarContextProps = {
  time: FilterTimeEnum;
  date: string;
  timeResolve: 'week' | 'month' | 'day';
  setTime: (time: FilterTimeEnum) => void;
  onNext: () => void;
  onToday: () => void;
  onPrev: () => void;
};

const CalendarContext = createContext<CalendarContextProps | null>(null);

export const TIME_FILTER: Record<FilterTimeEnum, 'week' | 'month' | 'day'> = {
  DAY: 'day',
  WEEK: 'week',
  MONTH: 'month',
};

const FORMAT = 'YYYY-MM-DD';

export default function CalendarProvider({ children }: PropsWithChildren) {
  const [time, setTime] = useState<FilterTimeEnum>(FilterTimeEnum.Week);
  const [date, setDate] = useState<string>(
    dayjs()
      .startOf(time === FilterTimeEnum.Week ? 'week' : 'month')
      .format(FORMAT)
  );

  return (
    <CalendarContext.Provider
      value={{
        time,
        date,
        timeResolve: TIME_FILTER[time],
        setTime,
        onNext: () => {
          setDate(
            dayjs(date)
              .startOf(time === FilterTimeEnum.Week ? 'week' : 'month')
              .add(1, TIME_FILTER[time])
              .format(FORMAT)
          );
        },
        onToday: () => {
          setDate(dayjs().format(FORMAT));
        },
        onPrev: () => {
          setDate(
            dayjs(date)
              .startOf(time === FilterTimeEnum.Week ? 'week' : 'month')
              .subtract(1, TIME_FILTER[time])
              .format(FORMAT)
          );
        }
      }}
    >
      {children}
    </CalendarContext.Provider>
  );
}

export function useCalendar() {
  const context = useContext(CalendarContext);

  if (!context) {
    throw new Error('useCalendar must be used within a CalendarProvider');
  }

  return context;
}
