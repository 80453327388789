import { PropsWithChildren, ReactNode } from 'react';
import {
  Dropdown as NextUIDropdown,
  DropdownItem,
  DropdownMenu,
  DropdownSection,
  DropdownTrigger,
  DropdownProps as NextUIDropdownProps
} from '@nextui-org/react';

export type DropdownItemProps = {
  key?: string | number;
  children: ReactNode;
  startContent?: ReactNode;
  endContent?: ReactNode;
  color?: 'default' | 'primary' | 'success' | 'warning' | 'danger';
  showDivider?: boolean;
  onClick?: () => void;
};

export type DropdownItemSectionProps = {
  key?: string | number;
  sectionName: string;
  showDivider?: boolean;
  items: DropdownItemProps[];
};

export type DropdownProps = Omit<NextUIDropdownProps, 'children'> & {
  disabledKeys?: Iterable<string | number>;
  items: (DropdownItemProps | DropdownItemSectionProps)[];
};

function isSection(
  item: DropdownItemProps | DropdownItemSectionProps
): item is DropdownItemSectionProps {
  return !!(item as DropdownItemSectionProps)?.sectionName;
}

export default function Dropdown({
  items,
  disabledKeys,
  children,
  ...rest
}: PropsWithChildren<DropdownProps>) {
  return (
    <NextUIDropdown {...rest}>
      <DropdownTrigger>{children}</DropdownTrigger>
      <DropdownMenu
        disabledKeys={disabledKeys}
        onAction={(key) => {
          let findItem: null | DropdownItemProps = null;

          items.some((item) => {
            if (isSection(item)) {
              findItem =
                item.items.find((subItem) => subItem.key === key) ?? null;
              return !!findItem;
            }

            const isSome = item.key === key;
            if (isSome) findItem = item;
            return isSome;
          });

          (findItem as unknown as DropdownItemProps)?.onClick?.();
        }}
      >
        {items.map((item, index) => {
          if (isSection(item)) {
            return (
              <DropdownSection
                key={item?.key ?? index}
                title={item.sectionName}
                showDivider={item?.showDivider}
              >
                {item.items.map((subItem, subIndex) => (
                  <DropdownItem
                    key={subItem?.key ?? subIndex}
                    showDivider={subItem?.showDivider}
                    startContent={subItem?.startContent}
                    endContent={subItem?.endContent}
                    color={subItem?.color}
                  >
                    {subItem.children}
                  </DropdownItem>
                ))}
              </DropdownSection>
            );
          }

          return (
            <DropdownItem
              key={item?.key ?? index}
              showDivider={item?.showDivider}
              startContent={item?.startContent}
              endContent={item?.endContent}
              color={item?.color}
            >
              {item.children}
            </DropdownItem>
          );
        })}
      </DropdownMenu>
    </NextUIDropdown>
  );
}
