import React, { PropsWithChildren, useState } from 'react';
import Text from '@/lib/ui/text.tsx';
import Modal, { ModalActionProps } from '@/lib/ui/modal.tsx';
import { useTranslation } from '@/hooks/useTranslation.ts';

export type DialogDefaultProps = {
  title?: React.ReactNode;
  description?: React.ReactNode;
  actions?: Array<ModalActionProps>;
  children?: React.ReactNode;
};

export type DialogConfirmProps = {
  title?: React.ReactNode;
  description?: React.ReactNode;
  recordName?: string | null;
  actions?: {
    onCancel?: ModalActionProps['onClick'];
    onConfirm: ModalActionProps['onClick'];
  };
};

export type DialogEditProps = {
  title?: React.ReactNode;
  description?: React.ReactNode;
  recordName?: string;
  actions?: [
    Pick<ModalActionProps, 'onClick' | 'isLoading'>,
    Pick<ModalActionProps, 'onClick' | 'isLoading'>
  ];
};

export type DialogContextType = {
  open: (content: DialogDefaultProps) => void;
  confirm: (content: DialogConfirmProps) => void;
  edit: (content: DialogEditProps) => void;
  close: () => void;
};

const DialogContext = React.createContext<DialogContextType | null>(null);

enum TypeDialog {
  default = 'default',
  confirm = 'confirm',
  edit = 'edit'
}

const isDefaultVariant = (
  _active: DialogDefaultProps | DialogConfirmProps | DialogEditProps | null,
  variant: TypeDialog
): _active is DialogDefaultProps => variant === TypeDialog.default || !variant;

const isConfirmVariant = (
  _active: DialogDefaultProps | DialogConfirmProps | DialogEditProps | null,
  variant: TypeDialog
): _active is DialogConfirmProps => variant === TypeDialog.confirm;

export default function DialogProvider({ children }: PropsWithChildren) {
  const { t } = useTranslation();
  const [variant, setVariant] = useState<TypeDialog>(TypeDialog.default);
  const [active, setActive] = useState<
    DialogDefaultProps | DialogConfirmProps | DialogEditProps | null
  >(null);

  const onClear = () => {
    setActive(null);
    setVariant(TypeDialog.default);
  };

  const onClose = (e?: React.MouseEvent<HTMLButtonElement>) => {
    (active?.actions as DialogConfirmProps['actions'])?.onCancel?.(
      e as React.MouseEvent<HTMLButtonElement>
    );
    onClear();
  };

  return (
    <DialogContext.Provider
      value={{
        open: setActive,
        confirm: (props) => {
          setVariant(TypeDialog.confirm);
          setActive(props);
        },
        edit: (props) => {
          setVariant(TypeDialog.edit);
          setActive(props);
        },
        close: onClose
      }}
    >
      {children}

      {isDefaultVariant(active, variant) && (
        <Modal
          isOpen={!!active}
          onClose={onClose}
          title={active?.title}
          description={active?.description}
          actions={(active?.actions as DialogDefaultProps['actions'])?.map?.(
            (item) => ({
              ...item,
              onClick: async (e) => {
                await item.onClick?.(e);
                onClear();
              }
            })
          )}
        >
          {active?.children}
        </Modal>
      )}

      {isConfirmVariant(active, variant) && (
        <Modal
          isOpen={!!active}
          onClose={onClose}
          maskClosable={false}
          title={active?.title}
          description={active?.description}
          actions={[
            {
              onClick: onClose,
              variant: 'light',
              label: t('cancel')
            },
            {
              color: 'danger',
              variant: 'light',
              label: t('delete'),
              onClick: async (e) => {
                await active?.actions?.onConfirm?.(e);
                onClear();
              }
            }
          ]}
        >
          <Text>
            {t('delete_record_message', 'delete_record_message', {
              name: active?.recordName
            })}
          </Text>
        </Modal>
      )}
    </DialogContext.Provider>
  );
}

export function useDialog() {
  const context = React.useContext(DialogContext);
  if (!context) {
    throw new Error('useDialog must be used within a DialogProvider');
  }
  return context;
}
