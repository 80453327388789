import Form, { FormItem } from 'reactivity-hook-form';
import Input from '@/lib/ui/input.tsx';
import Modal from '@/lib/ui/modal.tsx';
import { PlanFragmentFragment } from '@/__generated__/graphql.ts';
import { useTranslation } from '@/hooks/useTranslation.ts';
import {
  useCreateSessionCategory,
  useUpdateSessionCategory
} from '@/app/app/settings/store/mutation/category.mutation.ts';

const FORM_NAME = 'new-plan-form';

export default function NewOrUpdateCategoryModal({
  item,
  open,
  onClose
}: {
  item?: PlanFragmentFragment | null;
  open: boolean;
  onClose: () => void;
}) {
  const { t } = useTranslation();
  const { onCreateCategory, loading: cLoading } = useCreateSessionCategory();
  const { onUpdateCategory, loading: uLoading } = useUpdateSessionCategory();
  const loading = cLoading || uLoading;

  const onSubmit = async (values: any) => {
    if (item) {
      await onUpdateCategory(item.id, values);
    } else {
      await onCreateCategory(values);
    }
    onClose();
  };

  return (
    <Modal
      isOpen={open}
      onClose={onClose}
      maskClosable={false}
      title="New Category"
      actions={[
        {
          label: 'Cancel',
          color: 'danger',
          variant: 'light',
          onClick: onClose
        },
        {
          label: 'Save',
          color: 'primary',
          form: FORM_NAME,
          type: 'submit',
          isLoading: loading
        }
      ]}
    >
      <Form
        id={FORM_NAME}
        onSubmit={onSubmit}
        defaultValues={item}
        validations={{
          name: {
            required: t('validations.required')
          }
        }}
      >
        <FormItem name="name">
          <Input label="Name" />
        </FormItem>
      </Form>
    </Modal>
  );
}
