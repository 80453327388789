import { toast } from 'sonner';
import { CircleCheckBig } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import { Button, Checkbox } from '@nextui-org/react';
import Form, { FormItem } from 'reactivity-hook-form';

import Grid from '@/lib/ui/grid';
import Text from '@/lib/ui/text.tsx';
import Title from '@/lib/ui/title.tsx';
import Input from '@/lib/ui/input.tsx';
import { formatMoney } from '@/lib/utils.ts';
import { useAuth } from '@/app/auth/_context';
import { Textarea } from '@/lib/ui/textarea.tsx';
import plans from '../../../../public/plans/plans.json';
import { AUTH_ROUTES } from '@/app/auth/_common/routes.ts';
import { useTranslation } from '@/hooks/useTranslation.ts';
import { SelectCountry } from '@/lib/ui/select-country.tsx';
import RadioGroup from '@/app/app/clients/components/CreditsCard/RadioGroup.tsx';
import { useCreateRequestSpace } from '@/app/auth/_store/mutation/space.mutation.ts';

type SpaceRequestCreateInput = {
  address: string;
  country: string;
  email: string;
  name: string;
  plan: string;
  summary: string;
  user_email: string;
  user_name: string;
  user_phoneCountryCode: string;
  user_phoneNumber: string;
  website: string;
  agree: boolean;
};

export default function CreateSpacePage() {
  const navigate = useNavigate();
  const { t, lang } = useTranslation();
  const { logout } = useAuth();
  const [onCreateSpaceMutation, actionsMutation] = useCreateRequestSpace();

  const onSubmit = async (data: SpaceRequestCreateInput) => {
    console.log(data);
    // @ts-ignore
    delete data.agree;

    try {
      await onCreateSpaceMutation({
        variables: {
          data
        }
      });
      await logout();
      toast.success('Aplicacion enviada correctante');
      navigate(AUTH_ROUTES.LOGIN);
    } catch (e) {
      console.error(e);
      toast.error('Error al crear la solicitud', {
        description: 'Por favor intenta mas tarde.'
      });
    }
  };

  return (
    <main className="w-full h-screen p-5 flex justify-center">
      <div className="flex flex-col items-center py-10">
        <div className="w-[550px] max-w-full pb-20">
          {actionsMutation.data?.createSpaceRequest ? (
            <div className="flex flex-col items-center gap-5">
              <CircleCheckBig className="w-[120px] h-[120px] text-success" />

              <div className="text-center">
                <Title>{t('submit_request_space')}</Title>
                <Text>{t('submit_request_space_description')}</Text>
              </div>

              <Button color="primary">{t('go_to_home')}</Button>
            </div>
          ) : (
            <>
              <div className="mb-5">
                <Title>{t('plans')}</Title>
              </div>

              <Form<SpaceRequestCreateInput>
                onSubmit={onSubmit}
                validations={{
                  plan: { required: t('validations.required') },
                  name: { required: t('validations.required') },
                  email: { required: t('validations.required') },
                  summary: { required: t('validations.required') },
                  country: { required: t('validations.required') },
                  address: { required: t('validations.required') },
                  website: { required: t('validations.required') },
                  user_name: { required: t('validations.required') },
                  user_email: {
                    required: t('validations.required')
                  },
                  user_phoneCountryCode: {
                    required: t('validations.required')
                  },
                  user_phoneNumber: {
                    required: t('validations.required')
                  }
                }}
              >
                <FormItem<SpaceRequestCreateInput> name="plan">
                  <RadioGroup
                    items={plans.map((plan) => ({
                      description: `${plan[`name_${lang}`]} - ${formatMoney(plan.price)}`,
                      label: plan.benefices[lang].join(', '),
                      value: plan.name_en
                    }))}
                  />
                </FormItem>

                <div className="mt-5">
                  <Title>{t('information_space')}</Title>
                </div>

                <Grid gutter={[12, 12]}>
                  <Grid.Item xs={24} md={12}>
                    <FormItem<SpaceRequestCreateInput> name="name">
                      <Input
                        label={t('form.name')}
                        placeholder={t('form_placeholder.name')}
                      />
                    </FormItem>
                  </Grid.Item>

                  <Grid.Item xs={24} md={12}>
                    <FormItem<SpaceRequestCreateInput> name="email">
                      <Input
                        label={t('form.email')}
                        placeholder={t('form_placeholder.email')}
                      />
                    </FormItem>
                  </Grid.Item>
                </Grid>
                <FormItem<SpaceRequestCreateInput> name="summary">
                  <Textarea
                    label={t('form.summary')}
                    placeholder={t('form_placeholder.summary')}
                  />
                </FormItem>
                <FormItem<SpaceRequestCreateInput> name="country">
                  <SelectCountry
                    label={t('form.country')}
                    placeholder="United States"
                  />
                </FormItem>
                <FormItem<SpaceRequestCreateInput> name="address">
                  <Textarea
                    label={t('form.address')}
                    placeholder={t('form.address')}
                  />
                </FormItem>
                <FormItem<SpaceRequestCreateInput> name="website">
                  <Textarea label="Website" placeholder="Website" />
                </FormItem>

                <div className="mt-5">
                  <Title>{t('information_user')}</Title>
                </div>
                <Grid gutter={[12, 12]}>
                  <Grid.Item xs={24} md={12}>
                    <FormItem<SpaceRequestCreateInput> name="user_name">
                      <Input
                        label={t('form.name')}
                        placeholder={t('form.name')}
                      />
                    </FormItem>
                  </Grid.Item>

                  <Grid.Item xs={24} md={12}>
                    <FormItem<SpaceRequestCreateInput> name="user_email">
                      <Input
                        label={t('form.email')}
                        placeholder={t('form_placeholder.email')}
                      />
                    </FormItem>
                  </Grid.Item>
                </Grid>
                <FormItem<SpaceRequestCreateInput> name="user_phoneCountryCode">
                  <SelectCountry
                    label={t('form.phone_country')}
                    placeholder={t('form.phone_country')}
                  />
                </FormItem>
                <FormItem<SpaceRequestCreateInput> name="user_phoneNumber">
                  <Input
                    label={t('form.phone_number')}
                    placeholder="23901232"
                    type="number"
                  />
                </FormItem>

                <div>
                  <FormItem<SpaceRequestCreateInput> name="agree">
                    <Checkbox>
                      <span className="text-xs">
                        {t('accept_terms_new_space')}
                      </span>
                    </Checkbox>
                  </FormItem>
                </div>

                <div className="flex gap-2 justify-end mt-5">
                  <Button
                    isDisabled={actionsMutation.loading}
                    onClick={() => navigate(AUTH_ROUTES.LOGIN)}
                  >
                    {t('cancel')}
                  </Button>

                  <FormItem<SpaceRequestCreateInput>>
                    {({ formState, watch }) => (
                      <Button
                        type="submit"
                        color="primary"
                        isLoading={actionsMutation.loading}
                        isDisabled={
                          !Object.keys(formState?.dirtyFields).length ||
                          !watch('agree')
                        }
                      >
                        {t('send')}
                      </Button>
                    )}
                  </FormItem>
                </div>
              </Form>
            </>
          )}
        </div>
      </div>
      {/* EndCol 2 */}
    </main>
  );
}
