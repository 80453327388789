import Form, { FormItem } from 'reactivity-hook-form';
import Input from '@/lib/ui/input.tsx';
import {
  NewPlanPayload,
  useCreatePlan,
  useUpdatePlan
} from '@/app/app/settings/store/mutation/plans.mutation.ts';
import Modal from '@/lib/ui/modal.tsx';
import Text from '@/lib/ui/text.tsx';
import GridColumn from '@/lib/ui/grid-column.tsx';
import { PlanFragmentFragment } from '@/__generated__/graphql.ts';
import SelectSessionCategories from '@/app/app/shared/components/SelectSessionCategories.tsx';

const FORM_NAME = 'new-plan-form';

export default function NewOrUpdatePlanModal({
  item,
  open,
  onClose
}: {
  item?: PlanFragmentFragment | null;
  open: boolean;
  onClose: () => void;
}) {
  const { onCreatePlan, loading: cLoading } = useCreatePlan();
  const { onUpdatePlan, loading: uLoading } = useUpdatePlan();
  const loading = cLoading || uLoading;

  const onSubmit = async (values: NewPlanPayload) => {
    if (item) {
      await onUpdatePlan(item.id, values);
    } else {
      await onCreatePlan(values);
    }
    onClose();
  };

  return (
    <Modal
      isOpen={open}
      onClose={onClose}
      maskClosable={false}
      title="New plan"
      actions={[
        {
          label: 'Cancel',
          color: 'danger',
          variant: 'light',
          onClick: onClose
        },
        {
          label: 'Save',
          color: 'primary',
          form: FORM_NAME,
          type: 'submit',
          isLoading: loading
        }
      ]}
    >
      <Form<NewPlanPayload>
        id={FORM_NAME}
        onSubmit={onSubmit}
        defaultValues={
          item
            ? ({
                ...item,
                categories: item?.categories?.map?.((c) => c.id) || []
              } as NewPlanPayload)
            : undefined
        }
        validations={{
          name: {
            required: 'Required field'
          },
          points: {
            required: 'Required field'
          },
          duration: {
            required: 'Required field'
          },
          price: {
            required: 'Required field'
          }
        }}
      >
        <FormItem<NewPlanPayload> name="name">
          <Input label="Plan name" />
        </FormItem>
        <FormItem<NewPlanPayload> name="points">
          <Input type="number" label="Number classes" />
        </FormItem>
        <GridColumn xs={1} md={2} spacing={2}>
          <FormItem<NewPlanPayload> name="price">
            <Input
              type="number"
              label="Total price"
              startContent={<Text className="text-sm text-default-600">$</Text>}
            />
          </FormItem>
          <FormItem<NewPlanPayload> name="duration">
            <Input type="number" label="Duration (days)" />
          </FormItem>
        </GridColumn>

        <FormItem<NewPlanPayload> name="categories">
          <SelectSessionCategories />
        </FormItem>
      </Form>
    </Modal>
  );
}
