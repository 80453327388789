import { Calendar } from 'lucide-react';
import { FormItem } from 'reactivity-hook-form';

import Input from '@/lib/ui/input';
import Select from '@/lib/ui/select';
import { NewSessionFormType } from '@/app/app/sessions/common/types';
import useFrequencyOptions from '@/app/app/sessions/hooks/useFrequencyOptions.ts';
import { useCreateOrEditSession } from '@/app/app/sessions/components/create-edit-session/context';

export function RepeatIntervalAndFrequencyField() {
  const frequencyOptions = useFrequencyOptions();
  const { session } = useCreateOrEditSession();

  return (
    <div className="flex items-center gap-4">
      <FormItem<NewSessionFormType>
        name="repeatSettings.interval"
        defaultValue={1}
      >
        <Input
          size="sm"
          type="number"
          isDisabled={!!session}
          className="bg-background w-20"
        />
      </FormItem>
      <FormItem<NewSessionFormType> name="repeatSettings.frequency">
        <Select
          size="sm"
          className="w-32"
          isDisabled={!!session}
          options={frequencyOptions}
          startContent={<Calendar size="20px" />}
        />
      </FormItem>
    </div>
  );
}
