import { PropsWithChildren } from 'react';

import { cn } from '@/lib/utils';

// type Size = 'xl' | '2xl';
//
// const sizes: Record<Size, string> = {
//   xl: 'max-w-screen-xl',
//   '2xl': 'max-w-screen-2xl'
// };

type Severity = 'success' | 'warning' | 'error' | 'info';

const severities: Record<Severity, string> = {
  success: 'bg-success-100 text-success-800',
  warning: 'bg-warning-100 text-warning-800',
  error: 'bg-error-100 text-error-800',
  info: 'bg-info-100 text-info-800'
};

export type ContainerProps = PropsWithChildren<{
  className?: string;
  severity?: Severity;
}>;

export default function Alert({
  children,
  severity = 'warning',
  className = '',
  ...rest
}: ContainerProps) {
  return (
    <div
      {...rest}
      className={cn(`p-3 rounded-xl`, severities[severity], className)}
    >
      {children}
    </div>
  );
}
