import 'react-big-calendar/lib/css/react-big-calendar.css';
import './style.css';

import 'dayjs/locale/es';
import dayjs from 'dayjs';
import { useEffect } from 'react';
import updateLocale from 'dayjs/plugin/updateLocale';
import { Calendar, dayjsLocalizer, Messages } from 'react-big-calendar';

import Loading from '@/lib/ui/loading';
import { useTranslation } from '@/hooks/useTranslation';
import {
  EventSchedule,
  ScheduleProps
} from '@/app/app/sessions/components/sessions-list/types';
import HeaderSchedule from '@/app/app/sessions/components/sessions-list/header-schedule';
import { useCalendar } from '@/context/calendar-provider.tsx';
import PopoverEvent from '../popover-event';
import {
  createCardEventStyle,
  isDisabledSession
} from '@/app/app/sessions/common/helper';
import { cn } from '@/lib/utils.ts';

dayjs.extend(updateLocale);
dayjs.updateLocale('en', {
  weekStart: 1
});

const langDictionary: Record<string, Messages> = {
  es: {
    showMore: (total: number) => `+${total} más`
  }
};

type CalendarItemProps = EventSchedule & {
  start: string;
  end: string;
  title: string;
};

export default function ScheduleCalendar({ events, loading }: ScheduleProps) {
  const { timeResolve, date } = useCalendar();
  const { lang } = useTranslation();

  useEffect(() => {
    dayjs.locale(lang);
  }, [lang]);

  return (
    <div className="relative" key={lang}>
      {loading && (
        <div className="fixed md:absolute bottom-0 left-0 h-[calc(100%-100px)] w-full bg-[rgba(255,255,255,0.38)] dark:bg-[rgba(18,18,18,0.38)] z-[10000] grid place-content-center">
          <Loading />
        </div>
      )}
      <Calendar<CalendarItemProps>
        popup
        key={lang}
        date={date}
        style={{ height: 850 }}
        views={['month', 'week']}
        localizer={dayjsLocalizer(dayjs)}
        messages={langDictionary?.[lang as string]}
        view={timeResolve === 'day' ? 'week' : timeResolve}
        components={{
          toolbar: () => {
            return <HeaderSchedule />;
          },
          eventWrapper: ({ event }) => {
            const isDisabledClass = isDisabledSession(
              event?.startedAt,
              event?.isBacklog
            );
            const utilities = createCardEventStyle({
              color: event?.color,
              type: 'CALENDAR'
            });

            return (
              <PopoverEvent {...event}>
                <div className="p-0.5 mx-0.5">
                  <div
                    style={utilities.style}
                    className={cn(
                      `p-0.9 rounded-md border`,
                      {
                        'opacity-40': isDisabledClass
                      },
                      utilities.className
                    )}
                  >
                    {event.name}
                  </div>
                </div>
              </PopoverEvent>
            );
          }
        }}
        events={(events as CalendarItemProps[])?.map?.((event, index) => ({
          ...event,
          id: event?.id ?? index,
          start: event.startedAt ?? '',
          end: event.finishedAt ?? '',
          title: event.name ?? ''
        }))}
      />
    </div>
  );
}
