import { useTranslation } from '@/hooks/useTranslation.ts';
import HeaderPage from '@/app/app/shared/components/HeaderPage.tsx';
import useToggle from '@/hooks/useToggle.ts';
import ClientList from '@/app/app/clients/components/ClientList/ClientList.tsx';
import NewOrEditClient from './components/NewOrEditClient';
import AppContainer from '@/app/app/shared/components/AppContainer.tsx';

export default function ClientsPage() {
  const { t } = useTranslation();
  const [isOpen, actionOpen] = useToggle();

  return (
    <AppContainer>
      <HeaderPage
        title={t('clients.title')}
        primaryAction={{
          text: t('clients.new'),
          onClick: actionOpen.onVisible
        }}
      />

      <ClientList />

      <NewOrEditClient
        isOpen={isOpen}
        key={String(isOpen)}
        onClose={actionOpen.onHidden}
      />
    </AppContainer>
  );
}
