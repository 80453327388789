import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useLinkStripeAccount } from '../../../../store/mutation/stripe.mutation.ts';

/*
  Tu usuario será redirigido a la refresh_url en estos casos:

  The link expired (a few minutes went by since the link was created)
  The user already visited the link (they refreshed the page, or clicked back or forward in the browser)
  Tu plataforma ya no puede acceder a la cuenta
  La cuenta ha sido rechazada
  La refresh_url activa un método en tu servidor para volver a llamar a la API Account Links con los mismos parámetros y redirigir al usuario al flujo de onboarding de Connect para crear una experiencia fluida.

  https://docs.stripe.com/connect/standard-accounts
*/
export default function RefreshPage() {
  const { connectedAccountId } = useParams();
  const linkAccountMutation = useLinkStripeAccount();

  useEffect(() => {
    if (connectedAccountId) {
      linkAccountMutation.onLinkAccount(connectedAccountId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [connectedAccountId]);

  return (
    <div className="container">
      <div className="banner">
        <h2>Rocket Rides</h2>
      </div>
      <div className="content">
        <h2>Add information to start accepting money</h2>
        <p>
          Rocket Rides is the world's leading air travel platform: join our team
          of pilots to help people travel faster.
        </p>
        {linkAccountMutation.error && (
          <p className="error">Something went wrong!</p>
        )}
      </div>
      <div className="dev-callout">
        {connectedAccountId && (
          <p>
            Your connected account ID is:{' '}
            <code className="bold">{connectedAccountId}</code>
          </p>
        )}
        {linkAccountMutation.loading && <p>Creating a new Account Link...</p>}
      </div>
    </div>
  );
}
