import { FormDependencies } from 'reactivity-hook-form';
import { NewSessionFormType } from '@/app/app/sessions/common/types';
import dayjs from 'dayjs';

export const useDependencies = (): FormDependencies<NewSessionFormType> => [
  {
    dependencies: ['repeatSettings'],
    callback(_values, { trigger }) {
      trigger('repeatSettings.finishedRepeatAt');
      return trigger('repeatSettings.count');
    }
  },
  {
    dependencies: ['startTime'],
    callback(_values, { setValue, trigger }) {
      const { startTime, finishTime } = _values;

      if (startTime && !finishTime) {
        setValue('finishTime', dayjs(startTime).add(1, 'hour').toISOString(), {
          shouldValidate: true
        });
      } else {
        return trigger('finishTime');
      }
    }
  },
  {
    dependencies: ['finishTime'],
    callback(_values, { trigger }) {
      trigger('startTime');
    }
  },
  {
    dependencies: [
      // @ts-ignore
      'repeatSettings.frequency',
      // @ts-ignore
      'repeatSettings.interval',
      // @ts-ignore
      'repeatSettings.daysRepeat'
    ],
    callback(_values, { trigger }) {
      trigger('repeatSettings.finishedRepeatAt');
    }
  }
];
