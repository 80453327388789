import GenericCard from '@/lib/ui/generic-card.tsx';
import { useGetTotalCustomers } from '@/app/app/dashboard/store/query/dashboard.query.ts';
import Loading from '@/lib/ui/loading.tsx';
import { useTranslation } from '@/hooks/useTranslation.ts';
import formatTotal from '@/app/app/dashboard/common/formatTotal.ts';

export default function TotalActiveCustomers() {
  const { t } = useTranslation();
  const { loading, data } = useGetTotalCustomers();

  return (
    <GenericCard
      variant="secondary"
      title={t('total_customers')}
      classNameBody="flex items-center gap-2 text-4xl"
      className="w-full"
      footer={
        !loading && (
          <span>
            {formatTotal(data?.totals?.diff)} respecto al periodo anterior
          </span>
        )
      }
      subTitle={
        <>
          {loading ? (
            <Loading className="min-h-[auto]" />
          ) : (
            <span>{data?.totals?.current || 0}</span>
          )}
        </>
      }
    />
  );
}
