import * as React from 'react';
import Text from '@/lib/ui/text.tsx';
import { Tooltip } from '@nextui-org/react';
import dayjs from 'dayjs';
import { formatDate, formatDateComplete, formatTime } from '@/lib/utils.ts';

type BaseProps = Omit<
  React.ButtonHTMLAttributes<HTMLParagraphElement>,
  'children'
> & {
  date: dayjs.ConfigType;
  format?: 'date' | 'datetime';
};

const DateCell = React.forwardRef<HTMLParagraphElement, BaseProps>(
  ({ date, format = 'date', ...props }, ref) => {
    if (!dayjs(date).isValid()) {
      return (
        <Text {...props} ref={ref}>
          -
        </Text>
      );
    }

    return (
      <Tooltip content={formatDateComplete(date, { format })}>
        <Text {...props} ref={ref}>
          {formatDate(date)} {format === 'datetime' && formatTime(date)}
        </Text>
      </Tooltip>
    );
  }
);

export default DateCell;
