import {
  PieChart as PieChartLib,
  PieChartProps
} from 'react-minimal-pie-chart';
import ReactTooltip from 'react-tooltip';
import { ReactNode, useId, useState } from 'react';
import { cn } from '@/lib/utils.ts';
import { Table } from '@/lib/ui/table.tsx';
import EmptyState from '@/lib/ui/empty-state.tsx';
import Loading from '@/lib/ui/loading.tsx';
import Text from '@/lib/ui/text.tsx';
import { darkenColor } from '@/utils/colors.ts';

export type PieChartType = {
  data: PieChartProps['data'];
  formatValue?: (value: number) => ReactNode;
  chartWrapperClass?: string;
  loading?: boolean;
};

type BaseData = PieChartProps['data'][number];

function makeTooltipContent(
  entry: BaseData,
  formatValue: PieChartType['formatValue'] = (value) => value
) {
  return (
    <div className="flex flex-row gap-2 items-center py-1">
      <div
        className="h-[15px] w-[15px] rounded"
        style={{ backgroundColor: entry.color }}
      />

      <Text>
        {entry.title}: {formatValue(entry.value)}
      </Text>
    </div>
  );
}

function calcPercentage(value: number, total: number) {
  const totalDiv = value / total;
  if (Number.isNaN(totalDiv)) {
    return '0.00';
  }
  return ((value / total) * 100).toFixed(2).replace('.00', '');
}

export default function PieChart({
  chartWrapperClass,
  loading,
  data = [],
  formatValue = (value) => value
}: PieChartType) {
  const chartId = useId();
  const [hovered, setHovered] = useState<number | null>(null);
  const _data = data.filter((item) => item.value);

  const total = _data.reduce((acc, { value }) => acc + value, 0);
  const isEmpty = !total;

  if (loading) {
    return <Loading className="min-h-[300px]" />;
  }

  return (
    <div className="flex flex-col md:flex-row gap-10 md:justify-center md:items-center h-full p-4">
      {/* graphic */}
      <div
        className={cn('w-full flex-1', chartWrapperClass)}
        data-tip=""
        data-for={chartId}
      >
        {isEmpty ? (
          <EmptyState />
        ) : (
          <PieChartLib
            rounded={true}
            data={_data}
            paddingAngle={25}
            // labelPosition={85}
            // label={(item) => `${item.dataEntry.percentage.toFixed(2)}%`}
            // labelStyle={{
            //   fontSize: '0.2rem'
            // }}
            segmentsStyle={(dataIndex) => {
              if (hovered === dataIndex) {
                return {
                  cursor: 'pointer',
                  transition: 'stroke 0.2s',
                  stroke: darkenColor(_data[dataIndex].color, 10)
                };
              }
              return {
                cursor: 'pointer',
                transition: 'stroke 0.3s'
              };
            }}
            lineWidth={30}
            onMouseOver={(_, index) => {
              setHovered(index);
            }}
            onMouseOut={() => {
              setHovered(null);
            }}
          />
        )}

        <ReactTooltip
          id={chartId}
          getContent={() =>
            typeof hovered === 'number'
              ? makeTooltipContent(_data[hovered] as any, formatValue)
              : null
          }
        />
      </div>

      {/* labels */}
      <div className="flex flex-col gap-4 h-full justify-center">
        <Table
          isCompact
          withImage={false}
          rows={data
            .filter((i) => i.value)
            .sort((a, b) => {
              if (a.value > b.value) return -1;
              if (a.value < b.value) return 1;
              return 0;
            })}
          classNames={{
            wrapper: 'shadow-none'
          }}
          getRowId={(_, index) => index}
          columns={[
            {
              field: 'tooltip',
              headerName: 'Label',
              renderCell({ row }) {
                return (
                  <div className="flex gap-2 items-center">
                    <div
                      className="w-3 h-3 rounded"
                      style={{ backgroundColor: row?.color }}
                    />
                    {row?.title}
                  </div>
                );
              }
            },
            {
              field: 'percentage',
              headerName: 'Percentage',
              renderCell({ row }) {
                return `${calcPercentage(row.value, total)}%`;
              }
            },
            {
              field: 'value',
              headerName: 'Value',
              renderCell({ row }) {
                return <>{formatValue(row?.value)}</>;
              }
            }
          ]}
        />
        {/*{data.map(({ title, value, color }) => (*/}
        {/*  <div>*/}
        {/*    <div className="flex gap-2 items-center">*/}
        {/*      <div*/}
        {/*        className="w-3 h-3 rounded"*/}
        {/*        style={{ backgroundColor: color }}*/}
        {/*      />*/}
        {/*      <Text className="text-default-700 text-sm">{title}</Text>*/}
        {/*    </div>*/}
        {/*    <Text className="font-bold text-lg mt-2">*/}
        {/*      {calcPercentage(value, total)}%*/}
        {/*    </Text>*/}
        {/*  </div>*/}
        {/*))}*/}
      </div>
    </div>
  );
}
