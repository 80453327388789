import dayjs from 'dayjs';
import { useEffect, useRef, useState } from 'react';

import {
  Button,
  Popover,
  PopoverContent,
  PopoverTrigger
} from '@nextui-org/react';
import { Timer, X } from 'lucide-react';

import { cn } from '@/lib/utils';
import Select, { Key } from '@/lib/ui/select.tsx';
import Input, { InputProps } from '@/lib/ui/input.tsx';
import useToggle from '@/hooks/useToggle.ts';
import { useTranslation } from '@/hooks/useTranslation.ts';
import ToggleButton from '@/lib/ui/toggle-button.tsx';
import Text from '@/lib/ui/text.tsx';

export enum Period {
  AM = 'AM',
  PM = 'PM'
}

const HOURS_OPTIONS = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map((index) => {
  return {
    label: index.toString().padStart(2, '0'),
    value: index.toString().padStart(2, '0')
  };
});

const MINUTES_OPTIONS = Array.from(new Array(60).keys()).map((index) => {
  return {
    label: index.toString().padStart(2, '0'),
    value: index.toString().padStart(2, '0')
  };
});

const PERIOD_OPTIONS = [
  {
    label: 'AM',
    value: Period.AM
  },
  {
    label: 'PM',
    value: Period.PM
  }
];

type InternalFormType = {
  hours?: Key;
  minutes?: Key;
  period: Period;
};

const defaultValues: InternalFormType = {
  period: Period.AM,
  hours: '',
  minutes: '00'
};

export function TimePicker({
  placeholder,
  value,
  onChange,
  ...rest
}: Partial<InputProps>) {
  const { t } = useTranslation();
  const popoverRef = useRef<HTMLDivElement>(null);
  const [date, setDate] = useState<string>('');
  const [showError, showErrorActions] = useToggle();
  const [values, setValues] = useState<InternalFormType>(defaultValues);

  const isEmpty = Object.values(values).some((f) => !f);
  const isError = isEmpty && showError;
  const enabledSubmit = !isEmpty;

  const onChangeField = (key: keyof InternalFormType) => {
    return (value: Key) => {
      // const isHourChange = key === 'hours';

      return setValues((prev) => {
        return {
          ...prev,
          [key]: value
        };
      });
    };
  };

  const onCompleted = () => {
    const { minutes: _minutes, period, hours: _hours } = values;
    let hours = Number(_hours);
    const minutes = Number(_minutes);

    if (period === Period.PM) {
      if (hours < 12) {
        hours += 12;
      } else {
        hours = 12;
      }
    } else if (hours === 12) {
      hours = 0;
    }

    const _date = dayjs()
      .set('hours', hours)
      .set('minutes', minutes)
      .set('seconds', 0)
      .set('milliseconds', 0)
      .toISOString();

    setDate(_date);
    onChange?.({
      target: {
        name: rest?.name || '',
        value: _date
      }
    } as any);

    popoverRef.current?.click?.();
  };

  // const onCancel = () => {
  //   if (date) {
  //     const _date = dayjs(date);
  //     setValues({
  //       period: _date.format('A') as Period,
  //       hours: _date.format('hh'),
  //       minutes: _date.format('mm')
  //     });
  //   } else {
  //     setDate('');
  //     setValues(defaultValues);
  //   }
  //   showErrorActions.onHidden();
  //   popoverRef.current?.click?.();
  // };

  const onReset = () => {
    setValues(defaultValues);
    setDate('');
    return onChange?.({
      target: {
        name: rest?.name || '',
        value: null
      }
    } as any);
  };

  useEffect(() => {
    if (typeof value !== 'undefined' && value !== null) {
      const _date = dayjs(value);
      setDate(value);

      setValues({
        period: _date.format('A') as Period,
        hours: _date.format('hh'),
        minutes: _date.format('mm')
      });
    }
  }, [value]);

  return (
    <Popover
      offset={20}
      shadow="md"
      triggerRef={popoverRef}
      placement="bottom"
      onOpenChange={(op) => {
        if (!op) {
          onCompleted();
        }
      }}
    >
      <PopoverTrigger>
        <Input
          {...rest}
          value={date ? dayjs(date).format('hh:mm A') : ''}
          startContent={
            <div
              onClick={rest?.onClick}
              className="absolute flex gap-2 flex-shrink-0 items-center z-10"
            >
              <div>
                <Timer size="20px" />
              </div>

              {!date && (
                <span className="text-foreground-500 font-normal w-full text-left text-small truncate">
                  {placeholder}
                </span>
              )}
            </div>
          }
          endContent={
            !isEmpty && (
              <X
                size="20px"
                className="cursor-pointer"
                onClick={(e) => {
                  e?.stopPropagation();
                  e?.preventDefault();
                  onReset();
                }}
              />
            )
          }
          classNames={{
            input: cn('text-left z-20 h-full pt-5 ps-[27px!important]'),
            innerWrapper: 'flex'
          }}
        />
      </PopoverTrigger>
      <PopoverContent className="w-auto bg-popover p-5 gap-3">
        <div>
          <Text className="mb-2 font-bold">{t('time_picker')}</Text>

          <div className="flex gap-2 flex-wrap justify-center items-center">
            <Select
              size="sm"
              label="HH"
              className="w-24"
              value={values.hours}
              options={HOURS_OPTIONS}
              onChange={onChangeField('hours')}
            />

            <Select
              size="sm"
              label="MM"
              className="w-24"
              value={values.minutes}
              options={MINUTES_OPTIONS}
              onChange={onChangeField('minutes')}
            />

            <ToggleButton
              value={values.period}
              options={PERIOD_OPTIONS}
              onChange={onChangeField('period')}
            />
          </div>
          {isError && (
            <span className="text-xs text-danger">
              {t('validations.is_required')}
            </span>
          )}
        </div>

        <div className="w-full flex justify-end gap-0.5 items-center">
          {/*<Button*/}
          {/*  variant="light"*/}
          {/*  onClick={() => {*/}
          {/*    onCancel();*/}
          {/*  }}*/}
          {/*>*/}
          {/*  {t('cancel')}*/}
          {/*</Button>*/}
          <Button
            variant="light"
            color="primary"
            onClick={!enabledSubmit ? showErrorActions.onVisible : onCompleted}
          >
            {t('ok')}
          </Button>
        </div>
      </PopoverContent>
    </Popover>
  );
}
