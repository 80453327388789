import 'dayjs/locale/es';
import 'dayjs/locale/en';
import dayjs from 'dayjs';
import { useEffect } from 'react';
import { useLocation } from '@/context/location-provider.tsx';

export function DayjsChangeLocation() {
  const { lang } = useLocation();

  useEffect(() => {
    dayjs.locale(lang);
  }, [lang]);

  return null;
}
