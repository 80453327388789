import Form, { FormItem } from 'reactivity-hook-form';
import Input from '@/lib/ui/input.tsx';
import {
  NewBadgePayload,
  useCreateBadge,
  useUpdateBadge
} from '@/app/app/settings/store/mutation/badge.mutation.ts';
import Modal, { ModalActions } from '@/lib/ui/modal.tsx';
import { BadgeFragmentFragment } from '@/__generated__/graphql.ts';
import { useTranslation } from '@/hooks/useTranslation.ts';

const FORM_NAME = 'new-badge-form';

export default function NewBadgeModal({
  open,
  item,
  onClose
}: {
  item?: BadgeFragmentFragment | null;
  open: boolean;
  onClose: () => void;
}) {
  const { t } = useTranslation();
  const { onCreateBadge, loading: loadingNew } = useCreateBadge();
  const { onUpdateBadge, loading: loadingEdit } = useUpdateBadge();

  const loading = loadingEdit || loadingNew;

  const onSubmit = async (values: NewBadgePayload) => {
    if (item) {
      await onUpdateBadge({
        badgeId: item.id,
        data: values
      });
    } else {
      await onCreateBadge(values);
    }
    onClose();
  };

  return (
    <Modal
      isOpen={open}
      onClose={onClose}
      maskClosable={false}
      title={item ? t('settings.edit_category') : t('settings.new_category')}
    >
      <Form<NewBadgePayload>
        id={FORM_NAME}
        onSubmit={onSubmit}
        defaultValues={{
          name: item?.name ?? ''
        }}
        validations={{
          name: {
            required: t('validations.required')
          }
        }}
      >
        <FormItem<NewBadgePayload> name="name">
          <Input label={t('name')} />
        </FormItem>
      </Form>

      <ModalActions
        isLoading={loading}
        formName={FORM_NAME}
        onCancel={onClose}
        variant={item ? 'edit' : 'save'}
      />
    </Modal>
  );
}
