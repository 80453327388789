import { Card, CardBody, CardHeader } from '@nextui-org/react';
import { cn } from '@/lib/utils.ts';
import Text from '@/lib/ui/text.tsx';
import dayjs from 'dayjs';
import { useGetTotalCustomers } from '@/app/app/dashboard/store/query/dashboard.query.ts';
import { useTranslation } from '@/hooks/useTranslation.ts';
import Title from '@/lib/ui/title.tsx';
import PieChart from '@/lib/ui/charts/pie-chart.tsx';
import { PieChartProps } from 'react-minimal-pie-chart';

export function CustomersGraph({ className }: { className?: string }) {
  const { t } = useTranslation();
  const { data, loading } = useGetTotalCustomers();

  const customers: PieChartProps['data'] = [
    {
      key: 'active',
      value: data?.active || 0,
      title: t('active'),
      color: '#5AD1A5'
    },
    {
      key: 'inactive',
      value: data?.inactive || 0,
      title: t('inactive'),
      color: '#B0BEC5'
    }
  ];

  return (
    <Card className={cn('relative block', className)}>
      <CardBody>
        <CardHeader className="flex-col items-start">
          <div className="flex gap-1 justify-between items-end">
            <Title size="lg" className="mb-0">
              {t('total_customers')}
            </Title>
            <Text className="text-sm mb-1">{dayjs().format('MMM, YYYY')}</Text>
          </div>
          <Text className="mb-4">{t('activity.sub_title')}</Text>
        </CardHeader>

        <PieChart
          loading={loading}
          formatValue={(value) => value}
          chartWrapperClass="max-w-[300px]"
          data={customers}
        />
      </CardBody>
    </Card>
  );
}
