import { RouteObject } from 'react-router/dist/lib/context';
import NotificationList from '@/app/app/notifications/page.tsx';
import NewNotification from '@/app/app/notifications/new.tsx';
import { PrivateRotes } from '@/routes/private-rotes.ts';

const routes: RouteObject[] = [
  {
    path: PrivateRotes.NOTIFICATIONS,
    Component: NotificationList
  },
  {
    path: PrivateRotes.NEW_NOTIFICATIONS,
    Component: NewNotification
  }
];

export default routes;
