import { Button } from '@nextui-org/react';
import { toast } from 'sonner';
import { UseFormReturn } from 'react-hook-form';
import Form, {
  FormDependencies,
  FormItem,
  FormValidations
} from 'reactivity-hook-form';

import Input from '@/lib/ui/input';
import { useTranslation } from '@/hooks/useTranslation';
import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signInWithGooglePopup
} from '@/lib/firebase';
import { VerifyTokenQueryQuery } from '@/__generated__/graphql.ts';
import { useUpdateFirebaseUIDMutation } from '@/app/auth/_store/mutation/auth.mutation.ts';
import { AUTH_ROUTES } from '@/app/auth/_common/routes.ts';
import { useGetFirebaseUser } from '@/app/auth/_store/mutation/auth.query.ts';
import Alert from '@/lib/ui/alert.tsx';
import Text from '@/lib/ui/text.tsx';
import Loading from '@/lib/ui/loading.tsx';
import ScreenLoading from '@/lib/ui/screen-loading.tsx';

type LoginFormType = DeepNonNullable<VerifyTokenQueryQuery>['verifyToken'] & {
  password: string;
  password1: string;
};

const dependencies: FormDependencies<LoginFormType> = [
  {
    dependencies: ['password1', 'password'],
    callback(_, { trigger }) {
      trigger('password');
      trigger('password1');
    }
  }
];

const validations: FormValidations<LoginFormType> = {
  password: {
    required: 'Este campo es requerido',
    validate(value, values) {
      if (!!value && value !== values.password1) {
        return 'Las contraseñas no coinciden';
      }
    }
  },
  password1: {
    required: 'Este campo es requerido',
    validate(value, values) {
      if (!!value && value !== values.password) {
        return 'Las contraseñas no coinciden';
      }
    }
  }
};

export default function CompleteForm({
  defaultValues,
  token
}: {
  token: string;
  defaultValues?: DeepNonNullable<VerifyTokenQueryQuery>['verifyToken'];
}) {
  const { t } = useTranslation();
  const { data: user, loading: loadingVerify } = useGetFirebaseUser(
    defaultValues?.email
  );

  const { onUpdateFirebaseUID, loading: loadingMutation } =
    useUpdateFirebaseUIDMutation();

  const isGoogleProvider = user?.providerData?.some?.(
    (p) => p?.providerId === 'google.com'
  );
  const providers = user?.providerData
    ?.map?.((provider) => provider?.providerId)
    ?.join('/');

  const onFinishRedirect = () => {
    window.location.href = AUTH_ROUTES.LOGIN;
  };

  const onGoogleLogin = async () => {
    const google = await signInWithGooglePopup();

    if (google?.user && google?.user?.email === defaultValues?.email) {
      await onUpdateFirebaseUID({
        firebaseUID: google?.user?.uid,
        token
      });

      onFinishRedirect();
    } else if (google?.user?.email !== defaultValues?.email) {
      toast.error(
        <div className="flex flex-col gap-1">
          <Text className="font-bold">{t('errors_message.email_not_match')}</Text>

          <Text className="text-sm">{t('complete_profile_alert_no_match')}</Text>
        </div>
      );
    }
  };

  const onEmailAndPasswordLogin = async (
    payload: LoginFormType,
    context: UseFormReturn<LoginFormType>
  ) => {
    try {
      let internalUser = user;

      if (
        !internalUser?.providerData?.some?.((p) => p?.providerId === 'password')
      ) {
        internalUser = (await createUserWithEmailAndPassword(payload))?.user;
      } else {
        internalUser = (await signInWithEmailAndPassword(payload))?.user;
      }

      if (internalUser) {
        await onUpdateFirebaseUID({
          firebaseUID: internalUser?.uid,
          token
        });
        onFinishRedirect();
      }
    } catch (e) {
      const error = e as { code?: string; message?: string };
      context.setError('password', {
        type: 'validate',
        message: t(
          `errors_message.${error?.code}` as LanguageKeys,
          error?.message
        )
      });
    }
  };

  if (loadingVerify) return <Loading />;

  return (
    <Form<LoginFormType>
      key={defaultValues?.id}
      validations={validations}
      dependencies={dependencies}
      defaultValues={defaultValues}
    >
      {loadingMutation && <ScreenLoading />}
      {!!user && (
        <Alert>
          <Text className="font-bold">{t('already_account')}</Text>
          <Text className="text-sm">
            {t('complete_profile_alert')}
            {providers}
          </Text>
        </Alert>
      )}
      <FormItem<LoginFormType> name="email">
        <Input
          isDisabled
          label={t('form.email')}
          placeholder={t('form_placeholder.email')}
        />
      </FormItem>

      <FormItem<LoginFormType> name="firstName">
        <Input
          isDisabled
          label={t('form.first_name')}
          placeholder={t('form_placeholder.first_name')}
        />
      </FormItem>

      <FormItem<LoginFormType> name="lastName">
        <Input
          isDisabled
          label={t('form.last_name')}
          placeholder={t('form_placeholder.last_name')}
        />
      </FormItem>

      <FormItem<LoginFormType> name="password">
        <Input
          type="password"
          label={t('form.password')}
          isDisabled={isGoogleProvider}
          placeholder={t('form_placeholder.password')}
        />
      </FormItem>

      <FormItem<LoginFormType> name="password1">
        <Input
          type="password"
          label={t('form.password')}
          isDisabled={isGoogleProvider}
          placeholder={t('form_placeholder.password')}
        />
      </FormItem>

      {!isGoogleProvider && (
        <FormItem<LoginFormType>>
          {(context) => (
            <Button
              fullWidth
              color="primary"
              className=""
              isLoading={loadingMutation || context.formState.isSubmitting}
              onClick={context.handleSubmit((values) =>
                onEmailAndPasswordLogin(values, context)
              )}
            >
              {t('complete_profile')}
            </Button>
          )}
        </FormItem>
      )}

      <div className="mt-20 flex flex-col items-center gap-10">
        <div className="h-[2px] bg-gray-200 relative w-full">
          <span className="text-gray-600 absolute top-[-12px] left-0 right-0 m-auto w-fit px-4 bg-background">
            {t('or')}
          </span>
        </div>

        <FormItem<LoginFormType>>
          {(context) => (
            <Button
              type="button"
              variant="ghost"
              onClick={onGoogleLogin}
              disabled={context.formState.isSubmitting || loadingMutation}
              className="bg-background text-text gap-5 shadow-lg"
            >
              <img
                loading="lazy"
                alt="google logo"
                className="w-6 h-6"
                src="https://www.svgrepo.com/show/475656/google-color.svg"
              />
              <span>{t('continue_with_google')}</span>
            </Button>
          )}
        </FormItem>
      </div>
    </Form>
  );
}
