import { useMutation } from '@apollo/client';
import { toast } from 'sonner';
import {
  VERIFY_STRIPE_ACCOUNT,
  LINK_STRIPE_ACCOUNT,
  LOGIN_STRIPE_ACCOUNT,
  CREATE_STRIPE_ACCOUNT, STRIPE_ACCOUNT_COMPLETED
} from './graphql/mutations.graphql';
import { useAuth } from '@/app/auth/_context';

export function useCreateStripeAccount() {
  const { activeSpace } = useAuth();
  const [mutation, actionMutation] = useMutation(CREATE_STRIPE_ACCOUNT, {
    onError(e) {
      toast('Failed', {
        description: e?.message || 'Error saving account'
      });
    }
  });

  const onCreateAccount = async () => {
    try {
      const { data } = await mutation({
        variables: {
          spaceId: activeSpace?.space?.id as string
        }
      });
      return data?.createStripeAccount?.accountLink;
    } catch (error) {
      toast.error((error as Error)?.message || 'Error creating account');
      console.error(error);
      throw error;
    }
  };

  return { onCreateAccount, ...actionMutation };
}

export function useVerifyStripeAccount() {
  const [mutation, actionMutation] = useMutation(VERIFY_STRIPE_ACCOUNT);

  const onVerifyAccount = async (accountId: string) => {
    const { data } = await mutation({
      variables: {
        accountId
      }
    });
    return data?.verifyStripeAccount;
  };

  return { onVerifyAccount, ...actionMutation };
}

export function useLoginStripeAccount() {
  const { activeSpace } = useAuth();
  const [mutation, actionMutation] = useMutation(LOGIN_STRIPE_ACCOUNT, {
    onError(e) {
      toast('Failed', {
        description: e?.message || 'Error login account'
      });
    }
  });

  const onMutation = async () => {
    const { data } = await mutation({
      variables: {
        spaceId: activeSpace?.space?.id as string
      }
    });
    return data?.loginStripeAccount?.client_secret as string;
  };

  return { onMutation, ...actionMutation };
}

export function useLinkStripeAccount() {
  const [mutation, actionMutation] = useMutation(LINK_STRIPE_ACCOUNT, {
    onError(e) {
      toast('Failed', {
        description: e?.message || 'Error linking account'
      });
    }
  });

  const onLinkAccount = async (accountId: string) => {
    const { data } = await mutation({
      variables: {
        accountId
      }
    });
    return data?.linkStripeAccount?.accountLink;
  };

  return { onLinkAccount, ...actionMutation };
}

export function useCompleteStripeAccount() {
  const [mutation, actionMutation] = useMutation(STRIPE_ACCOUNT_COMPLETED, {
    onError(e) {
      toast('Failed', {
        description: e?.message || 'Error update account'
      });
    }
  });

  const onMutation = async (paymentProviderId: string) => {
    const { data } = await mutation({
      variables: {
        paymentProviderId
      }
    });
    return data?.updatePaymentsProvider;
  };

  return { onMutation, ...actionMutation };
}
