import { initializeApp } from 'firebase/app';
import {
  getAuth,
  GoogleAuthProvider,
  signInWithPopup,
  sendPasswordResetEmail,
  signInWithEmailAndPassword as signInWithEmailAndPasswordLib,
  createUserWithEmailAndPassword as createUserWithEmailAndPasswordLib
} from '@firebase/auth';

const firebaseConfig = {
  apiKey: import.meta.env.VITE_API_FIREBASE_API_KEY,
  authDomain: import.meta.env.VITE_API_FIREBASE_AUTH_DOMAIN,
  projectId: import.meta.env.VITE_API_FIREBASE_PROJECT_ID,
  storageBucket: import.meta.env.VITE_API_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: import.meta.env.VITE_API_FIREBASE_MESSAGE_SENDER_ID,
  appId: import.meta.env.VITE_API_FIREBASE_APP_ID,
  measurementId: import.meta.env.VITE_API_FIREBASE_MEASUREMENT_ID
};

const app = initializeApp(firebaseConfig);

// Initialize Firebase Auth provider
const provider = new GoogleAuthProvider();

// whenever a user interacts with the provider, we force them to select an account
provider.setCustomParameters({
  prompt: 'select_account '
});

export const auth = getAuth();

export const resetPasswordInFirebase = (email: string) =>
  sendPasswordResetEmail(auth, email);

export const signInWithGooglePopup = () => signInWithPopup(auth, provider);

export const signInWithEmailAndPassword = (payload: {
  email: string;
  password: string;
}) => signInWithEmailAndPasswordLib(auth, payload.email, payload.password);

export const createUserWithEmailAndPassword = (payload: {
  email: string;
  password: string;
}) => createUserWithEmailAndPasswordLib(auth, payload.email, payload.password);

export default app;
