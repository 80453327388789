import { gql } from '@/__generated__/gql.ts';

export const GET_CLIENTS_QUERY = gql(`
  query getClients($spaceId: ID!) {
    space(where: { id: $spaceId }) {
      clients {
        ...ClientsAssociatedFragment
      }
    }
  }
`);

export const GET_CLIENT_QUERY = gql(`
  query getClientById($clientId: ID!) {
    user(where: { id: $clientId }) {
      ...ClientCompleteFragment
    }
  }
`);

export const GET_CLIENT_NOTIFICATIONS_BASE_QUERY = gql(`
  query getClientsNotificationsBase($spaceId: ID!, $take: Int, $skip: Int, $search: String) {
    space(where: { id: $spaceId }) {
      clientsCount(
        where: {
          user: {
            OR: [
              {
                firstName: {
                  contains: $search,
                  mode: insensitive
                }
              },
              {
                lastName: { 
                  contains: $search, 
                  mode: insensitive 
                }
              }, 
              { 
                email: { 
                  contains: $search, 
                  mode: insensitive 
                } 
              }
            ]
          }
        }
      )
      clients(
        take: $take,
        skip: $skip,
        where: {
          user: {
            OR: [
              {
                firstName: {
                  contains: $search,
                  mode: insensitive
                }
              },
              {
                lastName: { 
                  contains: $search, 
                  mode: insensitive 
                }
              }, 
              { 
                email: { 
                  contains: $search, 
                  mode: insensitive 
                } 
              }
            ]
          }  
        }
      ) {
        ...ClientsAssociatedFragment
      }
    }
  }
`);

export const GET_CLIENT_NOTIFICATIONS_ACTIVE_QUERY = gql(`
  query getClientsNotificationsActive($spaceId: ID!, $date: DateTime!, $take: Int, $skip: Int, $search: String) {
    space(where: { id: $spaceId }) {
      clientsCount(
        where: {
          user: {
            OR: [
              {
                firstName: {
                  contains: $search,
                  mode: insensitive
                }
              },
              {
                lastName: { 
                  contains: $search, 
                  mode: insensitive 
                }
              }, 
              { 
                email: { 
                  contains: $search, 
                  mode: insensitive 
                } 
              }
            ]
            credits: { 
              some: {
                space: {
                  id: {
                    equals: $spaceId
                  }
                }
                expiredAt: {
                  gte: $date
                }
              }
            }
          }
        }
      )
      clients(
        take: $take,
        skip: $skip,
        where: {
          user: {
            OR: [
              {
                firstName: {
                  contains: $search,
                  mode: insensitive
                }
              },
              {
                lastName: { 
                  contains: $search, 
                  mode: insensitive 
                }
              }, 
              { 
                email: { 
                  contains: $search, 
                  mode: insensitive 
                } 
              }
            ]
            credits: { 
              some: {
                space: {
                  id: {
                    equals: $spaceId
                  }
                }
                expiredAt: {
                  gte: $date
                }
              }
            }
          }
        }) {
        ...ClientsAssociatedFragment
      }
    }
  }
`);

export const GET_CLIENT_SESSIONS_INACTIVE_QUERY = gql(`
  query getClientsNotificationsInactive($spaceId: ID!, $date: DateTime!, $take: Int, $skip: Int, $search: String) {
    space(where: { id: $spaceId }) {
      clientsCount(
        where: {
          user: {
            OR: [
              {
                firstName: {
                  contains: $search,
                  mode: insensitive
                }
              },
              {
                lastName: { 
                  contains: $search, 
                  mode: insensitive 
                }
              }, 
              { 
                email: { 
                  contains: $search, 
                  mode: insensitive 
                } 
              }
            ]
            credits: { 
              every: {
                space: {
                  id: {
                    equals: $spaceId
                  }
                }
                expiredAt: {
                  lte: $date
                }
              }
            }
          }
        }
      )
      clients(
        take: $take,
        skip: $skip,
        where: {
          user: {
            OR: [
              {
                firstName: {
                  contains: $search,
                  mode: insensitive
                }
              },
              {
                lastName: { 
                  contains: $search, 
                  mode: insensitive 
                }
              }, 
              { 
                email: { 
                  contains: $search, 
                  mode: insensitive 
                } 
              }
            ]
            credits: { 
              every: {
                space: {
                  id: {
                    equals: $spaceId
                  }
                }
                expiredAt: {
                  lte: $date
                }
              }
            }
          }
        }) {
        ...ClientsAssociatedFragment
      }
    }
  }
`);

export const GET_CLIENT_SESSIONS_QUERY = gql(`
  query getSessionClientById($clientId: ID!, $take: Int, $skip: Int) {
    user(where: { id: $clientId }) {
      subscriptionsCount
      subscriptions(take: $take, skip: $skip) {
       id
       sessionForSubscribe {
        ...SessionFragment
       }
      }
    }
  }
`);

export const GET_SUBSCRIPTION_BY_CLIENT_QUERY = gql(`
  query GetCreditsByClient($clientId: ID!) {
    credits(where: { user: { id: { equals: $clientId } } }, orderBy: [{ createdAt: desc }]) {
      ...CreditFragment
    }
  }
`);
