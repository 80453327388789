import Loading from '@/lib/ui/loading';

export default function ScreenLoading() {
  return (
    <div className="fixed top-0 left-0 h-screen w-screen grid place-content-center z-20 bg-background">
      <img src="/icon.png" width={150} />
      <div className="absolute w-screen bottom-10 left-0 flex justify-center md:bottom-24">
        <Loading />
      </div>
    </div>
  );
}
