import { useCallback, useMemo } from 'react';
import { useLazyQuery } from '@apollo/client';
import { gql } from '@/__generated__';
import { UserFragmentFragment } from '@/__generated__/graphql.ts';

const GET_USER_BY_EMAIL = gql(`
  query GetUserByEmail($email: String!) {
    users(where: { email: { equals: $email } }) {
      ...UserFragment
    }
  }
`);

export function useVerifyEmail() {
  const [onFetch, { data, loading }] = useLazyQuery(GET_USER_BY_EMAIL, {
    fetchPolicy: 'no-cache'
  });

  const onVerifyEmail = useCallback(
    async (email: string) => {
      const { data } = await onFetch({
        variables: { email },
        fetchPolicy: 'no-cache'
      });

      return (data?.users?.[0] || null) as UserFragmentFragment | null;
    },
    [onFetch]
  );

  const returnData = useMemo(() => data?.users?.[0] || null, [data]);

  return {
    onVerifyEmail,
    loading,
    data: returnData as UserFragmentFragment | null
  };
}
