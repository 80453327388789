import { useQuery } from '@apollo/client';
import { useAuth } from '@/app/auth/_context';
import { GET_SESSION_CATEGORIES_BY_SPACE } from '@/app/app/settings/store/query/graphql/queries.graphql.ts';
import { useEffect } from 'react';

export function useGetSessionsCategories(key: number) {
  const { activeSpace } = useAuth();

  const query = useQuery(GET_SESSION_CATEGORIES_BY_SPACE, {
    skip: !activeSpace?.id,
    variables: {
      spaceId: activeSpace?.space?.id as string
    }
  });

  useEffect(() => {
    if (key) {
      query.refetch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [key])

  return query;
}
