import { toast } from 'sonner';
import { useMutation } from '@apollo/client';

import { useAuth } from '@/app/auth/_context';
import * as QUERY from '@/app/app/coaches/store/query/graphql/coaches.graphql';
import {
  CREATE_COACHES_QUERY,
  CHANGE_STATUS_COACH_QUERY,
  UPDATE_COACH_QUERY
} from '@/app/app/coaches/store/mutation/graphql/coaches.graphql';
import {
  CoachFragmentFragment,
  CreateCoachMutationVariables,
  UpdateCoachMutation,
  UpdateCoachMutationVariables
} from '@/__generated__/graphql.ts';

export function useNewCoach() {
  const { activeSpace } = useAuth();
  const spaceId = activeSpace?.space?.id;

  const [onFunction, restMutation] = useMutation(CREATE_COACHES_QUERY, {
    refetchQueries: [
      {
        query: QUERY.GET_COACHES_QUERY,
        variables: {
          spaceId
        }
      }
    ]
  });

  const onCreateCoach = async (
    data: Omit<CreateCoachMutationVariables['data'], 'space' | 'status'>
  ) => {
    try {
      await onFunction({
        variables: {
          data: {
            ...data,
            space: {
              connect: {
                id: spaceId as string
              }
            }
          }
        }
      });
    } catch (e) {
      console.log(e);
      toast.error((e as Error)?.message || 'Failed to create coach');
      throw e;
    }
  };

  return { onCreateCoach, ...restMutation };
}

export function useUpdateCoach() {
  const [onFunction, restMutation] = useMutation(UPDATE_COACH_QUERY, {
    onCompleted({ updateCoach }) {
      if (updateCoach) {
        toast.success("Coach's fields has been changed");
      } else {
        toast.error("Error occurred while updating coach's fields");
      }
    }
  });

  const onUpdateCoach = async ({
    coachID,
    data
  }: UpdateCoachMutationVariables) => {
    try {
      return await onFunction({
        variables: {
          coachID,
          data
        }
      });
    } catch (e) {
      console.log(e);
      toast.error((e as Error)?.message || 'Failed to update coach');
    }
  };

  return {
    onUpdateCoach,
    ...restMutation
  };
}

export function useEnabledOrDisabledCoach() {
  const [onFunction, restMutation] = useMutation(CHANGE_STATUS_COACH_QUERY, {
    update(cache, { data }) {
      const { updateCoach } = data as UpdateCoachMutation;
      if (!updateCoach) return;

      cache.modify({
        fields: {
          coaches(coaches = []) {
            const existingCoaches = coaches as CoachFragmentFragment[];

            const findCoach = existingCoaches?.find?.(
              (item) => item.id === updateCoach?.id
            );
            if (findCoach && existingCoaches) {
              return existingCoaches.map((item) => {
                if (item.id === updateCoach.id) {
                  return {
                    ...item,
                    status: updateCoach.status
                  };
                }
                return item;
              });
            }
            return existingCoaches || [];
          }
        }
      });
    },
    onCompleted({ updateCoach }) {
      if (updateCoach) {
        toast('Success', {
          description: "Coach's status has been changed"
        });
      } else {
        toast('Failed', {
          description: "Coach's status has been changed"
        });
      }
    }
  });

  const onChangeStatusCoach = async (coachID: string, status: string) => {
    try {
      return await onFunction({
        variables: {
          coachID: coachID,
          data: {
            status
          }
        }
      });
    } catch (e) {
      console.log(e);
      toast.error((e as Error)?.message || 'Failed to update coach');
    }
  };

  return { onChangeStatusCoach, ...restMutation };
}
