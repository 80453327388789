import { RouteObject } from 'react-router/dist/lib/context';
import Profile from '@/app/app/profile/page.tsx';
import { PrivateRotes } from '@/routes/private-rotes.ts';

const routes: RouteObject[] = [
  {
    path: PrivateRotes.PROFILE,
    Component: Profile
  }
];

export default routes;
