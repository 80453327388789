import { Checkbox } from '@nextui-org/react';
import { FormItem } from 'reactivity-hook-form';

import { useTranslation } from '@/hooks/useTranslation';
import { NewSessionFormType } from '@/app/app/sessions/common/types.ts';
import { useCreateOrEditSession } from '@/app/app/sessions/components/create-edit-session/context';

export function EnabledRepeatField() {
  const { t } = useTranslation();
  const { session } = useCreateOrEditSession();

  return (
    <FormItem<NewSessionFormType>
      name="isRepeatEvent"
      sanitize={({ field }) => ({
        isSelected: field.value
      })}
    >
      <Checkbox
        isDisabled={!!session?.rootEvent}
        classNames={{
          label: 'text-sm'
        }}
      >
        {t('form.enabled_repeat')}
      </Checkbox>
    </FormItem>
  );
}
