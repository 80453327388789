import useToggle from '@/hooks/useToggle.ts';
import { cn } from '@/lib/utils.ts';
import { CreateNotificationsInput } from '@/__generated__/graphql.ts';

type PreviewNotificationProps = Pick<
  CreateNotificationsInput,
  'body' | 'title' | 'imageUrl'
> & {
  icon: string;
};

export default function AndroidPreview({
  icon,
  title,
  imageUrl,
  body
}: PreviewNotificationProps) {
  const [open, toggleOpen] = useToggle(false);

  return (
    <div className="block" style={{ boxSizing: 'border-box' }}>
      <div
        className="block h-[400px] w-full relative"
        style={{
          backgroundSize: 'cover',
          backgroundPosition: 'top center',
          backgroundRepeat: 'no-repeat no-repeat',
          backgroundImage: 'url(/devices/google.svg)'
        }}
      >
        <div
          className={cn(
            'block w-full px-4 cursor-pointer overflow-hidden pt-[160px]'
          )}
        >
          <div
            onClick={toggleOpen.onToggle}
            className="bg-white overflow-y-hidden"
            style={{
              borderRadius: 14,
              boxShadow: 'rgb(220, 220, 220) 0px 0px 8px 0px'
            }}
          >
            <div
              className={cn('grid', {
                'grid-cols-[20px_auto_20px_!important]': open
              })}
              style={{
                display: 'grid',
                gridTemplateRows: open ? 'auto' : 'auto auto auto',
                gridTemplate: open
                  ? '"icon head collapse" "blank title title" "blank message message" "blank image image" "blank actions actions"'
                  : '"icon head collapse" / 20px auto 20px',
                gap: open ? '6px 5px' : '6px 5px',
                padding: '10px 8px'
              }}
            >
              <div
                style={{
                  gridArea: 'icon',
                  display: 'flex',
                  justifyContent: 'flex-start',
                  alignItems: 'center'
                }}
              >
                <div
                  style={{
                    width: 15,
                    height: 15,
                    padding: 1,
                    borderRadius: 9,
                    objectFit: 'contain',
                    backgroundColor: 'rgb(0, 0, 0)',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center'
                  }}
                >
                  <img
                    src={icon}
                    style={{
                      width: '100%',
                      height: '100%',
                      borderRadius: 'inherit'
                    }}
                  />
                </div>
              </div>

              <header
                style={{
                  gridArea: 'head',
                  display: 'grid',
                  gap: '2px 4px',
                  gridTemplate: open
                    ? 'auto / auto'
                    : '"title image" "message image" / auto 27px'
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    flexFlow: 'row',
                    alignItems: 'center',
                    columnGap: 1
                  }}
                >
                  {!open && (
                    <h1
                      style={{
                        gridArea: 'title',
                        color: 'rgb(27, 27, 27)',
                        fontSize: 10,
                        lineHeight: '12px',
                        fontWeight: 600,
                        letterSpacing: 0.1,
                        margin: 0,
                        display: '-webkit-box',
                        WebkitBoxOrient: 'vertical',
                        WebkitLineClamp: 1,
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'pre-wrap',
                        maxWidth: '100%',
                        wordBreak: 'break-word'
                      }}
                    >
                      {title || '-'}{' '}
                    </h1>
                  )}
                  <span
                    style={{
                      minWidth: 'max-content',
                      whiteSpace: 'nowrap',
                      color: 'rgb(27, 27, 27)',
                      fontSize: 9,
                      lineHeight: '12px',
                      letterSpacing: 0.1,
                      margin: 0
                    }}
                  >
                    {!open && ' • '}Jogo • now
                  </span>
                </div>

                {!open && (
                  <div
                    style={{
                      gridArea: 'message',
                      color: 'rgb(69, 69, 69)',
                      fontSize: 9,
                      lineHeight: '10px',
                      fontWeight: 400,
                      letterSpacing: 0.1,
                      marginTop: open ? 5 : 0,
                      display: '-webkit-box',
                      WebkitBoxOrient: 'vertical',
                      WebkitLineClamp: 1,
                      overflow: 'hidden',
                      width: '100%',
                      textOverflow: 'ellipsis',
                      whiteSpace: 'pre-wrap',
                      wordBreak: 'break-word'
                    }}
                  >
                    {body}
                  </div>
                )}

                {!!imageUrl && !open && (
                  <img
                    height="27"
                    width="27"
                    src={imageUrl}
                    style={{
                      gridArea: 'image',
                      width: 27,
                      minWidth: 27,
                      maxWidth: 27,
                      height: 27,
                      minHeight: 27,
                      maxHeight: 27,
                      backgroundColor: 'rgb(255, 255, 255)',
                      borderRadius: 6,
                      objectFit: 'cover'
                    }}
                  />
                )}
              </header>

              <div
                style={{
                  gridArea: 'collapse',
                  display: 'flex',
                  WebkitBoxPack: 'center',
                  justifyContent: 'center',
                  WebkitBoxAlign: 'center',
                  alignItems: 'center'
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    WebkitBoxPack: 'center',
                    justifyContent: 'center',
                    alignItems: 'center',
                    backgroundImage:
                      "url(\"data:image/svg+xml,%3c%3fxml version='1.0' encoding='UTF-8'%3f%3e%3c!DOCTYPE svg PUBLIC '-//W3C//DTD SVG 1.1//EN' 'http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd'%3e%3csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' version='1.1' width='24' height='24' viewBox='0 0 24 24'%3e%3cpath fill='%231A1C19' d='M7.41%2c8.58L12%2c13.17L16.59%2c8.58L18%2c10L12%2c16L6%2c10L7.41%2c8.58Z' /%3e%3c/svg%3e\")",
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'cover',
                    backgroundPosition: '100% 100%',
                    backgroundColor: 'rgb(239, 239, 239)',
                    width: 16,
                    height: 16,
                    borderRadius: 12
                  }}
                />
              </div>

              {open && (
                <h1
                  style={{
                    color: 'rgb(27, 27, 27)',
                    fontSize: 10,
                    margin: 0,
                    lineHeight: '12px',
                    fontWeight: 600,
                    letterSpacing: 0.1,
                    display: '-webkit-box',
                    whiteSpace: 'pre-wrap',
                    maxWidth: '100%',
                    wordBreak: 'break-word',
                    gridArea: 'title'
                  }}
                >
                  {title || '-'}{' '}
                </h1>
              )}

              {open && (
                <div
                  style={{
                    gridArea: 'message',
                    color: 'rgb(69, 69, 69)',
                    fontSize: 9,
                    lineHeight: '10px',
                    fontWeight: 400,
                    letterSpacing: 0.1,
                    margin: 0,
                    width: '100%',
                    whiteSpace: 'pre-wrap',
                    wordBreak: 'break-word'
                  }}
                >
                  {body}
                </div>
              )}

              {open && imageUrl && (
                <img
                  className="block w-full"
                  width="100%"
                  src={imageUrl}
                  style={{
                    gridArea: 'image',
                    display: 'flex',
                    width: '100%',
                    maxHeight: 160,
                    objectFit: 'cover',
                    backgroundColor: 'rgb(255, 255, 255)',
                    WebkitBoxAlign: 'center',
                    alignItems: 'center',
                    WebkitBoxPack: 'center',
                    justifyContent: 'center',
                    borderRadius: 16
                  }}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
