import { gql } from '@/__generated__';

export const POST_UPDATE_CLASS = gql(`
  mutation PostUpdateClass($id: ID!, $data: ClassOfferedUpdateInput!) {
     updateClassOffered(where: { id: $id }, data: $data) {
        ...ClassOfferedFragment
      }
  }
`);

export const POST_CREATE_PLAN = gql(`
  mutation PostCreatePlan($spaceId: ID!, $name: String!, $points: Int!, $price: Decimal!, $duration: Int!, $categories: SessionCategoryRelateToManyForCreateInput) {
     createPlan(data: {
        space: { connect: { id: $spaceId } },
        name: $name,
        points: $points,
        price: $price,
        duration: $duration,
        categories: $categories
     }) {
        ...PlanFragment
      }
  }
`);

export const POST_UPDATE_PLAN = gql(`
  mutation PostUpdatePlan($id: ID!, $data: PlanUpdateInput!) {
     updatePlan(where: { id: $id }, data: $data) {
        ...PlanFragment
      }
  }
`);

export const POST_CREATE_SESSION_CATEGORY = gql(`
  mutation PostCreateSessionCategory($spaceId: ID!, $name: String!) {
     createSessionCategory(data: { space: { connect: { id: $spaceId } }, name: $name }) {
        id
        name
        status
        plansCount
      }
  }
`);

export const POST_UPDATE_SESSION_CATEGORY = gql(`
  mutation PostUpdateSessionCategory($id: ID!, $data: SessionCategoryUpdateInput!) {
     updateSessionCategory(where: { id: $id }, data: $data) {
        id
        name
        status
        plansCount
      }
  }
`);

export const POST_DELETE_SESSION_CATEGORY = gql(`
  mutation PostDeleteSessionCategory($id: ID!) {
     deleteSessionCategory(where: { id: $id }) {
        id
      }
  }
`);

export const POST_CREATE_BADGE = gql(`
  mutation PostCreateBadge($data: BadgeCreateInput!) {
     createBadge(data: $data) {
        ...BadgeFragment
      }
  }
`);

export const POST_UPDATE_BADGE = gql(`
  mutation PostUpdateBadge($badgeId: ID!,$data: BadgeUpdateInput!) {
     updateBadge(where: { id: $badgeId }, data: $data) {
        ...BadgeFragment
      }
  }
`);

export const POST_DELETE_BADGE = gql(`
  mutation PostDeleteBadge($badgeId: ID!) {
     deleteBadge(where: { id: $badgeId }) {
        ...BadgeFragment
      }
  }
`);

export const POST_CREATE_BADGE_ITEM = gql(`
  mutation PostCreateBadgeItem($data: BadgeItemCreateInput!) {
     createBadgeItem(data: $data) {
        ...BadgeItemFragment
      }
  }
`);

export const POST_UPDATE_BADGE_ITEM = gql(`
  mutation PostUpdateBadgeItem($badgeId: ID!,$data: BadgeItemUpdateInput!) {
     updateBadgeItem(where: { id: $badgeId }, data: $data) {
        ...BadgeItemFragment
      }
  }
`);

export const POST_DELETE_BADGE_ITEM = gql(`
  mutation PostDeleteBadgeItem($badgeId: ID!) {
     deleteBadgeItem(where: { id: $badgeId }) {
        ...BadgeItemFragment
      }
  }
`);

export const NEW_USER_BACKOFFICE = gql(`
  mutation CreateNewUserBackoffice($data: UserBackofficeCreateInput!) {
    createUserBackoffice(data: $data) {
      ...UserBackofficeFragment
    }
  }
`);

export const UPDATE_USER_BASE = gql(`
  mutation UpdateUserData($id: ID!, $data: UserUpdateInput!) {
    updateUser(where: { id: $id }, data: $data) {
      ...UserFragment
    }
  }
`);

export const UPDATE_USER_BACKOFFICE = gql(`
  mutation UpdateNewUserBackoffice($id: ID!, $data: UserBackofficeUpdateInput!) {
    updateUserBackoffice(where: { id: $id }, data: $data) {
      ...UserBackofficeFragment
    }
  }
`);

export const VERIFY_STRIPE_ACCOUNT = gql(`
  mutation VerifyStripeAccount($accountId: String!) {
    verifyStripeAccount(accountId: $accountId) {
      charges_enabled
      details_submitted
    }
  }
`);

export const CREATE_STRIPE_ACCOUNT = gql(`
  mutation CreateStripeAccount($spaceId: ID!) {
    createStripeAccount(spaceId: $spaceId) {
      accountLink
    }
  }
`);

export const LOGIN_STRIPE_ACCOUNT = gql(`
  mutation LoginStripeLinkAccount($spaceId: ID!) {
    loginStripeAccount(spaceId: $spaceId) {
      client_secret
    }
  }
`);

export const LINK_STRIPE_ACCOUNT = gql(`
  mutation LinkStripeLinkAccount($accountId: String!) {
    linkStripeAccount(accountId: $accountId) {
      accountLink
    }
  }
`);

export const STRIPE_ACCOUNT_COMPLETED = gql(`
  mutation UpdatePaymentsProvider($paymentProviderId: ID!) {
    updatePaymentsProvider(
      where: {
        id: $paymentProviderId
      }
      data: {
        status: "completed"
      }
    ) {
      id
    }
  }
`);
