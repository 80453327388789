import { createContext, PropsWithChildren, useContext } from 'react';
import { SessionFragmentFragment } from '@/__generated__/graphql.ts';
import useSelectEditItem from '@/hooks/useSelectEditItem.ts';
import useToggle from '@/hooks/useToggle.ts';
import NewSession from '@/app/app/sessions/components/create-edit-session';

type CreateOrEditSessionContextType = {
  onClose: () => void;
  open: boolean;
  onOpen: () => void;
  setSession: (s?: DeepNonNullable<SessionFragmentFragment>) => void;
  session?: DeepNonNullable<SessionFragmentFragment> | null;
};

const CreateOrEditSessionContext =
  createContext<CreateOrEditSessionContextType>({
    open: false,
    onClose: () => {},
    onOpen: () => {},
    setSession: () => {},
    session: null
  });

export function CreateOrEditSessionProvider({ children }: PropsWithChildren) {
  const [isOpen, isOpenActions] = useToggle();
  const [session, setSession] =
    useSelectEditItem<DeepNonNullable<SessionFragmentFragment>>(isOpenActions);

  const onClose = () => {
    setSession(null, false);
  };

  const onOpen = () => {
    setSession(null, true);
  };

  const onSetSessionToggle = (
    item?: DeepNonNullable<SessionFragmentFragment>
  ) => {
    setSession(item || null, !!item);
  };

  return (
    <CreateOrEditSessionContext.Provider
      value={{
        session,
        onOpen,
        onClose,
        open: isOpen,
        setSession: onSetSessionToggle
      }}
    >
      {children}

      <NewSession
        key={session ? `edit-${session?.id}` : 'new'}
        session={session}
        open={isOpen}
        onHidden={onClose}
      />
    </CreateOrEditSessionContext.Provider>
  );
}

export function useCreateOrEditSession() {
  const context = useContext(CreateOrEditSessionContext);
  if (!context) {
    console.log('useCreateOrEditSession need CreateOrEditSessionProvider');
    return context as unknown as CreateOrEditSessionContextType;
  }
  return context;
}
