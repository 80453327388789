import { Card, CardBody, CardHeader } from '@nextui-org/react';
import { cn, formatMoney } from '@/lib/utils.ts';
import Text from '@/lib/ui/text.tsx';
import dayjs from 'dayjs';
import PieChart from '@/lib/ui/charts/pie-chart.tsx';
import { useGetPaymentMethod } from '@/app/app/dashboard/store/query/dashboard.query.ts';
import { useTranslation } from '@/hooks/useTranslation.ts';
import Title from '@/lib/ui/title.tsx';

export function PaymentMethodGraph({ className }: { className?: string }) {
  const { t } = useTranslation();
  const { options, loading } = useGetPaymentMethod();

  return (
    <Card className={cn('relative block', className)}>
      <CardBody>
        <CardHeader className="flex-col items-start">
          <div className="flex gap-1 justify-between items-end">
            <Title size="lg" className="mb-0">
              {t('income')}
            </Title>
            <Text className="text-sm mb-1">{dayjs().format('MMM, YYYY')}</Text>
          </div>
          <Text className="mb-4">{t('activity.sub_title')}</Text>
        </CardHeader>

        <PieChart
          formatValue={formatMoney}
          chartWrapperClass="max-w-[300px]"
          loading={loading}
          data={options}
        />
      </CardBody>
    </Card>
  );
}
