'use client';

import { useTranslation } from '@/hooks/useTranslation';

export default function DesignLeft() {
  const { t } = useTranslation();

  return (
    <div className={`md:w-[50%] md:h-full`}>
      <img width={120} src="/icon.png" className="mb-10 block md:hidden" />

      <div className="bg-gradient-brand md:pr-5 p-5 relative md:h-full flex flex-col justify-center items-center">
        <div className="absolute h-full w-full flex items-end justify-center opacity-10 pb-10 overflow-hidden">
          <img src="/images/login.svg" className="w-[500px] max-w-full" />
        </div>

        <div className="flex flex-col gap-8">
          <div className="flex flex-col gap-2">
            <h1
              className="text-2xl leading-10 text-white"
              style={{ letterSpacing: 8 }}
            >
              {t('welcome')}
            </h1>
            <h1 className="text-5xl leading-10 text-white font-bold">
              {t('management_space')}
            </h1>
          </div>

          <div className="h-[2px] w-32 bg-white" />

          <p className="text-white">{t('management_space_sub')}</p>
        </div>
      </div>
    </div>
  );
}
