import ClientList from '@/app/app/clients/page.tsx';
import ClientDetails from '@/app/app/clients/[id].tsx';
import { RouteObject } from 'react-router/dist/lib/context';
import { PrivateRotes } from '@/routes/private-rotes.ts';

const routes: RouteObject[] = [
  {
    path: PrivateRotes.CLIENTS,
    Component: ClientList
  },
  {
    path: PrivateRotes.CLIENTS_DETAILS,
    Component: ClientDetails
  }
];

export default routes;
