import React from 'react';

export const MEDIA_QUERY = {
  small: 'only screen and (max-width : 768px)',
  medium: 'only screen and (max-width : 1024px)',
  large: 'only screen and (min-width : 1025px)'
};

export default function useMediaQuery(query: string) {
  const subscribe = React.useCallback(
    (callback: () => void) => {
      const matchMedia = window.matchMedia(query);

      matchMedia.addEventListener('change', callback);
      return () => {
        matchMedia.removeEventListener('change', callback);
      };
    },
    [query]
  );

  const getSnapshot = () => {
    return window.matchMedia(query).matches;
  };

  const getServerSnapshot = () => {
    throw Error('useMediaQuery is a client-only hook');
  };

  return React.useSyncExternalStore(subscribe, getSnapshot, getServerSnapshot);
}
