import { useTranslation } from '@/hooks/useTranslation.ts';

const getColorStatus = (status: string | null | undefined) => {
  const statusColor = {
    open: 'primary',
    finished: 'success',
    in_progress: 'warning',
    canceled: 'error'
  };

  return (
    (statusColor?.[status as keyof typeof statusColor] as 'default') ||
    'default'
  );
};

export default function useSessionStatus() {
  const { t } = useTranslation();

  return {
    get(status: string | null | undefined) {
      return {
        label: t(`class_status.${status}` as LanguageKeys),
        color: getColorStatus(status)
      };
    }
  };
}
