import { cn } from '@/lib/utils';
import Text from '@/lib/ui/text.tsx';

const backgroundColors: Record<ColorStatus, string> = {
  default: 'bg-default-100 dark:bg-default-950',
  success: 'bg-green-100 dark:bg-green-950',
  danger: 'bg-red-100 dark:bg-red-950',
  primary: 'bg-blue-100 dark:bg-blue-950',
  warning: 'bg-yellow-100 dark:bg-yellow-950',
  secondary: 'bg-gray-100 dark:bg-gray-950'
};

const colors: Record<ColorStatus, string> = {
  default: 'text-default-800 dark:text-default-100',
  success: 'text-green-800 dark:text-green-100',
  danger: 'text-red-800 dark:text-red-100',
  primary: 'text-blue-800 dark:text-blue-100',
  warning: 'text-yellow-800 dark:text-yellow-100',
  secondary: 'text-gray-800 dark:text-gray-100'
};

export type StatusColorProps = {
  label: string;
  severity: ColorStatus;
  className?: string;
};

export default function StatusColor({
  label,
  severity = 'default',
  className = ''
}: StatusColorProps) {
  const bgColor = backgroundColors[severity || 'default'];
  const color = colors[severity || 'default'];

  return (
    <Text
      className={cn(
        `px-2 w-fit rounded-lg ${bgColor} ${color}`,
        className
      )}
    >
      {label}
    </Text>
  );
}
