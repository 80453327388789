import dayjs from 'dayjs';
import { useMemo } from 'react';

import Loading from '@/lib/ui/loading';
import { useTranslation } from '@/hooks/useTranslation';
import Board from '@/app/app/sessions/components/board';
import { ScheduleProps } from '@/app/app/sessions/components/sessions-list/types';
import HeaderSchedule from '@/app/app/sessions/components/sessions-list/header-schedule';
import { useCalendar } from '@/context/calendar-provider.tsx';

export default function WeekSchedule({ loading, events }: ScheduleProps) {
  const calendarProps = useCalendar();
  const { lang } = useTranslation();

  const weekDays = useMemo(() => {
    const dayjsLocal = dayjs(calendarProps?.date).locale(lang);
    const firstDayWeek = dayjsLocal.startOf('week');
    return Array.from(new Array(7).keys()).map((i) => {
      return firstDayWeek.add(i, 'day');
    });
  }, [lang, calendarProps?.date]);

  return (
    <div className="relative">
      {loading && (
        <div className="fixed md:absolute bottom-0 left-0 h-[calc(100%-80px)] w-full bg-[rgba(255,255,255,0.38)] dark:bg-[rgba(18,18,18,0.38)] z-[10000] grid place-content-center">
          <Loading />
        </div>
      )}
      <HeaderSchedule />
      <Board weekDays={weekDays} events={events}/>
    </div>
  );
}
