import { useNavigate } from 'react-router-dom';
import { useTranslation } from '@/hooks/useTranslation.ts';
import HeaderPage from '@/app/app/shared/components/HeaderPage.tsx';
import AppContainer from '@/app/app/shared/components/AppContainer.tsx';
import { useGetNotificationList } from '@/app/app/notifications/store/query/notification.query.ts';
import NotificationList from '@/app/app/notifications/components/notification-list';
import usePagination from '@/lib/hooks/usePagination.ts';
import { PrivateRotes } from '@/routes/private-rotes.ts';

export default function NotificationsPage() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const pagination = usePagination();
  const { data, isLoading } = useGetNotificationList({
    pagination: pagination.serverQueryPagination
  });

  return (
    <AppContainer>
      <HeaderPage
        title={t('nav.notifications')}
        primaryAction={{
          text: t('notifications.new'),
          onClick: () => navigate(PrivateRotes.NEW_NOTIFICATIONS)
        }}
      />

      <NotificationList
        data={data}
        isLoading={isLoading}
        pagination={pagination.frontPagination}
      />
    </AppContainer>
  );
}
