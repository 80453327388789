import { PublicRotes } from '@/routes/public-rotes.ts';
import { createUrl } from '@/routes/utils.ts';

export const AUTH_ROUTES = {
  LOGIN: createUrl('login', PublicRotes.AUTH),
  COMPLETE_PROFILE: createUrl('complete-profile', PublicRotes.AUTH),
  RESET_PASSWORD: createUrl('reset-password', PublicRotes.AUTH),
  CREATE_SPACE: createUrl('create-space', PublicRotes.AUTH),
  SIGNUP: createUrl(`signup`, PublicRotes.AUTH),
  SELECT_SPACE: createUrl(`select-space`, PublicRotes.AUTH),
  LOGOUT: createUrl(`logout`, PublicRotes.AUTH),
} as const;
