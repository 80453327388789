import { FilterTimeEnum } from '@/__generated__/graphql.ts';

export const FILTER_TIME_OPTIONS: {
  label: LanguageKeys;
  value: FilterTimeEnum;
}[] = [
  {
    label: 'sessions.week',
    value: FilterTimeEnum.Week
  },
  {
    label: 'sessions.month',
    value: FilterTimeEnum.Month
  }
];
