import Form, { FormItem } from 'reactivity-hook-form';
import Input from '@/lib/ui/input.tsx';
import ColorPicker from '@/lib/ui/color-picker.tsx';
import {
  NewBadgeItemPayload,
  useCreateBadgeItem,
  useUpdateBadgeItem
} from '@/app/app/settings/store/mutation/badge.mutation.ts';
import Modal, { ModalActions } from '@/lib/ui/modal.tsx';
import { BadgeItemFragmentFragment } from '@/__generated__/graphql.ts';
import { useTranslation } from '@/hooks/useTranslation.ts';

const FORM_NAME = 'new-badge-item-form';

export default function NewBadgeItemModal({
  open,
  item,
  badgeId,
  onClose
}: {
  item?: BadgeItemFragmentFragment | null;
  badgeId: string;
  open: boolean;
  onClose: () => void;
}) {
  const { t } = useTranslation();
  const { onCreateBadgeItem, loading: loadingNew } = useCreateBadgeItem();
  const { onUpdateBadgeItem, loading: loadingEdit } = useUpdateBadgeItem();

  const loading = loadingEdit || loadingNew;

  const onSubmit = async (values: NewBadgeItemPayload) => {
    if (item) {
      await onUpdateBadgeItem({
        badgeId: item.id,
        data: values
      });
    } else {
      await onCreateBadgeItem({
        ...values,
        badge: {
          connect: { id: badgeId }
        }
      });
    }
    onClose();
  };

  return (
    <Modal
      isOpen={open}
      onClose={onClose}
      maskClosable={false}
      title={item ? t('settings.edit_badge') : t('settings.new_badge')}
    >
      <Form<NewBadgeItemPayload>
        id={FORM_NAME}
        onSubmit={onSubmit}
        defaultValues={{
          name: item?.name ?? '',
          color: item?.color ?? '',
          icon: item?.icon ?? ''
        }}
        validations={{
          name: {
            required: t('validations.required')
          },
          color: {
            required: t('validations.required')
          }
        }}
      >
        <FormItem name="name">
          <Input label={t('name')} />
        </FormItem>
        <FormItem name="color">
          <ColorPicker label={t('color')} />
        </FormItem>
        <FormItem name="icon">
          <Input label={`Emoji(${t('optional')})`} />
        </FormItem>
      </Form>

      <ModalActions
        isLoading={loading}
        formName={FORM_NAME}
        onCancel={onClose}
        variant={item ? 'edit' : 'save'}
      />
    </Modal>
  );
}
