import Form, { FormItem } from 'reactivity-hook-form';

import Input from '@/lib/ui/input';
import { useTranslation } from '@/hooks/useTranslation';
import {
  useNewCoach,
  useUpdateCoach
} from '@/app/app/coaches/store/mutation/coaches.mutation';
import GridColumn from '@/lib/ui/grid-column.tsx';
import Modal from '@/lib/ui/modal.tsx';
import { CoachFragmentFragment } from '@/__generated__/graphql.ts';
import Upload from '@/lib/ui/upload.tsx';

const newCoachFormId = 'new-coach';

type CoachFormType = {
  firstName: string;
  lastName: string;
  email: string;
  file?: File | string | null;
};

export default function NewOrEditCoach({
  onClose,
  isOpen,
  editRecord
}: {
  isOpen?: boolean;
  onClose?: () => void;
  editRecord?: CoachFragmentFragment | null;
}) {
  const { t } = useTranslation();
  const { onCreateCoach, loading } = useNewCoach();
  const { onUpdateCoach, loading: loadingEdit } = useUpdateCoach();

  const fullLoading = loading || loadingEdit;

  const onSubmit = async (values: CoachFormType) => {
    let file: File | undefined = undefined;

    if (typeof values?.file === 'string') {
      file = undefined;
    } else if (values?.file instanceof File) {
      file = values?.file;
    }

    delete values.file;
    const payload = {
      ...values,
      avatar: file
        ? {
            upload: file
          }
        : undefined
    };

    if (editRecord) {
      await onUpdateCoach({
        coachID: editRecord?.id,
        data: payload
      });
    } else {
      await onCreateCoach(payload);
    }

    onClose?.();
  };

  return (
    <Modal
      size="2xl"
      isOpen={isOpen}
      onClose={onClose}
      maskClosable={false}
      title={t(editRecord ? 'coach.edit_coach' : 'coach.new')}
      actions={[
        {
          label: t('close'),
          color: 'danger',
          variant: 'light',
          onClick: onClose,
          disabled: fullLoading
        },
        {
          label: t(editRecord ? 'edit' : 'save'),
          color: 'primary',
          type: 'submit',
          form: newCoachFormId,
          isLoading: fullLoading
        }
      ]}
    >
      <Form<CoachFormType>
        id={newCoachFormId}
        defaultValues={{
          firstName: editRecord?.firstName ?? '',
          email: editRecord?.email ?? '',
          lastName: editRecord?.lastName ?? '',
          file: editRecord?.avatar?.url ?? null
        }}
        validations={{
          firstName: {
            required: `${t('form.first_name')} ${t('validations.is_required')}`
          },
          lastName: {
            required: `${t('form.last_name')} ${t('validations.is_required')}`
          },
          email: {
            required: `${t('form.email')} ${t('validations.is_required')}`,
            pattern: {
              value: /\S+@\S+\.\S+/,
              message: `${t('form.email')} ${t('validations.is_invalid')}`
            }
          }
        }}
        onSubmit={onSubmit}
      >
        <div className="flex justify-center items-center">
          <FormItem name="file">
            <Upload label={t('form.avatar')} />
          </FormItem>
        </div>

        <GridColumn spacing={4} xs={12} md={2}>
          <FormItem name="firstName">
            <Input
              label={t('form.first_name')}
              placeholder={t('form_placeholder.first_name')}
            />
          </FormItem>

          <FormItem name="lastName">
            <Input
              label={t('form.last_name')}
              placeholder={t('form_placeholder.last_name')}
            />
          </FormItem>
        </GridColumn>

        <GridColumn spacing={4} xs={12} md={2}>
          <FormItem name="email">
            <Input
              label={t('form.email')}
              placeholder={t('form_placeholder.email')}
            />
          </FormItem>
        </GridColumn>
      </Form>
    </Modal>
  );
}
