import { LoadingLogin } from './_loading.tsx';
import Text from '@/lib/ui/text.tsx';
import CompleteForm from '@/app/auth/_components/complete-form';
import { useNavigate, useSearchParams } from 'react-router-dom';
import EmptyState from '@/lib/ui/empty-state.tsx';
import { Button } from '@nextui-org/react';
import { useVerifyToken } from '@/app/auth/_store/mutation/auth.query.ts';
import { VerifyTokenQueryQuery } from '@/__generated__/graphql.ts';
import { AUTH_ROUTES } from '@/app/auth/_common/routes.ts';
import { useAuth } from '@/app/auth/_context';
import { useEffect } from 'react';
import { useTranslation } from '@/hooks/useTranslation.ts';

export default function CompleteProfilePage() {
  const [search] = useSearchParams();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { logout } = useAuth();

  useEffect(() => {
    logout();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const token = search.get('token');

  const { data, loading } = useVerifyToken(token);

  return (
    <main className="w-full flex h-screen p-5 flex-col items-center">
      <LoadingLogin loading={loading} />

      {/* Col 2 */}
      <div className="md:w-[50%] md:h-full flex justify-center items-center py-10">
        <div className="text-left w-full lg:max-w-[400px]">
          {!token || !data ? (
            <EmptyState
              title="Enlace no valido"
              footer={
                <Button
                  color="primary"
                  onClick={() => navigate(AUTH_ROUTES.LOGIN)}
                >
                  {t('go_to_home')}
                </Button>
              }
            />
          ) : (
            <>
              <img
                src="/icon.png"
                width={120}
                className="mb-10 hidden md:block"
              />

              <Text className="mb-5">
                {t('complete_profile_description')}
                <strong>{data?.verifyToken?.space}</strong>
              </Text>

              <CompleteForm
                token={token}
                defaultValues={
                  data?.verifyToken as DeepNonNullable<VerifyTokenQueryQuery>['verifyToken']
                }
              />
            </>
          )}
        </div>
      </div>
      {/* EndCol 2 */}
    </main>
  );
}
