import { gql } from '@/__generated__/gql.ts';

export const VERIFY_TOKEN_QUERY = gql(`
  query VerifyTokenQuery($token: String!) {
    verifyToken(token: $token) {
      id
      email
      firstName
      lastName
      space
    }
  }
`);
