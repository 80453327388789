import { cn } from '@/lib/utils.ts';
import Text from '@/lib/ui/text.tsx';
import { useTranslation } from '@/hooks/useTranslation.ts';
import SessionCard from '@/app/app/sessions/components/session-card';
import { EventSchedule } from '@/app/app/sessions/components/sessions-list/types.ts';

export type ColumnProps = {
  name: string;
  isNow: boolean;
  events: EventSchedule[];
};

export default function Column({ name, isNow, events }: ColumnProps) {
  const { t } = useTranslation();

  return (
    <div
      className="w-full flex flex-row gap-3 md:min-w-fit md:flex-col"
      style={{
        gridTemplateRows: 'min-content 1fr'
      }}
    >
      <div
        className={cn(
          'p-2 bg-background-board rounded-lg flex items-center h-[150px] md:h-auto md:justify-center',
          isNow && 'bg-background-cell-calendar'
        )}
      >
        <Text className="capitalize text-center text-sm [writing-mode:vertical-lr] md:[writing-mode:initial] rotate-180 md:rotate-0 font-bold">
          {name}
        </Text>
      </div>
      <div
        className={cn(
          'bg-background-board rounded-lg flex p-3 gap-3 w-full md:flex-col',
          isNow && 'bg-background-cell-calendar'
        )}
      >
        {!events?.length ? (
          <div className="p-4 py-7 rounded-lg flex justify-center items-center w-full gap-2">
            <Text className="text-center text-sm text-gray-600">
              {t('sessions.empty_tasks')}
            </Text>
          </div>
        ) : (
          events.map((event, index) => {
            return <SessionCard {...event} key={event.id ?? index} />;
          })
        )}
      </div>
    </div>
  );
}
