import 'reactivity-hook-form/lib/esm/style.css';
import { Outlet } from 'react-router-dom';

import ExtendLayout from '@/app/layout.tsx';
import PrivateRouter from '@/app/app/shared/private_router';
import Navbar from '@/app/app/shared/components/navbar';

export default function AppLayout() {
  return (
    <ExtendLayout>
      <PrivateRouter>
        <Navbar />
        <div id="body" className="w-full flex flex-1 pt-[65px]">
          {/*<Aside />*/}

          {/* md:pl-[350px] */}
          <div id="content" className="w-full">
            <Outlet />
          </div>
        </div>
      </PrivateRouter>
    </ExtendLayout>
  );
}
