import { Button } from '@nextui-org/react';
import Form, { FormItem } from 'reactivity-hook-form';

import { useAuth } from '@/app/auth/_context';
import Upload from '@/lib/ui/upload.tsx';
import GridColumn from '@/lib/ui/grid-column.tsx';
import Input from '@/lib/ui/input.tsx';
import { Textarea } from '@/lib/ui/textarea.tsx';
import CountryCodeSelect from '@/lib/ui/country-code-select.tsx';
import { SpaceUpdateInput } from '@/__generated__/graphql.ts';
import TabContainer from '@/app/app/settings/components/TabContainer';
import Amenities from '@/app/app/settings/components/Amenities';
import { useTranslation } from '@/hooks/useTranslation.ts';
import Title from '@/lib/ui/title.tsx';

type GeneralForm = Pick<
  SpaceUpdateInput,
  | 'address'
  | 'name'
  | 'website'
  | 'amenities'
  | 'email'
  | 'phoneCountryCode'
  | 'phoneNumber'
  | 'summary'
  | 'paymentLink'
> & {
  gallery1: File | string | null;
  gallery2: File | string | null;
  gallery3: File | string | null;
  thumbnail: File | string | null;
};

export default function GeneralTab() {
  const { t } = useTranslation();
  const { activeSpace, onEditSpace, loadingUpdateSpace } = useAuth();
  const space = activeSpace?.space;

  const onSubmit = (_payload: GeneralForm) => {
    const thumbnail =
      _payload.thumbnail instanceof File
        ? { upload: _payload.thumbnail }
        : undefined;
    const gallery1 =
      _payload.gallery1 instanceof File
        ? { upload: _payload.gallery1 }
        : undefined;
    const gallery2 =
      _payload.gallery2 instanceof File
        ? { upload: _payload.gallery2 }
        : undefined;
    const gallery3 =
      _payload.gallery3 instanceof File
        ? { upload: _payload.gallery3 }
        : undefined;

    const payload: SpaceUpdateInput = {
      ..._payload,
      thumbnail,
      gallery1,
      gallery2,
      gallery3
    };

    return onEditSpace({
      data: payload,
      spaceId: space?.id ?? ''
    });
  };

  return (
    <TabContainer title="General">
      <Title size="xs">{t('settings.basic_information')}</Title>

      <Form<GeneralForm>
        onSubmit={onSubmit}
        defaultValues={{
          name: space?.name ?? '',
          summary: space?.summary ?? '',
          thumbnail: space?.thumbnail?.url ?? null,
          email: space?.email ?? '',
          address: space?.address ?? '',
          phoneNumber: space?.phoneNumber ?? '',
          paymentLink: space?.paymentLink ?? '',
          phoneCountryCode: space?.phoneCountryCode ?? '',
          website: space?.website ?? '',
          amenities: space?.amenities ?? [],
          gallery1: space?.gallery1?.url ?? null,
          gallery2: space?.gallery2?.url ?? null,
          gallery3: space?.gallery3?.url ?? null
        }}
      >
        <div className="flex justify-center items-center">
          <FormItem<GeneralForm> name="thumbnail">
            <Upload className="w-36 h-36 lg:w-40 lg:h-40" />
          </FormItem>
        </div>

        <GridColumn spacing={4} xs={1} md={2}>
          <FormItem<GeneralForm> name="name">
            <Input label={t('form.name')} />
          </FormItem>
          <FormItem<GeneralForm> name="email">
            <Input label={t('form.email')} />
          </FormItem>
          <FormItem<GeneralForm> name="phoneCountryCode">
            <CountryCodeSelect label={t('form.phone_country')} />
          </FormItem>
          <FormItem<GeneralForm> name="phoneNumber">
            <Input label={t('form.phone_number')} />
          </FormItem>
        </GridColumn>

        <FormItem<GeneralForm> name="summary">
          <Textarea label={t('form.description')} />
        </FormItem>
        <FormItem<GeneralForm> name="address">
          <Textarea label={t('form.address')} />
        </FormItem>

        <FormItem<GeneralForm> name="amenities">
          <Amenities label={t('form.amenities')} />
        </FormItem>

        <GridColumn xs={1} md={2} spacing={4}>
          <FormItem<GeneralForm> name="website">
            <Input label="Website" />
          </FormItem>
          <FormItem<GeneralForm> name="paymentLink">
            <Input label={t('form.payment_link')} />
          </FormItem>
        </GridColumn>

        <Title size="xs" className="mt-7">{t('form.gallery')}</Title>
        <div className="flex flex-col lg:flex-row flex-wrap gap-2 justify-center">
          <FormItem<GeneralForm> name="gallery1">
            <Upload
              shape="square"
              className="w-full h-[200px] lg:w-[350px] lg:h-[200px] object-cover"
            />
          </FormItem>

          <FormItem<GeneralForm> name="gallery2">
            <Upload
              shape="square"
              className="w-full h-[200px] lg:w-[350px] lg:h-[200px]"
            />
          </FormItem>

          <FormItem<GeneralForm> name="gallery3">
            <Upload
              shape="square"
              className="w-full h-[200px] lg:w-[350px] lg:h-[200px]"
            />
          </FormItem>
        </div>

        <div className="mt-7 mb-5">
          <FormItem<GeneralForm>>
            {({ formState }) => {
              return (
                <Button
                  fullWidth
                  type="submit"
                  isLoading={loadingUpdateSpace}
                  disabled={Object.keys(formState?.dirtyFields).length == 0}
                  color={
                    Object.keys(formState?.dirtyFields).length == 0
                      ? 'default'
                      : 'primary'
                  }
                >
                  Save
                </Button>
              );
            }}
          </FormItem>
        </div>
      </Form>
    </TabContainer>
  );
}
