import { FormItem } from 'reactivity-hook-form';

import { useTranslation } from '@/hooks/useTranslation';
import { NewSessionFormType } from '@/app/app/sessions/common/types.ts';
import SelectCoach from '@/app/app/coaches/components/SelectCoach/SelectCoach.tsx';

export function CoachField() {
  const { t } = useTranslation();

  return (
    <FormItem<NewSessionFormType> name="coach">
      <SelectCoach label={t('form.coach')} />
    </FormItem>
  );
}
