import * as React from 'react';

const Text = React.forwardRef<
  HTMLParagraphElement,
  React.ButtonHTMLAttributes<HTMLParagraphElement>
>((props, ref) => {
  return <p {...props} ref={ref} />;
});

export default Text;
