import {
  NewOrEditCreditsPayload,
  TheLastCredit
} from '@/app/app/clients/components/CreditsCard/type.ts';
import { PaymentMethodEnum } from '@/app/app/clients/components/PaymentMethodSelect/PaymentMethodSelect.tsx';

export default function useDefaultValues(
  editRecord?: TheLastCredit | null,
  paymentPreference?: string | null
): NewOrEditCreditsPayload {
  if (editRecord) {
    const plan = editRecord.plan;

    return {
      openAt: editRecord.openAt,
      expiredAt: editRecord.expiredAt,
      lastCredit: '',
      extra: `${
        Number(editRecord?.creditBuy ?? 0) - Number(plan?.points ?? 0)
      }`,
      plan: plan?.id,
      paymentMethod: editRecord?.paymentMethod ?? paymentPreference ?? null,
      totalPrice: editRecord?.totalPrice?.toString?.() ?? '',
      selectedPlan: plan
    };
  }

  return {
    openAt: '',
    expiredAt: '',
    lastCredit: '',
    extra: '',
    plan: '',
    totalPrice: '',
    paymentMethod: paymentPreference || PaymentMethodEnum.STRIPE,
    selectedPlan: null
  };
}
