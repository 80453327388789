export enum StatusItem {
  confirmed = 'confirmed',
  pending = 'pending',
  canceled = 'cancelled'
}

type StatusColor = 'success' | 'warning' | 'danger';

export const STATUS_SUBSCRIPTION_OPTIONS: Record<
  StatusItem,
  {
    label: LanguageKeys;
    color: StatusColor;
  }
> = {
  confirmed: {
    label: 'subscription_status.confirmed',
    color: 'success'
  },
  pending: {
    label: 'subscription_status.pending',
    color: 'warning'
  },
  cancelled: {
    label: 'subscription_status.canceled',
    color: 'danger'
  }
};
