import useToggle from '@/hooks/useToggle.ts';
import { cn } from '@/lib/utils.ts';
import { CreateNotificationsInput } from '@/__generated__/graphql.ts';

type PreviewNotificationProps = Pick<
  CreateNotificationsInput,
  'body' | 'title' | 'imageUrl'
> & {
  icon: string;
};

export default function IosPreview({
  icon,
  title,
  imageUrl,
  body
}: PreviewNotificationProps) {
  const [open, toggleOpen] = useToggle(false);
  console.log({ body });
  return (
    <div className="block" style={{ boxSizing: 'border-box' }}>
      <div
        className="block h-[400px] w-full relative"
        style={{
          backgroundSize: 'cover',
          backgroundPosition: 'top center',
          backgroundRepeat: 'no-repeat no-repeat',
          backgroundImage: 'url(/devices/ios.svg)'
        }}
      >
        <div
          className={cn('block w-full px-5 cursor-pointer overflow-hidden', {
            'pt-[35px]': open && !!imageUrl,
            'pt-[150px]': !open || !imageUrl
          })}
        >
          <div
            onClick={toggleOpen.onToggle}
            className="rounded-xl bg-white overflow-y-hidden"
            style={{ boxShadow: 'rgb(220, 220, 220) 0px 0px 8px 0px' }}
          >
            <div
              className="grid"
              style={{
                display: 'grid',
                gridTemplateColumns: '27px auto 22px',
                gridTemplateRows: 'auto auto auto',
                gridTemplateAreas: '"appIcon titlesAndBody timeAndThumbnail"',
                gap: '0px 5px',
                padding: '6px 8px 6px 6px'
              }}
            >
              <div
                style={{
                  gridArea: 'appIcon',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center'
                }}
              >
                <div
                  style={{
                    width: 26,
                    height: 26,
                    borderRadius: 9,
                    padding: 1,
                    objectFit: 'contain',
                    backgroundColor: 'rgb(0, 0, 0)',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center'
                  }}
                >
                  <img
                    src={icon}
                    style={{
                      borderRadius: 8,
                      width: 24,
                      height: 24,
                      flexShrink: 0,
                    }}
                  />
                </div>
              </div>
              <div
                style={{
                  gridArea: 'titlesAndBody',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center'
                }}
              >
                <h2
                  style={{
                    fontSize: 10,
                    letterSpacing: -0.09,
                    color: 'rgb(0, 0, 0)',
                    userSelect: 'none',
                    fontWeight: 500,
                    lineHeight: '12px',
                    margin: 0,
                    width: 144,
                    display: 'block',
                    overflow: 'hidden',
                    whiteSpace: 'nowrap',
                    textOverflow: 'ellipsis'
                  }}
                >
                  {title || '-'}
                </h2>
                <div
                  style={{
                    fontSize: 13,
                    letterSpacing: -0.09,
                    color: 'rgb(0, 0, 0)',
                    userSelect: 'none',
                    fontWeight: 500,
                    lineHeight: '14px',
                    margin: 0,
                    width: 144,
                    display: 'block',
                    overflow: 'hidden',
                    whiteSpace: 'nowrap',
                    textOverflow: 'ellipsis'
                  }}
                >
                  <p
                    style={{
                      fontSize: 9,
                      fontWeight: 300,
                      letterSpacing: -0.09,
                      lineHeight: '11px',
                      color: 'rgb(0, 0, 0)',
                      userSelect: 'none',
                      margin: 0,
                      whiteSpace: 'pre-wrap',
                      display: '-webkit-box',
                      overflow: 'hidden',
                      maxHeight: 75,
                      WebkitLineClamp: 4,
                      width: '100%'
                    }}
                  >
                    {body}
                  </p>
                </div>
              </div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'flex-end',
                  gridArea: 'timeAndThumbnail',
                  gap: 3
                }}
              >
                <div
                  style={{
                    textAlign: 'right',
                    fontSize: 7,
                    color: 'rgb(0, 0, 0)'
                  }}
                >
                  now
                </div>
                {!open && imageUrl && (
                  <img
                    style={{
                      width: 22,
                      height: 22,
                      borderRadius: 5,
                      objectFit: 'cover'
                    }}
                    src={imageUrl}
                  />
                )}
              </div>
            </div>

            {open && imageUrl && (
              <img className="block w-full" width="100%" src={imageUrl} />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
