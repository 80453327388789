import { CreateNotificationsInput } from '@/__generated__/graphql.ts';
import { useState } from 'react';
import Select from '@/lib/ui/select.tsx';
import IosPreview from '@/app/app/notifications/components/preview/ios.tsx';
import AndroidPreview from '@/app/app/notifications/components/preview/android.tsx';

type PreviewNotificationProps = Pick<
  CreateNotificationsInput,
  'body' | 'title' | 'imageUrl'
> & {
  icon: string;
};

enum Platform {
  android = 'android',
  ios = 'ios'
}

export default function PreviewNotification(props: PreviewNotificationProps) {
  const [platform, setPlatform] = useState<Platform>(Platform.android);

  return (
    <div className="flex flex-col gap-5 w-[300px]">
      <Select
        value={platform}
        onChange={(key) => setPlatform(key as Platform)}
        options={[
          {
            label: 'iOS',
            value: Platform.ios
          },
          {
            label: 'Android',
            value: Platform.android
          }
        ]}
      />

      {platform === Platform.ios ? (
        <IosPreview {...props} />
      ) : (
        <AndroidPreview {...props} />
      )}
    </div>
  );
}
