import { Key, PropsWithChildren, ReactNode } from 'react';
import { Tab, Tabs as NexUITabs, TabsProps } from '@nextui-org/react';

export type TabProps = PropsWithChildren<{
  key: Key;
  label: ReactNode;
  disabled?: boolean;
}>;

export default function Tabs({
  items,
  ...rest
}: Omit<TabsProps, 'items'> & {
  items: TabProps[];
}) {
  return (
    <NexUITabs {...rest}>
      {items
        .filter((item) => !item?.disabled)
        .map(({ key, label, children }) => (
          <Tab key={key} title={label}>
            {children}
          </Tab>
        ))}
    </NexUITabs>
  );
}
