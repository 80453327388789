import Form, { FormItem } from 'reactivity-hook-form';
import { Button } from '@nextui-org/react';
import { Link, useNavigate } from 'react-router-dom';
import AppContainer from '@/app/app/shared/components/AppContainer.tsx';
import NewNotification from '@/app/app/notifications/components/new-notification';
import {
  CreateNotificationsInput,
  SendUserNotificationEnum
} from '@/__generated__/graphql.ts';
import Tabs from '@/lib/ui/tabs.tsx';
import Title from '@/lib/ui/title.tsx';
import { useNewNotification } from '@/app/app/notifications/store/mutation/notifications.mutation.ts';
import PreviewNotification from '@/app/app/notifications/components/preview';
import appIcon from '../../../../public/icon-app.png';
import { startMonthDate } from '@/app/app/clients/store/query/clients.query.ts';
import { useTranslation } from '@/hooks/useTranslation.ts';
import { PrivateRotes } from '@/routes/private-rotes.ts';

export default function NewNotificationPage() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { onNewNotification, loading } = useNewNotification();

  const onSubmit = async (values: CreateNotificationsInput) => {
    const isAllSelected =
      values?.users?.template === SendUserNotificationEnum.All;

    await onNewNotification({
      ...values,
      users: {
        ...values?.users,
        filter: values?.users?.filter || undefined,
        connect: isAllSelected ? [] : values?.users?.connect
      }
    });

    // Redirect to the notifications page
    navigate(PrivateRotes.NOTIFICATIONS);
  };

  return (
    <AppContainer size="md">
      <Title>{t('notifications.new')}</Title>

      <Form<CreateNotificationsInput>
        onSubmit={onSubmit}
        validations={{
          title: {
            required: t('validations.required')
          }
        }}
        defaultValues={{
          title: '',
          body: '',
          imageUrl: '',
          users: {
            connect: [],
            filter: null,
            date: startMonthDate,
            template: SendUserNotificationEnum.Selection
          },
          sendToEmail: true
        }}
      >
        <Tabs
          color="primary"
          variant="underlined"
          items={[
            {
              key: 'notification',
              label: t('notifications.notification'),
              children: (
                <div className="flex-col flex gap-5 mt-[-10px]">
                  <NewNotification />
                </div>
              )
            },
            {
              key: 'preview',
              label: t('notifications.preview'),
              children: (
                <FormItem<CreateNotificationsInput>>
                  {({ watch }) => {
                    const formValues = watch();
                    return (
                      <PreviewNotification {...formValues} icon={appIcon} />
                    );
                  }}
                </FormItem>
              )
            }
          ]}
        />

        <div className="flex justify-end mt-5 mb-10 gap-2">
          <Button
            as={Link}
            variant="light"
            color="default"
            disabled={loading}
            to={PrivateRotes.NOTIFICATIONS}
          >
            {t('cancel')}
          </Button>
          <Button isLoading={loading} type="submit" color="primary">
            {t('send')}
          </Button>
        </div>
      </Form>
    </AppContainer>
  );
}
