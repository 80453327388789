import './style.css';
import { Button, ModalHeader } from '@nextui-org/react';
import RCDrawer, { DrawerProps } from 'rc-drawer';
import { PropsWithChildren } from 'react';
import Container from '@/lib/ui/container.tsx';
import Text from '@/lib/ui/text.tsx';
import { X } from 'lucide-react';

export default function Drawer({
  isOpen,
  onClose,
  children,
  title,
  description,
  closable = true,
  ...restProps
}: PropsWithChildren<
  Pick<DrawerProps, 'onClose'> & {
    isOpen: boolean;
    closable?: boolean;
    title?: string;
    description?: string;
  }
>) {
  return (
    <RCDrawer
      open={isOpen}
      onClose={onClose}
      {...restProps}
      className={isOpen ? 'animate-slide-right-left' : ''}
    >
      {closable && (
        <Button
          isIconOnly
          variant="light"
          onClick={onClose}
          className="absolute top-2 right-2 z-50"
        >
          <X />
        </Button>
      )}
      {(title || description) && (
        <ModalHeader className="sticky top-0 left-0 flex flex-col gap-1 bg-background shadow-sm z-40">
          {title}
          <Text className="text-xs">{description}</Text>
        </ModalHeader>
      )}
      <Container>{children}</Container>
    </RCDrawer>
  );
}
