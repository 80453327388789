import {
  createContext,
  PropsWithChildren,
  useContext,
  useEffect,
  useState
} from 'react';

export type ThemeContextValue = {
  theme: 'light' | 'dark';
  setTheme: (theme: 'light' | 'dark') => void;
};

const ThemeContext = createContext<ThemeContextValue | null>(null);

const theme_key = 'theme';

export function ThemeProvider({ children }: PropsWithChildren) {
  const [theme, setTheme] = useState(() => {
    if (typeof window !== 'undefined') {
      const theme = window.localStorage.getItem(theme_key);
      return theme ? (theme as 'light' | 'dark') : 'light';
    }
    return 'light';
  });

  useEffect(() => {
    console.log('theme', theme);
    document.body.classList.remove('light', 'dark');
    document.body.classList.add(theme);
    window.localStorage.setItem(theme_key, theme);
  }, [theme]);

  return (
    <ThemeContext.Provider
      value={{
        theme,
        setTheme
      }}
    >
      {children}
    </ThemeContext.Provider>
  );
}

export function useTheme() {
  const context = useContext(ThemeContext);

  if (!context) {
    throw new Error('useTheme must be used within a ThemeProvider');
  }

  return context;
}
