import { getCountries } from 'node-countries';
import Select, { SelectProps } from '@/lib/ui/select.tsx';

const countries = getCountries();

const countriesCodeMapOptions = countries.map((country) => ({
  label: country.name,
  value: country.name
}));

export default function CountryCodeSelect(
  props: Partial<Omit<SelectProps, 'options'>>
) {
  return <Select {...props} options={countriesCodeMapOptions} />;
}
