import { create } from 'zustand';
import dayjs from 'dayjs';
import { useTranslation } from '@/hooks/useTranslation.ts';

export enum DateRangeEnum {
  TODAY = 'today',
  CURRENT_MONTH = 'current_month',
  LAST_7_DAYS = 'last_7_days',
  LAST_15_DAYS = 'last_15_days',
  LAST_30_DAYS = 'last_30_days',
  LAST_90_DAYS = 'last_90_days',
  LAST_YEAR_DAYS = 'last_year_days'
}

export type DashboardFiltersType = {
  now: dayjs.Dayjs;
  startDate: dayjs.Dayjs;
  endDate: dayjs.Dayjs;
  dateRange: DateRangeEnum;
  onChangeDateRange: (dateRange: DateRangeEnum | null) => void;
};

const now = dayjs();

export const useDashboardFilters = create<DashboardFiltersType>((setState) => {
  return {
    now,
    dateRange: DateRangeEnum.CURRENT_MONTH,
    startDate: now.startOf('month'),
    endDate: now.endOf('month'),
    onChangeDateRange: (dateRange) => {
      switch (dateRange) {
        case DateRangeEnum.TODAY:
          setState({
            dateRange,
            startDate: now.startOf('day'),
            endDate: now.endOf('day')
          });
          break;
        case DateRangeEnum.CURRENT_MONTH:
          setState({
            dateRange,
            startDate: now.startOf('month'),
            endDate: now.endOf('month')
          });
          break;
        case DateRangeEnum.LAST_7_DAYS:
          setState({
            dateRange,
            startDate: now.subtract(7, 'day'),
            endDate: now
          });
          break;
        case DateRangeEnum.LAST_15_DAYS:
          setState({
            dateRange,
            startDate: now.subtract(15, 'day'),
            endDate: now
          });
          break;
        case DateRangeEnum.LAST_30_DAYS:
          setState({
            dateRange,
            startDate: now.subtract(30, 'day'),
            endDate: now
          });
          break;
        case DateRangeEnum.LAST_90_DAYS:
          setState({
            dateRange,
            startDate: now.subtract(90, 'day'),
            endDate: now
          });
          break;
        case DateRangeEnum.LAST_YEAR_DAYS:
          setState({
            dateRange,
            startDate: now.subtract(1, 'year'),
            endDate: now
          });
          break;
        default:
          setState({
            dateRange: DateRangeEnum.CURRENT_MONTH,
            startDate: now.startOf('month'),
            endDate: now.endOf('month')
          });
      }
    }
  };
});

export function useGetFilterOptions() {
  const { t } = useTranslation();

  return [
    {
      label: t(`filter_dashboard.today`),
      value: DateRangeEnum.TODAY
    },
    {
      label: t(`filter_dashboard.current_month`),
      value: DateRangeEnum.CURRENT_MONTH
    },
    {
      label: t(`filter_dashboard.last_7_days`),
      value: DateRangeEnum.LAST_7_DAYS
    },
    {
      label: t(`filter_dashboard.last_15_days`),
      value: DateRangeEnum.LAST_15_DAYS
    },
    {
      label: t(`filter_dashboard.last_30_days`),
      value: DateRangeEnum.LAST_30_DAYS
    },
    {
      label: t(`filter_dashboard.last_90_days`),
      value: DateRangeEnum.LAST_90_DAYS
    },
    {
      label: t(`filter_dashboard.last_year_days`),
      value: DateRangeEnum.LAST_YEAR_DAYS
    }
  ];
}
