import { useRef } from 'react';
import Loading from '@/lib/ui/loading.tsx';
import EmptyState from '@/lib/ui/empty-state.tsx';
import { useTranslation } from '@/hooks/useTranslation.ts';
import {
  Bar,
  BarChart,
  Line,
  ResponsiveContainer,
  XAxis,
  Tooltip
} from 'recharts';
import { useTheme } from '@/context/theme-provider.tsx';
import Text from '@/lib/ui/text.tsx';
import { cn } from '@/lib/utils.ts';
import { darkenColor } from '@/utils/colors.ts';

export type LinearChartItemProps = Record<string, any> & {
  label: string;
  labelTooltip?: string;
  formattedValue?: string | number | null | undefined;
};

export type LinearChartProps = {
  name: string;
  loading?: boolean;
  data: Array<LinearChartItemProps>;
  dataKeys: Array<{ key: string; color: string }>;
};

export default function LinearChart({
  name,
  data,
  dataKeys,
  loading,
}: LinearChartProps) {
  const { t } = useTranslation();
  const { theme } = useTheme();
  const chartContainerRef = useRef<HTMLDivElement>(null);

  if (loading) {
    return <Loading className="min-h-[300px]" />;
  }

  if (!data?.length) {
    return <EmptyState title={t('empty')} />;
  }

  const isDark = theme === 'dark';

  return (
    <div ref={chartContainerRef} className="h-72 w-full relative">
      <ResponsiveContainer width="100%" height="100%">
        <BarChart data={data}>
          <defs>
            {dataKeys.map((dataKeyItem) => (
              <linearGradient
                key={dataKeyItem.color}
                id={`barGradient-${dataKeyItem.color}`}
                x1="0"
                y1="0"
                x2="0"
                y2="1"
              >
                <stop
                  offset="0%"
                  stopColor={darkenColor(dataKeyItem.color, -10)}
                  stopOpacity={1}
                />
                <stop
                  offset="100%"
                  stopColor={darkenColor(dataKeyItem.color, 30)}
                  stopOpacity={1}
                />
              </linearGradient>
            ))}
          </defs>

          <XAxis
            dataKey="label"
            axisLine={false}
            tickLine={false}
            fontSize={14}
            tick={{ fill: 'hsl(var(--muted-foreground))' }}
            style={{
              textTransform: 'capitalize'
            }}
          />

          <Tooltip
            content={({ payload }) => {
              return (
                <div
                  className={cn('bg-card border rounded p-2', {
                    'border-gray-500': isDark,
                    'border-gray-200': !isDark
                  })}
                >
                  <Text className="mb-2">{name}:</Text>

                  {payload?.map((entry, index) => {
                    const item = entry.payload;
                    const color = dataKeys.find(
                      (d) => d.key === entry.dataKey
                    )?.color;

                    return (
                      <div
                        key={`item-${index}`}
                        className="flex gap-1 items-center"
                      >
                        <div
                          className="h-[10px] w-[10px] rounded"
                          style={{ backgroundColor: color }}
                        />
                        <Text className="text-sm">
                          <span className="capitalize">
                            {item.labelTooltip || item.label}:
                          </span>{' '}
                          {item?.formattedValue || entry?.value}
                        </Text>
                      </div>
                    );
                  })}
                </div>
              );
            }}
            cursor={{
              fill: 'transparent'
            }}
          />

          {dataKeys.map((dataKeyItem) => (
            <Bar
              barSize={70}
              key={dataKeyItem.key}
              dataKey={dataKeyItem.key}
              fill={`url(#barGradient-${dataKeyItem.color})`}
              radius={[40, 40, 40, 40]}
              background={{
                radius: 40,
                fill: isDark
                  ? 'rgba(255, 255, 255, .1)'
                  : 'rgba(100, 100, 100, .05)'
              }}
            />
          ))}

          <Line dot={false} type="monotone" dataKey="value" />
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
}
