import dayjs from 'dayjs';
import { useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { CalendarDays, CreditCard } from 'lucide-react';

import GridColumn from '@/lib/ui/grid-column.tsx';
import GenericCard from '@/lib/ui/generic-card.tsx';
import Loading from '@/lib/ui/loading.tsx';
import ClientCard from '@/app/app/clients/components/ClientCard';
import CreditsCard from '@/app/app/clients/components/CreditsCard';
import AppContainer from '@/app/app/shared/components/AppContainer.tsx';
import SubscriptionsCard from '@/app/app/clients/components/SubscriptionsCard';
import { TheLastCredit } from '@/app/app/clients/components/CreditsCard/type.ts';
import {
  useGetClient,
  useGetSubscriptions
} from '@/app/app/clients/store/query/clients.query.ts';
import ListOfClasses from '@/app/app/clients/components/ListOfClasses';
import { SessionForShow } from '@/app/app/clients/components/ListOfClasses/ListOfClasses.tsx';
import { useTranslation } from '@/hooks/useTranslation.ts';
import usePagination from '@/lib/hooks/usePagination';
import { CREDIT_STATUS_ENUM } from '@/app/app/clients/common/enums/credit.enum.ts';

export default function ClientDetailsPage() {
  const { t } = useTranslation();
  const { id } = useParams<{ id: string }>();
  const { serverPagination, frontPagination, setTotalCount } = usePagination();
  const { data, loadingClient, loadingSessions } = useGetClient({
    ...serverPagination,
    clientId: id
  });
  const subscriptionsOperation = useGetSubscriptions(id);

  const lastCredit = useMemo(() => {
    const last = (subscriptionsOperation?.data?.credits?.[0] ??
      null) as TheLastCredit;

    if (last?.status === CREDIT_STATUS_ENUM.VALID) {
      return last;
    }
    return null;
  }, [subscriptionsOperation?.data?.credits]);

  const diffDays = dayjs(lastCredit?.expiredAt).diff(dayjs(), 'days');

  const classes = lastCredit?.creditBuy || 0;

  useEffect(() => {
    if (!loadingSessions) {
      setTotalCount(data?.sessions?.user?.subscriptionsCount || 0);
    }
  }, [
    data?.sessions?.user?.subscriptionsCount,
    loadingSessions,
    setTotalCount
  ]);

  return (
    <AppContainer size="xl">
      {loadingClient ? (
        <div className="py-2 flex justify-center">
          <Loading />
        </div>
      ) : (
        <>
          <GridColumn xs={1} md={3} spacing={5}>
            <ClientCard {...data?.client} />

            <SubscriptionsCard
              id={id as string}
              data={subscriptionsOperation.data}
              loading={subscriptionsOperation.loading}
              refetch={subscriptionsOperation.refetch}
            />

            <div className="flex flex-col gap-5">
              <div className="flex gap-5">
                <GenericCard
                  footer={t('available')}
                  subTitle={t(classes <= 1 ? 'class' : 'classes')}
                  icon={<CreditCard className="text-white" />}
                  className="bg-primary/80 text-white w-[50%]"
                  title={classes - (lastCredit?.creditUsed || 0)}
                />

                <GenericCard
                  title={diffDays <= 0 ? 0 : diffDays}
                  footer={t('to_end_subscription')}
                  className="bg-danger/80 text-white w-[50%]"
                  icon={<CalendarDays className="text-white" />}
                  subTitle={t(diffDays <= 1 ? 'day' : '_days')}
                />
              </div>

              <CreditsCard
                clientId={id as string}
                lastCredit={lastCredit}
                paymentPreference={data?.client?.paymentPreference}
              />
            </div>
          </GridColumn>

          <ListOfClasses
            className="mt-12"
            pagination={frontPagination}
            data={
              data?.sessions?.user?.subscriptions as unknown as SessionForShow[]
            }
          />
          <div className="w-full h-24" />
        </>
      )}
    </AppContainer>
  );
}
