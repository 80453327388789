import { FormValidations } from 'reactivity-hook-form';
import { NewOrEditCreditsPayload } from '@/app/app/clients/components/CreditsCard/type.ts';
import { useAuth } from '@/app/auth/_context';
import { PaymentMethodEnum } from '@/app/app/clients/components/PaymentMethodSelect/PaymentMethodSelect.tsx';

export default function useValidation(): FormValidations<NewOrEditCreditsPayload> {
  const { activeSpace } = useAuth();

  return {
    plan: {
      validate(value, values) {
        if (!value && !values?.lastCredit) {
          return 'This field is required';
        }

        return undefined;
      }
    },
    lastCredit: {
      validate(value, values) {
        if (!value && !values?.plan) {
          return 'This field is required';
        }

        return undefined;
      }
    },
    openAt: {
      required: 'This field is required'
    },
    paymentMethod: {
      required: 'This field is required',
      validate(value) {
        if (
          activeSpace?.space?.paymentsProviderCount === 0 &&
          value === PaymentMethodEnum.STRIPE
        ) {
          return 'You need to configure a stripe payment method in configuration/stripe';
        }
      }
    },
    expiredAt: {
      required: 'This field is required'
    },
    totalPrice: {
      required: 'This field is required'
    }
  };
}
