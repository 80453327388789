import amenities from '../../../../../../public/amenities.json';
import Select, { SelectProps } from '@/lib/ui/select.tsx';
import { useTranslation } from '@/hooks/useTranslation.ts';

export default function Amenities(props: Omit<SelectProps, 'options'>) {
  const { lang } = useTranslation();

  return (
    <Select
      {...props}
      multiple
      options={amenities.map((item) => ({
        label: item[`name_${lang}`],
        value: item.id
      }))}
    />
  );
}
