import CoachList from '@/app/app/coaches/components/CoachList';
import { useTranslation } from '@/hooks/useTranslation';
import NewOrEditCoach from '@/app/app/coaches/components/NewOrEditCoach/NewOrEditCoach.tsx';
import useToggle from '@/hooks/useToggle.ts';
import HeaderPage from '@/app/app/shared/components/HeaderPage.tsx';
import AppContainer from '@/app/app/shared/components/AppContainer.tsx';

export default function CoachesPage() {
  const { t } = useTranslation();
  const [isOpen, actionOpen] = useToggle();

  return (
    <AppContainer>
      <HeaderPage
        title={t('coach.title')}
        primaryAction={{
          text: t('coach.new'),
          onClick: actionOpen.onVisible
        }}
      />

      <CoachList />

      <NewOrEditCoach isOpen={isOpen} onClose={actionOpen.onHidden} />
    </AppContainer>
  );
}
