import { FormItem } from 'reactivity-hook-form';
import { useFormContext } from 'react-hook-form';

import { useTranslation } from '@/hooks/useTranslation';
import { NewSessionFormType } from '@/app/app/sessions/common/types';
import { SESSION_FREQUENCY_ENUM } from '@/app/app/sessions/common/enum.ts';
import WeekDays from '@/lib/ui/week-days.tsx';

export function RepeatOnField() {
  const { t } = useTranslation();
  const context = useFormContext<NewSessionFormType>();

  const frequency = context.watch('repeatSettings.frequency');

  if (frequency !== SESSION_FREQUENCY_ENUM.EVERY_WEEK) return null;

  return (
    <FormItem<NewSessionFormType>
      key="daysRepeat"
      name="repeatSettings.daysRepeat"
    >
      <WeekDays label={t('sessions.repeat_on')} />
    </FormItem>
  );
}
