import { PrivateRotes } from '@/routes/private-rotes.ts';

export enum SETTINGS_TAB_ENUM {
  generals = 'generals',
  class_offered = 'class_offered',
  plans = 'plans',
  categories = 'categories',
  admins = 'admins',
  stripe = 'stripe'
}

export const SETTINGS_ROUTES = {
  BASE_FIXED: PrivateRotes.SETTINGS.replace('/:tab', ''),
  BASE: PrivateRotes.SETTINGS,
  RETURN_STRIPE_CALLBACK: PrivateRotes.SETTINGS_STRIPE_RETURN,
  REFRESH_STRIPE_CALLBACK: PrivateRotes.SETTINGS_STRIPE_REFRESH,
  TAB(tab: SETTINGS_TAB_ENUM) {
    return PrivateRotes.SETTINGS.replace(':tab', tab?.toString?.());
  }
};
