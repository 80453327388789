import '@/app/auth/login/style.css';
import LogoutButton from './_logout-button.tsx';
import DesignLeft from '@/app/auth/_components/design-left/design-left.tsx';
import { useTranslation } from '@/hooks/useTranslation.ts';
import Title from '@/lib/ui/title.tsx';

export default function SignupPage() {
  const { t } = useTranslation();

  return (
    <main className="w-full flex h-screen p-5 md:flex-row flex-col">
      {/* Col 1 */}
      <DesignLeft />
      {/* EndCol 1 */}

      {/* Col 2 */}
      <div className="md:w-[50%] md:h-full md:pl-5 flex justify-center items-center">
        <div className="text-left w-full lg:max-w-[400px]">
          <img src="/icon.png" width={120} className="mb-10" />

          <div>
            <Title className="text-2xl leading-10">{t('signup')}</Title>
            <p className="text-gray-500">
              {t('signup_description')}
            </p>

            <LogoutButton />
          </div>
        </div>
      </div>
      {/* EndCol 2 */}
    </main>
  );
}
