import dayjs from 'dayjs';
import {
  NewRepeatSessionType,
  NewSessionFormType,
  NewSessionType
} from '@/app/app/sessions/common/types.ts';

export function createStartOrEndDate(
  _date: dayjs.ConfigType,
  _time: dayjs.ConfigType
) {
  const date = dayjs(_date);
  const time = dayjs(_time);

  if (!date.isValid() || !time.isValid()) {
    throw new Error('Invalid time');
  }

  return date
    .set('hours', time.get('hours'))
    .set('minutes', time.get('minutes'))
    .set('seconds', 0)
    .set('milliseconds', 0)
    .toISOString();
}

export function createCardEventStyle({
  color,
  type
}: {
  color?: string | null;
  type: 'CALENDAR' | 'BOARD';
}) {
  if (type === 'CALENDAR') {
    return {
      className: color
        ? 'bg-background'
        : 'bg-background border-brand text-brand',
      style: color
        ? {
            borderColor: color,
            color: color
          }
        : {}
    };
  }

  return {
    className: color ? '' : 'border-brand',
    style: color
      ? {
          borderColor: color
        }
      : {}
  };
}

export function isOnlyOneSession(
  data: NewSessionFormType
): data is NewSessionType {
  return !data.isRepeatEvent;
}

export function isRepeatSession(
  data: NewSessionFormType
): data is NewRepeatSessionType {
  return data.isRepeatEvent;
}

export const now = new Date();

export function isDisabledSession(
  startedAt?: string | null,
  isBacklog?: boolean | null
) {
  return dayjs(startedAt).endOf('day').isBefore(now) || !!isBacklog;
}
