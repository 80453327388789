import Select, { SelectSingleProps } from '@/lib/ui/select.tsx';
import { renderContentOrFallback } from '@/utils';
import { useTranslation } from '@/hooks/useTranslation.ts';

export type ClientSelectorProps = Omit<SelectSingleProps, 'options'>;

export enum SUBSCRIPTION_STATUS_ENUM {
  PENDING = 'pending',
  CONFIRMED = 'confirmed',
  CANCELED = 'canceled'
}

export const SUBSCRIPTION_STATUS_MAP_LABELS: Record<
  SUBSCRIPTION_STATUS_ENUM,
  string
> = {
  [SUBSCRIPTION_STATUS_ENUM.PENDING]: 'Pending',
  [SUBSCRIPTION_STATUS_ENUM.CONFIRMED]: 'Confirmed',
  [SUBSCRIPTION_STATUS_ENUM.CANCELED]: 'Canceled'
};

export const SUBSCRIPTION_STATUS_MAP_OPTIONS = [
  {
    label: SUBSCRIPTION_STATUS_MAP_LABELS[SUBSCRIPTION_STATUS_ENUM.PENDING],
    value: SUBSCRIPTION_STATUS_ENUM.PENDING
  },
  {
    label: SUBSCRIPTION_STATUS_MAP_LABELS[SUBSCRIPTION_STATUS_ENUM.CONFIRMED],
    value: SUBSCRIPTION_STATUS_ENUM.CONFIRMED
  },
  {
    label: SUBSCRIPTION_STATUS_MAP_LABELS[SUBSCRIPTION_STATUS_ENUM.CANCELED],
    value: SUBSCRIPTION_STATUS_ENUM.CANCELED
  }
];

export default function SubscriptionStatusSelect(props: ClientSelectorProps) {
  const { t } = useTranslation();

  return (
    <Select
      {...props}
      label={t('status')}
      options={SUBSCRIPTION_STATUS_MAP_OPTIONS.map((item) => ({
        ...item,
        label: t(`subscription_status.${item.value}` as LanguageKeys)
      }))}
      placeholder={renderContentOrFallback(props?.value, 'Select a state')}
    />
  );
}
