import './styles/global.css';

import React from 'react';
import ReactDOM from 'react-dom/client';
import { NextUIProvider } from '@nextui-org/react';
import { ReactivityHookFormProvider } from 'reactivity-hook-form';
import { QueryClientProvider } from '@tanstack/react-query';
import { Analytics } from '@vercel/analytics/react';

import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import weekday from 'dayjs/plugin/weekday';
import updateLocale from 'dayjs/plugin/updateLocale';
import localeData from 'dayjs/plugin/localeData';

import Routes from './routes';
import { LocationProvider } from '@/context/location-provider.tsx';
import { ThemeProvider } from '@/context/theme-provider.tsx';
import { ApolloProviderClient } from '@/graphql/apollo.tsx';
import { DayjsChangeLocation } from '@/lib/ui/dayjs-change-location.tsx';
import { queryClient } from '@/config/react-query.ts';
import DialogProvider from '@/lib/ui/Dialog/Provider.tsx';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(updateLocale);
dayjs.extend(localeData);
dayjs.extend(isSameOrBefore);
dayjs.extend(isSameOrAfter);
dayjs.extend(weekday);

ReactDOM.createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <ThemeProvider>
      <NextUIProvider>
        <ApolloProviderClient>
          <LocationProvider>
            <ReactivityHookFormProvider showErrorText={false}>
              <QueryClientProvider client={queryClient}>
                <DialogProvider>
                  <Routes />
                </DialogProvider>
              </QueryClientProvider>
            </ReactivityHookFormProvider>
            <DayjsChangeLocation />
          </LocationProvider>
        </ApolloProviderClient>
      </NextUIProvider>
    </ThemeProvider>
    <Analytics />
  </React.StrictMode>
);
