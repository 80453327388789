import { FormItem } from 'reactivity-hook-form';

import Input from '@/lib/ui/input';
import { useTranslation } from '@/hooks/useTranslation';
import { NewSessionFormType } from '@/app/app/sessions/common/types.ts';

export function LimitQuoteField() {
  const { t } = useTranslation();

  return (
    <FormItem<NewSessionFormType> name="limitQuote">
      <Input
        type="number"
        placeholder={t('form_placeholder.limitQuote')}
        label={t('form.limitQuote')}
      />
    </FormItem>
  );
}
