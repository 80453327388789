import { Toaster } from 'sonner';
import { ReactNode } from 'react';
import { Outlet } from 'react-router-dom';

import { AuthProvider } from '@/app/auth/_context';
import CalendarProvider from '@/context/calendar-provider';
import TriggerFirebase from '@/app/auth/_trigger_firebase.tsx';
import { CreateOrEditSessionProvider } from '@/app/app/sessions/components/create-edit-session/context';

export default function RootLayout({ children }: { children?: ReactNode }) {
  return (
    <div>
      <AuthProvider>
        <TriggerFirebase />
        <CalendarProvider>
          <CreateOrEditSessionProvider>
            {children ?? <Outlet />}
          </CreateOrEditSessionProvider>
        </CalendarProvider>
        <Toaster richColors closeButton />
      </AuthProvider>
    </div>
  );
}
