import { Link, useLocation } from 'react-router-dom';
import { PropsWithChildren, useMemo } from 'react';
import { cn } from '@/lib/utils.ts';

export default function NavbarItem({
  href,
  replace,
  children,
  trigger
}: PropsWithChildren<{
  href?: string;
  trigger?: () => void;
  replace?: { key: string; value: string };
}>) {
  const location = useLocation();

  const path = useMemo(() => {
    if (replace) {
      return href?.replace?.(replace.key, replace.value) || '';
    }

    return href || '';
  }, [href, replace]);

  const isActive = useMemo(() => {
    return location?.pathname.includes(path);
  }, [path, location?.pathname]);

  const Component = path ? Link : (props: any) => <span {...props} />;

  return (
    <Component
      to={href || ''}
      onClick={trigger}
      className={cn(
        `transition-colors hover:text-foreground/80 block`,
        isActive ? 'text-foreground font-medium' : 'text-foreground/60'
      )}
    >
      {children}
    </Component>
  );
}
