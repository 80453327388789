import { NewSessionFormType } from '@/app/app/sessions/common/types.ts';
import {
  createStartOrEndDate,
  isRepeatSession
} from '@/app/app/sessions/common/helper.ts';
import {
  FinishEnum,
  SESSION_FREQUENCY_ENUM
} from '@/app/app/sessions/common/enum.ts';
import {
  CreateRepeatSessionMutationVariables,
  Maybe,
  SessionFragmentFragment,
  Space,
  UpdateAllSessionForEventBackofficeMutationVariables
} from '@/__generated__/graphql.ts';

export default class RepeatSessionRequestMapper {
  constructor(
    private readonly rawData: NewSessionFormType,
    private readonly activeSpace?: Maybe<Space>
  ) {}

  private get finishExtraProps() {
    if (
      this.rawData.isRepeatEvent &&
      this.rawData.repeatSettings?.finishType === FinishEnum.DATE
    ) {
      return {
        finishedRepeatAt: this.rawData.repeatSettings.finishedRepeatAt
      };
    }
    if (
      this.rawData.isRepeatEvent &&
      this.rawData.repeatSettings?.finishType === FinishEnum.COUNT
    ) {
      return {
        count: Number(this.rawData.repeatSettings.count)
      };
    }

    return {};
  }

  private get frequencyExtraProps() {
    if (
      this.rawData.isRepeatEvent &&
      this.rawData.repeatSettings?.frequency ===
        SESSION_FREQUENCY_ENUM.EVERY_WEEK
    ) {
      return {
        daysRepeat: this.rawData.repeatSettings
          ?.daysRepeat as CreateRepeatSessionMutationVariables['data']['daysRepeat']
      };
    }

    return {};
  }

  toRequest(): CreateRepeatSessionMutationVariables {
    if (!this.activeSpace) {
      throw new Error('activeSpace cannon be undefined, please check the data');
    }

    if (isRepeatSession(this.rawData) && this.rawData.repeatSettings) {
      return {
        data: {
          cost: 1,
          name: this.rawData.name,
          description: this.rawData?.summary,
          color: this.rawData?.color,
          frequency: this.rawData.repeatSettings.frequency,
          interval: this.rawData.repeatSettings.interval,
          limitQuote: Number(this.rawData.limitQuote),
          finishType: this.rawData.repeatSettings.finishType,
          startedAt: createStartOrEndDate(new Date(), this.rawData.startTime),
          finishedAt: createStartOrEndDate(new Date(), this.rawData.finishTime),
          space: {
            connect: {
              id: this?.activeSpace?.id as string
            }
          },
          coach: {
            connect: {
              id: this.rawData.coach
            }
          },
          categories: {
            connect:
              this.rawData.categories?.map?.((category) => ({
                id: category
              })) || []
          },
          ...this.frequencyExtraProps,
          ...this.finishExtraProps
        }
      };
    }
    throw new Error(
      'Invalid data, please check the data. isRepeatEvent = true'
    );
  }

  toEditRequest(
    session: SessionFragmentFragment
  ): UpdateAllSessionForEventBackofficeMutationVariables {
    const { name, summary, color, limitQuote, startTime, finishTime, coach } =
      this.rawData;

    return {
      id: session.id,
      data: {
        name: name !== session?.name ? name : undefined,
        description: summary !== session?.description ? summary : undefined,
        color: color !== session?.color ? color : undefined,
        limitQuote:
          limitQuote !== session?.limitQuote ? Number(limitQuote) : undefined,
        startedAt:
          startTime !== session?.startedAt
            ? createStartOrEndDate(session.startedAt, startTime)
            : undefined,
        finishedAt:
          finishTime !== session?.finishedAt
            ? createStartOrEndDate(session.finishedAt, finishTime)
            : undefined,
        coach:
          coach !== session?.coach?.id
            ? {
                connect: {
                  id: coach
                }
              }
            : undefined,
        categories: {
          connect:
            this.rawData.categories?.map?.((category) => ({
              id: category
            })) || []
        }
      }
    };
  }
}
