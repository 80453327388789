import es from '@/locales/es/default.json';
import en from '@/locales/en/default.json';
import { useLocation } from '@/context/location-provider.tsx';
import { useCallback } from 'react';

const languages = {
  es,
  en
};

export function useTranslation() {
  const location = useLocation();

  const t = useCallback(
    (
      key: LanguageKeys,
      fallback?: string | null,
      params?: Record<string, any>
    ): string => {
      if (!key) return '';

      const definitions = languages[location.lang];

      let output: Record<string, string> | string | null = null;
      const keyspoint = key.split('.');

      if (keyspoint.length) {
        keyspoint.forEach((text) => {
          if (output && typeof output === 'object') {
            output = output?.[text] ?? null;
          } else {
            output = definitions?.[text as keyof typeof es] ?? null;
          }
        });
      }

      let valueResolved = output ?? (fallback || key) ?? '';

      Object.entries(params || {}).forEach(([k, v]) => {
        valueResolved = valueResolved.replace(`{{${k}}}`, v);
      });

      return valueResolved;
    },
    [location]
  );

  return {
    t,
    ...location
  };
}
