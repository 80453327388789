import { useState } from 'react';
import { Button } from '@nextui-org/react';
import { X } from 'lucide-react';
import useToggle from '@/hooks/useToggle.ts';

const en = (
  <>
    <h1 className="capitalize font-bold text-2xl mb-2">Privacy Policy</h1>
    <p>
      At Jogo App LLC, we respect your privacy and are committed to protecting
      your personal data. This privacy policy explains how we collect, use, and
      share your information when you use our app.
    </p>

    <h2 className="capitalize font-bold text-lg mb-2 mt-5">
      1. Data We Collect
    </h2>
    <p>We collect the following types of information:</p>
    <ul className="list-disc pl-5">
      <li>
        <strong>Personal data:</strong> Name, email address, or other
        information you voluntarily provide.
      </li>
      <li>
        <strong>Usage data:</strong> Information about how you interact with the
        app (e.g., pages visited).
      </li>
    </ul>

    <h2 className="capitalize font-bold text-lg mb-2 mt-5">2. Use of Data</h2>
    <p>We use your data to:</p>
    <ul className="list-disc pl-5">
      <li>Improve the app's performance and functionalities.</li>
      <li>Provide personalized services.</li>
      <li>Send service-related communications.</li>
    </ul>

    <h2 className="capitalize font-bold text-lg mb-2 mt-5">3. Sharing Data</h2>
    <p>
      We do not share your data with third parties, except when necessary for
      the operation of the app or when required by law.
    </p>

    <h2 className="capitalize font-bold text-lg mb-2 mt-5">4. Data Security</h2>
    <p>
      We implement technical and organizational measures to protect your
      information from unauthorized access, loss, or alteration.
    </p>

    <h2 className="capitalize font-bold text-lg mb-2 mt-5">5. Your Rights</h2>
    <p>
      You have the right to access, rectify, or delete your personal information
      at any time. You may also withdraw your consent for the processing of your
      data.
    </p>

    <h2 className="capitalize font-bold text-lg mb-2 mt-5">
      6. Changes to the Privacy Policy
    </h2>
    <p>
      We reserve the right to update this privacy policy at any time. We will
      notify you of any significant changes through the app or via email.
    </p>

    <h2 className="capitalize font-bold text-lg mb-2 mt-5">7. Contact</h2>
    <p>
      If you have any questions or concerns about this policy, you can contact
      us at: <a href="mailto:support@getjogo.com">support@getjogo.com</a>
    </p>
  </>
);

const es = (
  <>
    <h1 className="capitalize font-bold text-2xl mb-2">
      Política de Privacidad
    </h1>
    <p>
      En Jogo App LLC, respetamos tu privacidad y estamos comprometidos a
      proteger tus datos personales. Esta política de privacidad explica cómo
      recopilamos, usamos y compartimos tu información cuando utilizas nuestra
      app.
    </p>

    <h2 className="capitalize font-bold text-lg mb-2 mt-5">
      1. Datos que Recopilamos
    </h2>
    <p>Recopilamos los siguientes tipos de información:</p>
    <ul className="list-disc pl-5">
      <li>
        <strong>Datos personales:</strong> Nombre, correo electrónico, u otra
        información que proporciones voluntariamente.
      </li>
      <li>
        <strong>Datos de uso:</strong> Información sobre cómo interactúas con la
        app (p. ej., páginas visitadas).
      </li>
    </ul>

    <h2 className="capitalize font-bold text-lg mb-2 mt-5">2. Use of Data</h2>
    <p>Utilizamos tus datos para:</p>
    <ul className="list-disc pl-5">
      <li>Mejorar el rendimiento y las funcionalidades de la app.</li>
      <li>Proporcionar servicios personalizados.</li>
      <li>Enviar comunicaciones relacionadas con el servicio.</li>
    </ul>

    <h2 className="capitalize font-bold text-lg mb-2 mt-5">
      3. Compartir Datos
    </h2>
    <p>
      No compartimos tus datos con terceros, excepto cuando es necesario para el
      funcionamiento de la app o cuando la ley lo exige.
    </p>

    <h2 className="capitalize font-bold text-lg mb-2 mt-5">
      4. Seguridad de los Datos
    </h2>
    <p>
      Implementamos medidas técnicas y organizativas para proteger tu
      información contra accesos no autorizados, pérdida o alteración.
    </p>

    <h2 className="capitalize font-bold text-lg mb-2 mt-5">5. Tus Derechos</h2>
    <p>
      Tienes el derecho de acceder, rectificar o eliminar tu información
      personal en cualquier momento. También puedes retirar tu consentimiento
      para el tratamiento de tus datos.
    </p>

    <h2 className="capitalize font-bold text-lg mb-2 mt-5">
      6. Cambios en la Política de Privacidad
    </h2>
    <p>
      Nos reservamos el derecho de actualizar esta política de privacidad en
      cualquier momento. Te notificaremos de cualquier cambio importante a
      través de la app o por correo electrónico.
    </p>

    <h2 className="capitalize font-bold text-lg mb-2 mt-5">7. Contacto</h2>
    <p>
      Si tienes alguna pregunta o inquietud sobre esta política, puedes
      contactarnos en:{' '}
      <a href="mailto:support@getjogo.com">support@getjogo.com</a>
    </p>
  </>
);

const alertMessage = (setLand: (lang: 'es' | 'en') => void) => ({
  en: (
    <p>
      If you wish to see this policy inn <strong>spanish</strong> click{' '}
      <a
        className="text-primary cursor-pointer hover:underline"
        onClick={() => setLand('es')}
      >
        here
      </a>
    </p>
  ),
  es: (
    <p>
      Si deseas ver esta política en <strong>english</strong> haz click{' '}
      <a
        className="text-primary cursor-pointer hover:underline"
        onClick={() => setLand('en')}
      >
        aquí
      </a>
    </p>
  )
});

export default function PrivacyPage() {
  const [lang, setLand] = useState<'es' | 'en'>('en');
  const [closeAlert, closeActions] = useToggle(true);

  const isEnglish = lang === 'en';

  return (
    <main className="p-5 md: p-7">
      {closeAlert && (
        <div className="mb-5 bg-warning/20 p-2 rounded flex justify-between">
          {alertMessage(setLand)[lang]}

          <Button
            isIconOnly
            size="sm"
            startContent={<X size={18} />}
            onClick={closeActions.onHidden}
            className="bg-transparent hover:bg-slate-300"
          />
        </div>
      )}
      {isEnglish ? en : es}
    </main>
  );
}
