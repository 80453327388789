import { gql } from '@/__generated__/gql.ts';

export const CREATE_SESSION_MUTATION = gql(`
  mutation CreateSession($data: SessionCreateInput!) {
    createSession(data: $data) {
      ...SessionFragment
    }
  }
`);

export const CREATE_REPEAT_SESSION_MUTATION = gql(`
  mutation CreateRepeatSession($data: RepeatSessionCreateInput!) {
    createRepeatSession(data: $data) {
      id
    }
  }
`);

export const UPDATE_ALL_SESSIONS_MUTATION = gql(`
  mutation UpdateAllSessionForEventBackoffice($id: ID!, $data: UpdateAllSessionForEventBackofficeInput!) {
    updateAllSessionForEventBackoffice(id: $id, data: $data) {
      id
    }
  }
`);

export const UPDATE_SESSION_MUTATION = gql(`
  mutation UpdateSessionBackoffice($id: ID!, $data: SessionUpdateInput!) {
    updateSession(where: { id: $id }, data: $data) {
      id
    }
  }
`);

export const DELETE_SESSION_MUTATION = gql(`
  mutation DeleteSessionBackoffice($id: ID!) {
    updateSession(where: { id: $id }, data: { status: "deleted" }) {
      id
    }
  }
`);


export const DELETE_REPEAT_SESSION_MUTATION = gql(`
  mutation DeleteRepeatSessionBackoffice($id: ID!) {
    deleteAllSessionForEventBackoffice(id: $id) {
      id
    }
  }
`);
