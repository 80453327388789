import {
  createContext,
  memo,
  PropsWithChildren,
  useCallback,
  useContext,
  useState
} from 'react';
import { toast } from 'sonner';

import { auth } from '@/lib/firebase';
import { AUTH_ROUTES } from '@/app/auth/_common/routes';
import { DASHBOARD_ROUTES } from '@/app/app/dashboard/common/routes';
import {
  useLoginMutation,
  useUpdateSpaceMutation
} from '@/app/auth/_store/mutation/auth.mutation';
import {
  UpdateSpaceMutationVariables,
  UserAuthenticationWithPasswordSuccess,
  UserBackoffice
} from '@/__generated__/graphql.ts';
import { SELECT_SPACE_KEY, SESSION_KEY } from '@/config/constants.ts';

export type UserType = UserAuthenticationWithPasswordSuccess;

export type AuthContextProps = {
  isAuthenticated: boolean;
  isInitialLoading: boolean;
  isLoginLoading: boolean;
  loadingCms: boolean;
  loadingUpdateSpace: boolean;
  user: UserAuthenticationWithPasswordSuccess | null;
  activeSpace: UserBackoffice | null;
  onEditSpace: (props: UpdateSpaceMutationVariables) => void;
  onChangeSpace: (space: UserBackoffice | null) => void;
  login: (firebaseToken: string) => void;
  logout: () => Promise<void>;
  onEndInitialLoading: () => void;
};

const AuthContext = createContext<AuthContextProps | null>(null);

const onUpdateSpace = (): UserBackoffice | null => {
  const selectSpace = window?.localStorage?.getItem(SELECT_SPACE_KEY);
  if (selectSpace) {
    try {
      return JSON.parse(selectSpace);
    } catch (e) {
      return null;
    }
  }
  return null;
};

export const AuthProvider = memo(({ children }: PropsWithChildren) => {
  const [isLoginLoading, setIsLoginLoading] = useState(false);
  const [isUpdateSpaceLoading, setIsUpdateSpaceLoading] = useState(false);
  const [isInitialLoading, setIsInitialLoading] = useState(true);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [user, setUser] = useState<UserType | null>(null);
  const [activeSpace, setActiveSpace] = useState(onUpdateSpace);

  const [onLoginCms, { loading }] = useLoginMutation();
  const [onUpdateSpaceCms, { loading: isUpdateSpaceLoadingMutation }] =
    useUpdateSpaceMutation();

  const login = useCallback(
    async (firebaseToken: string) => {
      const pathname = window?.location?.pathname;
      setIsLoginLoading(true);
      try {
        const loginData = await onLoginCms({
          variables: { firebaseToken }
        });

        const data = loginData?.data as {
          authenticateBackofficeUserWithFirebase: UserAuthenticationWithPasswordSuccess;
        };

        const scope =
          data?.authenticateBackofficeUserWithFirebase?.scope?.filter?.(
            (item) => !item?.isBlocked
          ) || [];

        if (scope.length && data?.authenticateBackofficeUserWithFirebase) {
          window?.localStorage?.setItem(
            SESSION_KEY,
            data?.authenticateBackofficeUserWithFirebase?.sessionToken
          );

          setUser(data?.authenticateBackofficeUserWithFirebase);
          setIsAuthenticated(true);
          setIsInitialLoading(false);

          if (pathname.includes(AUTH_ROUTES.LOGIN) && scope.length > 1) {
            return (window.location.href = AUTH_ROUTES.SELECT_SPACE);
          } else if (scope.length === 1) {
            const space = scope[0];
            setActiveSpace(space);
            window?.localStorage?.setItem(
              SELECT_SPACE_KEY,
              JSON.stringify(space)
            );
          }

          if (pathname.includes(AUTH_ROUTES.LOGIN)) {
            return (window.location.href = DASHBOARD_ROUTES.DASHBOARD);
          }
          return null;
        }
      } catch (error) {
        console.log({ error });
        setIsAuthenticated(true);
        setIsInitialLoading(false);
        if (pathname === AUTH_ROUTES.LOGIN) {
          return (window.location.href = AUTH_ROUTES.SIGNUP);
        }
      } finally {
        setTimeout(() => {
          setIsLoginLoading(false);
        }, 1000);
      }
    },
    [onLoginCms]
  );

  const logout = useCallback(async () => {
    try {
      await auth.signOut();
    } catch (error) {
      console.log({ error });
    } finally {
      window?.localStorage?.removeItem(SESSION_KEY);
      window?.localStorage?.removeItem(SELECT_SPACE_KEY);
      setIsAuthenticated(false);
      setIsInitialLoading(false);
    }
  }, []);

  const onChangeSpace = useCallback((space: UserBackoffice | null) => {
    window?.localStorage?.setItem(SELECT_SPACE_KEY, JSON.stringify(space));
    setActiveSpace(space);
  }, []);

  const onEditSpace = useCallback(
    async ({ spaceId, data: payload }: UpdateSpaceMutationVariables) => {
      setIsUpdateSpaceLoading(true);
      try {
        const { data } = await onUpdateSpaceCms({
          variables: {
            spaceId: spaceId,
            data: payload
          }
        });
        if (data?.updateSpace) {
          const newSpace = data.updateSpace as NonNullable<
            UserBackoffice['space']
          >;

          setActiveSpace((prev) => {
            const newState = !prev ? prev : { ...prev, space: newSpace };
            window?.localStorage?.setItem(
              SELECT_SPACE_KEY,
              JSON.stringify(newState)
            );
            return newState;
          });
          setUser((prev) => {
            return !prev
              ? prev
              : {
                  ...prev,
                  scope: prev?.scope?.map((item) => {
                    if (item?.space?.id === newSpace?.id) {
                      return {
                        ...item,
                        space: newSpace
                      };
                    }
                    return item;
                  })
                };
          });

          toast.success('Espacio actualizado correctamente');
        } else {
          throw new Error('Error updating space');
        }
      } catch (error) {
        console.error(error);
        toast.error((error as Error)?.message ?? 'Error updating space');
      }
      setIsUpdateSpaceLoading(false);
    },
    [onUpdateSpaceCms]
  );

  return (
    <AuthContext.Provider
      value={{
        login,
        logout,
        user,
        activeSpace,
        onChangeSpace,
        onEditSpace,
        isLoginLoading,
        isAuthenticated,
        isInitialLoading,
        loadingCms: loading,
        loadingUpdateSpace:
          isUpdateSpaceLoading || isUpdateSpaceLoadingMutation,
        onEndInitialLoading: () => {
          setIsInitialLoading(false);
        }
      }}
    >
      {children}
    </AuthContext.Provider>
  );
});

export function useAuth() {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }

  return context;
}
