import { Avatar } from '@nextui-org/react';
import { useAuth } from '@/app/auth/_context';
import { cn } from '@/lib/utils.ts';

export default function AvatarSpace({
  className,
  ...props
}: {
  className?: string;
}) {
  const { activeSpace } = useAuth();

  return (
    <Avatar
      className={cn('h-[35px] w-[35px]', className)}
      {...props}
      src={activeSpace?.space?.thumbnail?.url}
      name={activeSpace?.space?.name as string}
    />
  );
}
