import { PropsWithChildren } from 'react';

import { cn } from '@/lib/utils';

type Size = 'xs' | 'sm' | 'md' | 'lg' | 'xl' | '2xl';

const sizes: Record<Size, string> = {
  xs: 'max-w-screen-xs',
  sm: 'max-w-screen-sm',
  md: 'max-w-screen-md',
  lg: 'max-w-screen-lg',
  xl: 'max-w-screen-xl',
  '2xl': 'max-w-screen-2xl'
};

export type ContainerProps = PropsWithChildren<{
  className?: string;
  size?: Size;
  id?: string;
}>;

export default function Container({
  children,
  id,
  size = '2xl',
  className = ''
}: ContainerProps) {
  return (
    <main
      id={id}
      className={cn(
        sizes?.[size],
        `mx-auto px-4 py-4 md:px-5 md:py-5`,
        className
      )}
    >
      {children}
    </main>
  );
}
