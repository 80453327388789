import { PrivateRotes } from '@/routes/private-rotes.ts';

export const COACHES_ROUTES = {
  HOME: PrivateRotes.COACHES,
  COACH_DETAILS(id: string) {
    return PrivateRotes.COACHES_DETAILS.replace(
      ':id',
      id
    );
  }
};
