import dayjs from 'dayjs';

import { formatDate } from '@/lib/utils.ts';
import Column, { ColumnProps } from './column.tsx';

const now = formatDate(new Date());

export type BoardProps = Pick<ColumnProps, 'events'> & {
  weekDays: dayjs.Dayjs[];
};

export default function Board({ weekDays, events }: BoardProps) {
  return (
    <div className="overflow-x-auto mt-5">
      <div className="flex flex-col gap-3 w-full md:flex-row md:min-h-[calc(100vh-320px)]">
        {weekDays.map((day) => {
          const format = day.format('dddd - DD');
          const isNow = formatDate(day) === now;

          const eventsDay = events.filter((event) => {
            return formatDate(event.startedAt) === formatDate(day);
          });

          return (
            <Column
              key={format}
              events={eventsDay}
              isNow={isNow}
              name={format}
            />
          );
        })}
      </div>
    </div>
  );
}
