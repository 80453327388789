import { useMutation } from '@apollo/client';
import {
  SIGNING_WITH_FIREBASE_MUTATION,
  UPDATE_SPACE_MUTATION,
  UPDATE_USER_MUTATION
} from '@/app/auth/_store/mutation/graphql/mutation.graphql';
import { UpdateFirebaseUidMutationVariables } from '@/__generated__/graphql.ts';

export function useLoginMutation() {
  return useMutation(SIGNING_WITH_FIREBASE_MUTATION);
}

export function useUpdateSpaceMutation() {
  return useMutation(UPDATE_SPACE_MUTATION);
}

export function useUpdateFirebaseUIDMutation() {
  const [updateUser, rest] = useMutation(UPDATE_USER_MUTATION);

  const onUpdateFirebaseUID = async (
    variables: UpdateFirebaseUidMutationVariables
  ) => {
    return updateUser({
      variables
    });
  };

  return {
    onUpdateFirebaseUID,
    ...rest
  };
}
