import { useAuth } from '@/app/auth/_context';
import { useQuery } from '@tanstack/react-query';
import { notificationApiClient } from '@/sdks/clients/notification-client.ts';
import { ServerQueryPagination } from '@/lib/hooks/usePagination.ts';

export const NOTIFICATION_KEYS = {
  BASE: 'notifications'
};

export function useGetNotificationList({
  pagination
}: {
  pagination: ServerQueryPagination;
}) {
  const { activeSpace } = useAuth();
  const spaceId = activeSpace?.space?.id as string;

  return useQuery({
    enabled: !!spaceId,
    queryKey: [NOTIFICATION_KEYS.BASE, spaceId, pagination],
    initialData: [],
    queryFn: () =>
      notificationApiClient.notifications.findAllBySpace({
        spaceId,
        ...pagination
      })
  });
}
