import './style.css';
import LoginForm from '../_components/login-form';
import { LoadingLogin } from './_loading.tsx';
import DesignLeft from '@/app/auth/_components/design-left/design-left';

export default function LoginPage() {
  return (
    <main className="w-full flex h-screen p-5 md:flex-row flex-col">
      <LoadingLogin />

      {/* Col 1 */}
      <DesignLeft />
      {/* EndCol 1 */}

      {/* Col 2 */}
      <div className="md:w-[50%] md:h-full md:pl-5 flex justify-center items-center py-10">
        <div className="text-left w-full lg:max-w-[400px]">
          <img src="/icon.png" width={120} className="mb-10 hidden md:block" />

          <LoginForm />
        </div>
      </div>
      {/* EndCol 2 */}
    </main>
  );
}
