import { useLocation } from '@/context/location-provider.tsx';
import { Button, Dropdown, DropdownItem, DropdownMenu, DropdownTrigger } from '@nextui-org/react';

export function ChangeLocale() {
  const { setLang, lang } = useLocation();

  return (
    <Dropdown>
      <DropdownTrigger>
        <Button isIconOnly>{lang.toUpperCase()}</Button>
      </DropdownTrigger>
      <DropdownMenu>
        <DropdownItem key="es" onClick={() => setLang('es')}>
          ES
        </DropdownItem>
        <DropdownItem key="en" onClick={() => setLang('en')}>
          EN
        </DropdownItem>
      </DropdownMenu>
    </Dropdown>
  );
}
