export enum SESSION_FREQUENCY_ENUM {
  EVERY_DAY = 'days',
  EVERY_WEEK = 'weeks'
}

export enum FinishEnum {
  NEVER = 'NEVER',
  DATE = 'DATE',
  COUNT = 'COUNT'
}

export enum ClassEnum {
  OPEN = 'open',
  NOT_CREATED = 'not_created',
  IN_PROGRESS = 'in_progress',
  FINISHED = 'finished',
  CANCELED = 'canceled'
}
