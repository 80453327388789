import Form, { FormItem } from 'reactivity-hook-form';
import Upload from '@/lib/ui/upload.tsx';
import GridColumn from '@/lib/ui/grid-column.tsx';
import Input from '@/lib/ui/input.tsx';
import EmailInputFetch from '@/lib/ui/email-input-fetch';
import { UserFragmentFragment } from '@/__generated__/graphql.ts';
import PaymentMethodSelect from '@/app/app/clients/components/PaymentMethodSelect';
import { ClientFormType } from '@/app/app/clients/components/NewOrEditClient/NewOrEditClient.tsx';
import { UseFormReturn } from 'react-hook-form';
import { useTranslation } from '@/hooks/useTranslation.ts';

export type FormUserType = Pick<
  ClientFormType,
  | 'id'
  | 'file'
  | 'email'
  | 'firstName'
  | 'lastName'
  | 'avatar'
  | 'user'
  | 'paymentPreference'
>;

export default function FormNewOrEditClient({
  id,
  onSubmit,
  formContext,
  isProfile
}: {
  id?: string;
  isProfile?: boolean;
  formContext: UseFormReturn<FormUserType>;
  onSubmit: (values: FormUserType) => void;
}) {
  const { t } = useTranslation();

  const onLinkUser = (user: UserFragmentFragment | null) => {
    if (!user) return formContext.setValue('user', undefined);
    formContext.setValue('user', user);
    formContext.setValue('id', user.id, { shouldValidate: true });
    formContext.setValue('email', user.email, { shouldValidate: true });
    formContext.setValue('firstName', user.firstName, { shouldValidate: true });
    formContext.setValue('lastName', user.lastName, { shouldValidate: true });
    formContext.setValue('file', user?.avatar?.url || null, {
      shouldValidate: true
    });
  };

  const user = formContext.watch();

  return (
    <Form<FormUserType>
      id={id}
      formContext={formContext}
      onSubmit={onSubmit}
      validations={{
        firstName: {
          required: `${t('form.first_name')} ${t('validations.is_required')}`
        },
        lastName: {
          required: `${t('form.last_name')} ${t('validations.is_required')}`
        },
        email: {
          required: `${t('form.email')} ${t('validations.is_required')}`,
          pattern: {
            value: /\S+@\S+\.\S+/,
            message: `${t('form.email')} ${t('validations.is_invalid')}`
          }
        }
      }}
    >
      <div className="flex justify-center items-center">
        <FormItem<ClientFormType> name="file">
          <Upload label={t('form.avatar')} />
        </FormItem>
      </div>

      <GridColumn spacing={4} xs={12} md={2}>
        <FormItem<ClientFormType> name="firstName">
          <Input
            label={t('form.first_name')}
            placeholder={t('form_placeholder.first_name')}
          />
        </FormItem>

        <FormItem<ClientFormType> name="lastName">
          <Input
            label={t('form.last_name')}
            placeholder={t('form_placeholder.last_name')}
          />
        </FormItem>
      </GridColumn>

      <GridColumn spacing={4} xs={2}>
        <FormItem<FormUserType> name="email">
          <EmailInputFetch
            removeOnEmpty
            onCleanUser={() => onLinkUser(null)}
            user={user as UserFragmentFragment}
            onLinkUser={onLinkUser}
            label={t('form.email')}
            placeholder={t('form_placeholder.email')}
          />
        </FormItem>
        {!isProfile && (
          <FormItem<FormUserType> name="paymentPreference">
            <PaymentMethodSelect label={t('form.payment_preference')} />
          </FormItem>
        )}
      </GridColumn>
    </Form>
  );
}
