import dayjs from 'dayjs';
import { useTranslation } from '@/hooks/useTranslation.ts';

export default function useGetDurationInDays() {
  const { t } = useTranslation();

  function renderUnit(
    value: number,
    unit: 'day' | 'hour' | 'minute' | 'second'
  ) {
    if (value <= 1) return `${value} ${t(`time_units.${unit}`)}`;
    return `${value} ${t(`time_units.${unit}s`)}`;
  }

  function getDurationInTwoDates(
    startAt?: dayjs.ConfigType,
    endAt?: dayjs.ConfigType
  ) {
    const date = dayjs(endAt);
    const days = date.diff(startAt, 'days');
    const hours = date.diff(startAt, 'hours');
    const minutes = date.diff(startAt, 'minutes');

    if (days >= 1) return renderUnit(days, 'day');
    if (hours >= 1) return renderUnit(hours, 'hour');
    if (minutes >= 1) return renderUnit(minutes, 'minute');

    return renderUnit(date.diff(startAt, 'seconds'), 'second');
  }

  return {
    getDurationInTwoDates
  };
}
