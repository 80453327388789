import {
  Button,
  Card,
  CardBody,
  CardFooter,
  Chip,
  Tooltip
} from '@nextui-org/react';
import { PlanFragmentFragment } from '@/__generated__/graphql.ts';
import Text from '@/lib/ui/text.tsx';
import { formatMoney } from '@/lib/utils.ts';
import { useTranslation } from '@/hooks/useTranslation.ts';

export default function PlanCard({
  status,
  name,
  points,
  price,
  duration,
  creditCount,
  categoriesCount,
  categories,
  onEdit
}: PlanFragmentFragment & { onEdit?: () => void }) {
  const { t } = useTranslation();

  return (
    <Card className="p-4 relative bg-default-50 dark:bg-default">
      <div>
        <Chip variant="flat" color="success" size="sm" radius="sm">
          {t(`plan_status.${status}` as LanguageKeys)}
        </Chip>
      </div>

      <CardBody className="gap-2">
        <Text className="text-xl text-center">{name}</Text>
        <Text className="text-4xl text-center">{formatMoney(price ?? '')}</Text>

        <div className="mt-10 mb-5 flex flex-col gap-2">
          <Text className="text-default-500">
            {duration} {t((duration ?? 0) > 1 ? '_days' : 'day')}
          </Text>
          <Text className="text-default-500">
            {points} {t((points ?? 0) > 1 ? 'classes' : 'class')}
          </Text>
          <Tooltip
            content={
              <div>
                {categories?.map?.((item) => (
                  <Text key={item.name}>{item.name}</Text>
                ))}
              </div>
            }
          >
            <Text className="text-default-500">
              {categoriesCount} {t('settings.categories')}
            </Text>
          </Tooltip>

          <Text className="text-default-500">
            {creditCount} {t('currently_subscription')}
          </Text>
        </div>
      </CardBody>
      <CardFooter className="flex-row justify-center items-center">
        <Button onClick={onEdit} color="primary" variant="light">
          {t('edit')}
        </Button>
      </CardFooter>
    </Card>
  );
}
