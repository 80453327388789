export enum PrivateRotes {
  APP = '/app/',
  DASHBOARD = '/app/dashboard',
  SESSIONS = '/app/sessions',
  SESSION_DETAILS = '/app/sessions/:id',
  COACHES = '/app/coaches',
  COACHES_DETAILS = '/app/coaches/:id',
  CLIENTS = '/app/clients',
  NOTIFICATIONS = '/app/notifications',
  NEW_NOTIFICATIONS = '/app/notifications/new',
  CLIENTS_DETAILS = '/app/clients/:id',
  SETTINGS = '/app/settings/:tab',
  SETTINGS_STRIPE_RETURN = '/app/settings/stripe/:connectedAccountId/return',
  SETTINGS_STRIPE_REFRESH = '/app/settings/stripe/:connectedAccountId/refresh',
  PROFILE = '/app/profile'
}
