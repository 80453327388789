import { NewSessionFormType } from '@/app/app/sessions/common/types.ts';
import {
  createStartOrEndDate,
  isOnlyOneSession
} from '@/app/app/sessions/common/helper.ts';
import {
  CreateSessionMutationVariables,
  Maybe,
  SessionFragmentFragment,
  Space,
  UpdateAllSessionForEventBackofficeMutationVariables
} from '@/__generated__/graphql.ts';

export default class SessionRequestMapper {
  constructor(
    private readonly rawData: NewSessionFormType,
    private readonly activeSpace?: Maybe<Space>
  ) {}

  toRequest(): CreateSessionMutationVariables {
    if (!this.activeSpace) {
      throw new Error('activeSpace cannon be undefined, please check the data');
    }

    if (isOnlyOneSession(this.rawData)) {
      return {
        data: {
          cost: 1,
          name: this.rawData.name,
          description: this.rawData?.summary,
          color: this.rawData?.color,
          limitQuote: Number(this.rawData.limitQuote),
          startedAt: createStartOrEndDate(
            this.rawData.date,
            this.rawData.startTime
          ),
          finishedAt: createStartOrEndDate(
            this.rawData.date,
            this.rawData.finishTime
          ),
          space: {
            connect: {
              id: this?.activeSpace?.id as string
            }
          },
          coach: {
            connect: {
              id: this.rawData.coach
            }
          },
          categories: {
            connect:
              this.rawData.categories?.map?.((category) => ({
                id: category
              })) || []
          }
        }
      };
    }
    throw new Error(
      'Invalid data, please check the data. isRepeatEvent = false'
    );
  }

  toEditRequest(
    session: SessionFragmentFragment
  ): UpdateAllSessionForEventBackofficeMutationVariables {
    const { name, summary, color, limitQuote, startTime, finishTime, coach } =
      this.rawData;

    return {
      id: session.id,
      data: {
        name: name !== session?.name ? name : undefined,
        description: summary !== session?.description ? summary : undefined,
        color: color !== session?.color ? color : undefined,
        limitQuote:
          limitQuote !== session?.limitQuote ? Number(limitQuote) : undefined,
        startedAt:
          startTime !== session?.startedAt
            ? createStartOrEndDate(session.startedAt, startTime)
            : undefined,
        finishedAt:
          finishTime !== session?.finishedAt
            ? createStartOrEndDate(session.finishedAt, finishTime)
            : undefined,
        coach:
          coach !== session?.coach?.id
            ? {
                connect: {
                  id: coach
                }
              }
            : undefined,
        cost: 1,
        categories: {
          set:
            this.rawData.categories?.map?.((category) => ({
              id: category
            })) || []
        }
      }
    };
  }
}
