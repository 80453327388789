import { toast } from 'sonner';
import dayjs from 'dayjs';
import { useMutation } from '@apollo/client';

import {
  CREATE_SESSION_SUBSCRIPTION,
  DELETE_SESSION_SUBSCRIPTION,
  UPDATE_SESSION_SUBSCRIPTION
} from '@/app/app/sessions/store/mutation/graphql/subscription.graphql';
import { GET_ALL_SESSIONS } from '@/app/app/sessions/store/query/graphql/session.graphql';
import { useCalendar } from '@/context/calendar-provider.tsx';
import { useAuth } from '@/app/auth/_context';
import { TODAY_SESSIONS_QUERY } from '@/app/app/dashboard/store/query/graphql/dashboard.graphql.ts';
import {
  CreateSessionSubscriptionMutationVariables,
  DeleteSessionSubscriptionMutationVariables,
  UpdateSessionSubscriptionMutationVariables
} from '@/__generated__/graphql.ts';
import { useTranslation } from '@/hooks/useTranslation.ts';

export function useCreateSubscription() {
  const now = dayjs().utc().endOf('day');
  const { activeSpace } = useAuth();
  const { t } = useTranslation();
  const { time, date } = useCalendar();

  const [onFunction, restProps] = useMutation(CREATE_SESSION_SUBSCRIPTION, {
    awaitRefetchQueries: true,
    onCompleted: ({ createSubscriptionForSession }) => {
      if (!createSubscriptionForSession) {
        toast.error(t('errors_message.create_booking'), {
          description: t('errors_message.unexpected_error')
        });
      } else {
        toast.success(t('operation_successfully'));
      }
    },
    onError: (error) => {
      toast.error(t('errors_message.create_booking'), {
        description: error?.message
          ? t(
              'errors_message.original_error',
              `Original error: ${error?.message}`,
              {
                error: error?.message
              }
            )
          : t('errors_message.unexpected_error')
      });
    },
    refetchQueries: [
      // schedule view
      {
        query: GET_ALL_SESSIONS,
        variables: {
          day: date,
          time,
          spaceId: activeSpace?.space?.id
        }
      },
      // dashboard view
      {
        query: TODAY_SESSIONS_QUERY,
        variables: {
          day: now.toISOString(),
          spaceId: activeSpace?.space?.id
        }
      }
    ]
  });

  const onCreateSubscription = async (
    data: CreateSessionSubscriptionMutationVariables['data']
  ) => {
    return onFunction({
      variables: {
        data
      }
    });
  };

  return {
    onCreateSubscription,
    ...restProps
  };
}

export function useUpdateSubscriptions() {
  const now = dayjs().utc().endOf('day');
  const { activeSpace } = useAuth();
  const { t } = useTranslation();
  const { time, date } = useCalendar();

  const [onFunction, restProps] = useMutation(UPDATE_SESSION_SUBSCRIPTION, {
    awaitRefetchQueries: true,
    onCompleted: ({ updateSessionSubscriptions }) => {
      if (!updateSessionSubscriptions) {
        toast.error(t('errors_message.update_booking'), {
          description: t('errors_message.unexpected_error')
        });
      } else {
        toast.success(t('operation_successfully'));
      }
    },
    onError: (error) => {
      toast.error(t('errors_message.update_booking'), {
        description: error?.message
          ? t(
              'errors_message.original_error',
              `Original error: ${error?.message}`,
              {
                error: error?.message
              }
            )
          : t('errors_message.unexpected_error')
      });
    },
    refetchQueries: [
      // schedule view
      {
        query: GET_ALL_SESSIONS,
        variables: {
          day: date,
          time,
          spaceId: activeSpace?.space?.id
        }
      },
      // dashboard view
      {
        query: TODAY_SESSIONS_QUERY,
        variables: {
          day: now.toISOString(),
          spaceId: activeSpace?.space?.id
        }
      }
    ]
  });

  const onUpdateSubscription = async (
    data: UpdateSessionSubscriptionMutationVariables['data']
  ) => {
    return onFunction({
      variables: {
        data
      }
    });
  };

  return {
    ...restProps,
    // isLoading: restProps?.loading || restProps.client.
    onUpdateSubscription
  };
}

export function useCancelSubscription() {
  const { activeSpace } = useAuth();
  const { t } = useTranslation();
  const { time, date } = useCalendar();

  const [onFunction, restProps] = useMutation(DELETE_SESSION_SUBSCRIPTION, {
    awaitRefetchQueries: true,
    onCompleted: ({ deleteSubscriptionForSession }) => {
      if (!deleteSubscriptionForSession) {
        toast.error(t('errors_message.delete_booking'), {
          description: t('errors_message.unexpected_error')
        });
      } else {
        toast.success(t('operation_successfully'));
      }
    },
    onError: (error) => {
      toast.error(t('errors_message.delete_booking'), {
        description: error?.message
          ? t(
              'errors_message.original_error',
              `Original error: ${error?.message}`,
              {
                error: error?.message
              }
            )
          : t('errors_message.unexpected_error')
      });
    },
    refetchQueries: [
      // schedule view
      {
        query: GET_ALL_SESSIONS,
        variables: {
          day: date,
          time,
          spaceId: activeSpace?.space?.id
        }
      },
      // dashboard view
      {
        query: TODAY_SESSIONS_QUERY,
        variables: {
          day: date,
          time,
          spaceId: activeSpace?.space?.id
        }
      }
    ]
  });

  const onCancelSubscription = async (
    data: DeleteSessionSubscriptionMutationVariables
  ) => {
    return onFunction({
      variables: {
        sessionId: data.sessionId,
        userId: data.userId
      }
    });
  };

  return {
    onCancelSubscription,
    ...restProps
  };
}
