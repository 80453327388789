import { ReactNode, useEffect, useState } from 'react';
import { cn } from '@/lib/utils';

type ToggleButtonProps<TValue = string> = {
  label: ReactNode;
  value: TValue;
  variant?: 'toggle' | 'radio';
  checked?: boolean;
  onChange?: (value: TValue) => void;
};

function Toggle<TValue = string>({
  label,
  value,
  checked,
  onChange
}: ToggleButtonProps<TValue>) {
  return (
    <label className={cn(`inline-flex items-center cursor-pointer`)}>
      <input
        type="radio"
        checked={checked}
        value={value as string}
        className={cn('hidden')}
        onChange={(event) => onChange?.(event?.target?.value as TValue)}
      />
      <span
        className={cn(
          'border-2 px-4 py-2 rounded-md',
          checked ? 'border-brand bg-background' : 'border-transparent'
        )}
      >
        {label}
      </span>
    </label>
  );
}

export default function ToggleButton<TValue = string>({
  options,
  onChange,
  className,
  value: currentValue
}: {
  className?: string;
  value?: ToggleButtonProps<TValue>['value'];
  onChange?: ToggleButtonProps<TValue>['onChange'];
  options: ToggleButtonProps<TValue>[];
}) {
  const [internalValue, setInternalValue] = useState(currentValue);

  useEffect(() => {
    setInternalValue(currentValue);
  }, [currentValue]);

  return (
    <div
      className={cn(
        'flex-row gap-4',
        'bg-gray-100 dark:bg-neutral-800 rounded-md overflow-hidden',
        className
      )}
    >
      {options.map(({ value, label }) => (
        <Toggle
          key={value as string}
          label={label}
          value={value}
          checked={value === internalValue}
          onChange={() => {
            setInternalValue(value);
            onChange?.(value);
          }}
        />
      ))}
    </div>
  );
}
