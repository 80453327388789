import { toast } from 'sonner';
import { useMutation } from '@apollo/client';

import { useAuth } from '@/app/auth/_context';
import {
  POST_CREATE_BADGE,
  POST_CREATE_BADGE_ITEM,
  POST_DELETE_BADGE,
  POST_DELETE_BADGE_ITEM,
  POST_UPDATE_BADGE,
  POST_UPDATE_BADGE_ITEM
} from '@/app/app/settings/store/mutation/graphql/mutations.graphql.ts';
import {
  BadgeFragmentFragment,
  BadgeItemCreateInput,
  BadgeWhereUniqueInput,
  InputMaybe,
  PostCreateBadgeMutationVariables,
  PostDeleteBadgeMutationVariables,
  PostUpdateBadgeItemMutationVariables,
  PostUpdateBadgeMutationVariables
} from '@/__generated__/graphql.ts';
import { BADGE_BASIC_FRAGMENT } from '@/graphql/fragment.ts';
import { GET_BADGE_BY_SPACE } from '@/app/app/settings/store/query/graphql/queries.graphql.ts';

export type NewBadgePayload = Pick<
  PostCreateBadgeMutationVariables['data'],
  'name'
>;

export type NewBadgeItemPayload = {
  badge: {
    connect?: InputMaybe<BadgeWhereUniqueInput>;
  };
  name: string;
  color: string;
  icon?: string;
};

function getReadBadgeCache(
  cache: any = [],
  records: any,
  readField: any
): BadgeFragmentFragment[] {
  return records.map((ref: any) => {
    const badgeId = readField('id', ref);
    if (!badgeId) return null;

    return cache.readFragment({
      id: `Badge:${badgeId.toString()}`,
      fragment: BADGE_BASIC_FRAGMENT
    }) as BadgeFragmentFragment[];
  });
}

export function useCreateBadge() {
  const { activeSpace } = useAuth();
  const [mutation, actionMutation] = useMutation(POST_CREATE_BADGE, {
    update(cache, { data }) {
      const createBadge = data?.createBadge;
      if (createBadge) {
        cache.modify({
          fields: {
            badges(existingBadges = []) {
              return [...existingBadges, createBadge];
            }
          }
        });
      }
    }
  });

  const onCreateBadge = async (variables: NewBadgePayload) => {
    try {
      await mutation({
        variables: {
          data: {
            ...variables,
            space: { connect: { id: activeSpace?.space?.id } }
          }
        }
      });
      toast.success('Badge created');
    } catch (error) {
      toast.error((error as Error)?.message || 'Error creating badge');
      console.error(error);
      throw error;
    }
  };

  return { onCreateBadge, ...actionMutation };
}

export function useUpdateBadge() {
  const [mutation, actionMutation] = useMutation(POST_UPDATE_BADGE, {
    update(cache, { data }) {
      const updateBadge = data?.updateBadge as BadgeFragmentFragment;
      if (updateBadge) {
        cache.modify({
          fields: {
            badges(_existingBadges = [], { readField }) {
              const existingBadges = getReadBadgeCache(
                cache,
                _existingBadges,
                readField
              );

              const findIndex = (
                existingBadges as BadgeFragmentFragment[]
              ).findIndex((item) => item.id === updateBadge?.id);

              if (findIndex === -1) return existingBadges;
              const copyArray = [...existingBadges] as BadgeFragmentFragment[];
              copyArray[findIndex] = updateBadge;
              return copyArray;
            }
          }
        });
      }
    }
  });

  const onUpdateBadge = async (variables: PostUpdateBadgeMutationVariables) => {
    try {
      await mutation({
        variables
      });
      toast.success('Badge updated');
    } catch (error) {
      toast.error((error as Error)?.message || 'Error updating badge');
      console.error(error);
      throw error;
    }
  };

  return { onUpdateBadge, ...actionMutation };
}

export function useDeleteBadge() {
  const [mutation, actionMutation] = useMutation(POST_DELETE_BADGE, {
    update(cache, { data }) {
      const deleteBadge = data?.deleteBadge as BadgeFragmentFragment;
      if (deleteBadge) {
        cache.modify({
          fields: {
            badges(_existingBadges = [], { readField }) {
              const existingBadges = getReadBadgeCache(
                cache,
                _existingBadges,
                readField
              );

              return existingBadges?.filter?.(
                (item) => item.id !== deleteBadge?.id
              );
            }
          }
        });
      }
    }
  });

  const onDeleteBadge = async (variables: PostDeleteBadgeMutationVariables) => {
    try {
      await mutation({
        variables
      });
      toast.success('Badge deleted');
    } catch (error) {
      toast.error((error as Error)?.message || 'Error deleting badge');
      console.error(error);
      throw error;
    }
  };

  return { onDeleteBadge, ...actionMutation };
}

export function useCreateBadgeItem() {
  const { activeSpace } = useAuth();

  const [mutation, actionMutation] = useMutation(POST_CREATE_BADGE_ITEM, {
    awaitRefetchQueries: true,
    refetchQueries: [
      {
        query: GET_BADGE_BY_SPACE,
        variables: {
          spaceId: activeSpace?.space?.id as string
        }
      }
    ]
  });

  const onCreateBadgeItem = async (variables: BadgeItemCreateInput) => {
    try {
      await mutation({
        variables: {
          data: {
            ...variables
          }
        }
      });
      toast.success('Badge item created');
    } catch (error) {
      toast.error((error as Error)?.message || 'Error creating badge item');
      console.error(error);
      throw error;
    }
  };

  return { onCreateBadgeItem, ...actionMutation };
}

export function useUpdateBadgeItem() {
  const { activeSpace } = useAuth();

  const [mutation, actionMutation] = useMutation(POST_UPDATE_BADGE_ITEM, {
    awaitRefetchQueries: true,
    refetchQueries: [
      {
        query: GET_BADGE_BY_SPACE,
        variables: {
          spaceId: activeSpace?.space?.id as string
        }
      }
    ]
  });

  const onUpdateBadgeItem = async (
    variables: PostUpdateBadgeItemMutationVariables
  ) => {
    try {
      await mutation({
        variables
      });
      toast.success('Badge item updated');
    } catch (error) {
      toast.error((error as Error)?.message || 'Error updating badge item');
      console.error(error);
      throw error;
    }
  };

  return { onUpdateBadgeItem, ...actionMutation };
}

export function useDeleteBadgeItem() {
  const { activeSpace } = useAuth();

  const [mutation, actionMutation] = useMutation(POST_DELETE_BADGE_ITEM, {
    awaitRefetchQueries: true,
    refetchQueries: [
      {
        query: GET_BADGE_BY_SPACE,
        variables: {
          spaceId: activeSpace?.space?.id as string
        }
      }
    ]
  });

  const onDeleteBadgeItem = async (
    variables: PostDeleteBadgeMutationVariables
  ) => {
    try {
      await mutation({
        variables
      });
      toast.success('Badge item deleted');
    } catch (error) {
      toast.error((error as Error)?.message || 'Error deleting badge item');
      console.error(error);
      throw error;
    }
  };

  return { onDeleteBadgeItem, ...actionMutation };
}
