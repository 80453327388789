import { useState } from 'react';
import { UseToggleActions } from '@/hooks/useToggle.ts';

type UseSelectEditItem<T> = [T | null, (value: T | null, open?: boolean) => void];

export default function useSelectEditItem<T>(
  actions?: UseToggleActions
): UseSelectEditItem<T> {
  const [item, setItem] = useState<T | null>(null);

  const onSelectItem = (value: T | null, open?: boolean) => {
    setItem(value);
    value || open ? actions?.onVisible?.() : actions?.onHidden?.();
  };

  return [item, onSelectItem];
}
