import { gql } from '@/__generated__/gql.ts';

export const CREATE_COACHES_QUERY = gql(`
  mutation CreateCoach($data: CoachCreateInput!) {
    createCoach(data: $data) {
      ...CoachFragment
    }
  }
`);

export const UPDATE_COACH_QUERY = gql(`
  mutation updateCoachById($coachID: ID!, $data: CoachUpdateInput!) {
    updateCoach(where: { id: $coachID }, data: $data) {
      ...CoachFragment
    }
  }
`);

export const CHANGE_STATUS_COACH_QUERY = gql(`
  mutation UpdateCoach($coachID: ID!, $data: CoachUpdateInput!) {
    updateCoach(where: { id: $coachID }, data: $data) {
      id
      status
    }
  }
`);
