import { BreadcrumbItem, Breadcrumbs } from '@nextui-org/react';
import { Link, useLocation } from 'react-router-dom';
import { useMemo } from 'react';

export default function BreadcrumbsApp() {
  const { pathname } = useLocation();

  const paths = useMemo(() => {
    const pathInArray = pathname.split('/').filter(Boolean);
    if (!pathInArray.length) return [];

    return pathInArray.map((path, index) => {
      const url = `/${pathInArray.slice(0, index + 1).join('/')}`;
      return {
        path,
        key: index,
        url
      };
    });
  }, [pathname]);

  return (
    <Breadcrumbs variant="solid">
      {paths.map(({ path, key, url }) => (
        <BreadcrumbItem key={key}>
          <Link to={url}>{path}</Link>
        </BreadcrumbItem>
      ))}
    </Breadcrumbs>
  );
}
