import { useNavigate, useParams } from 'react-router-dom';
import { useEffect } from 'react';
import {
  useCompleteStripeAccount,
  useVerifyStripeAccount
} from '@/app/app/settings/store/mutation/stripe.mutation.ts';
import Text from '@/lib/ui/text.tsx';
import Container from '@/lib/ui/container.tsx';
import {
  SETTINGS_ROUTES,
  SETTINGS_TAB_ENUM
} from '@/app/app/settings/common/routes.ts';
import { useAuth } from '@/app/auth/_context';
import Loading from '@/lib/ui/loading.tsx';

/*
  Stripe redirige a esta URL cuando el usuario completa el flujo de onboarding de Connect. Esto no implica que se haya recopilado toda la información o que no haya requisitos pendientes en la cuenta. Simplemente significa que se ingresó y se salió del flujo correctamente.

  No state is passed through this URL. After redirecting a user to your return_url, check the state of the details_submitted parameter on their account by doing either of the following:

  Escuchando los webhooks account.updated
  Llamando a la API Accounts e inspeccionando el objeto devuelto

  https://docs.stripe.com/connect/standard-accounts
*/
export default function ReturnPage() {
  const { activeSpace } = useAuth();
  const navigate = useNavigate();
  const { connectedAccountId } = useParams();

  const paymentProvider = activeSpace?.space?.paymentsProvider?.find?.(
    (item) => item?.status === 'pending' && item?.provider === 'stripe'
  );

  const completeProviderMutation = useCompleteStripeAccount();
  const { onVerifyAccount, loading, data } = useVerifyStripeAccount();

  useEffect(() => {
    if (connectedAccountId && paymentProvider?.id) {
      onVerifyAccount(connectedAccountId).then(async (data) => {
        if (data?.details_submitted && data?.charges_enabled) {
          await completeProviderMutation.onMutation(paymentProvider?.id);
          return navigate(SETTINGS_ROUTES.TAB(SETTINGS_TAB_ENUM.stripe));
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [connectedAccountId]);

  useEffect(() => {
    if (
      !loading &&
      !data?.verifyStripeAccount?.charges_enabled &&
      !data?.verifyStripeAccount?.details_submitted
    ) {
      navigate(SETTINGS_ROUTES.TAB(SETTINGS_TAB_ENUM.stripe));
    }
  }, [
    data?.verifyStripeAccount?.charges_enabled,
    data?.verifyStripeAccount?.details_submitted,
    loading,
    navigate
  ]);

  if (completeProviderMutation?.error) {
    return (
      <Container>
        <Text>Error al completar la cuenta</Text>
      </Container>
    );
  }

  return (
    <Container>
      <Loading />
    </Container>
  );
}
