import { Avatar, Chip } from '@nextui-org/react';
import Text from '@/lib/ui/text.tsx';
import { Table, TableColumn, TableProps } from '@/lib/ui/table.tsx';
import { SessionFragmentFragment } from '@/__generated__/graphql.ts';
import { formatDate, formatMoney } from '@/lib/utils.ts';
import { useTranslation } from '@/hooks/useTranslation.ts';
import useGetDurationInDays from '@/hooks/useGetDurationInDays.ts';
import useSessionStatus from '@/hooks/useSessionStatus.ts';

export type SessionForShow = { sessionForSubscribe: SessionFragmentFragment };

const useColumns = (): TableColumn<SessionForShow> => {
  const { t } = useTranslation();
  const getStatus = useSessionStatus();
  const { getDurationInTwoDates } = useGetDurationInDays();

  return [
    {
      field: 'name',
      headerName: t('name'),
      renderCell({ row }) {
        return row?.sessionForSubscribe?.name ?? '';
      }
    },
    {
      field: 'coach',
      headerName: t('form.coach'),
      renderCell({ row }) {
        return (
          <div className="flex gap-2 items-center">
            <Avatar
              size="sm"
              src={row?.sessionForSubscribe?.coach?.avatar?.url}
            />
            <Text>{row?.sessionForSubscribe?.coach?.fullName}</Text>
          </div>
        );
      }
    },
    {
      field: 'subscriptionsCount',
      headerName: t('assistant'),
      renderCell({ row }) {
        return formatMoney(
          row?.sessionForSubscribe?.subscriptionsCount as number,
          { symbol: 'none' }
        );
      }
    },
    {
      field: 'date',
      headerName: t('date'),
      renderCell({ row }) {
        return formatDate(row?.sessionForSubscribe?.finishedAt);
      }
    },
    {
      field: 'duration',
      headerName: t('duration'),
      renderCell({ row }) {
        return getDurationInTwoDates(
          row?.sessionForSubscribe?.startedAt,
          row?.sessionForSubscribe?.finishedAt
        );
      }
    },
    {
      field: 'status',
      headerName: t('status'),
      renderCell({ row }) {
        const status = getStatus.get(row?.sessionForSubscribe?.status);

        return (
          <Chip color={status?.color} size="sm" variant="flat">
            {status?.label}
          </Chip>
        );
      }
    }
  ];
};

export default function ListOfClasses({
  data,
  pagination,
  isLoading,
  ...props
}: CommonComponentProps & {
  data?: SessionForShow[];
  isLoading?: boolean;
  pagination?: TableProps<any>['pagination'];
}) {
  const { t } = useTranslation();
  const columns = useColumns();

  return (
    <div {...props}>
      <Text className="text-3xl font-bold mb-4">{t('classes')}:</Text>
      <Table
        rows={data ?? []}
        columns={columns}
        isLoading={isLoading}
        pagination={pagination}
      />
    </div>
  );
}
