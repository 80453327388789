import { gql } from '@/__generated__';

export const GET_PLANS_BY_SPACE = gql(`
  query GetPlansBySpace($spaceId: ID!) {
     plans(where: { space: { id: { equals: $spaceId } } }, orderBy: [{ points: asc }]) {
        ...PlanFragment
      }
  }
`);

export const GET_SESSION_CATEGORIES_BY_SPACE = gql(`
  query GetSessionsCategoriesBySpace($spaceId: ID!) {
     sessionCategories(where: { space: { id: { equals: $spaceId } } }, orderBy: [{ name: asc }]) {
        id
        name
        status
        plansCount
      }
  }
`);

export const GET_BADGE_BY_SPACE = gql(`
  query GetBadgesBySpace($spaceId: ID!) {
     badges(where: { space: { id: { equals: $spaceId } } }) {
        ...BadgeFragment
      }
  }
`);

export const GET_USERS_ADMIN_BY_SPACE = gql(`
  query UserBackoffices($spaceId: ID!) {
    userBackoffices(where: { space: { id: { equals: $spaceId } } }) {
      ...UserBackofficeFragment  
    }
  }
`);
