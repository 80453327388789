import dayjs from 'dayjs';

import { FormValidations } from 'reactivity-hook-form';
import { useTranslation } from '@/hooks/useTranslation';
import { NewSessionFormType } from '@/app/app/sessions/common/types';
import {
  FinishEnum,
  SESSION_FREQUENCY_ENUM
} from '@/app/app/sessions/common/enum.ts';

export const useValidations = (): FormValidations<NewSessionFormType> => {
  const { t } = useTranslation();
  return {
    name: {
      required: `${t('form.name')} ${t('validations.is_required')}`
    },
    coach: {
      required: `${t('form.coach')} ${t('validations.is_required')}`
    },
    date: {
      required: `${t('form.date')} ${t('validations.is_required')}`
    },
    startTime: {
      required: `${t('form.start_time')} ${t('validations.is_required')}`,
      validate(value, values: NewSessionFormType) {
        const startTime = value ? dayjs(value as string) : null;
        const finishTime = values?.finishTime ? dayjs(values.finishTime) : null;

        if (startTime?.isValid?.() && finishTime?.isValid?.()) {
          if (startTime.isSameOrAfter(finishTime)) {
            return t('validations.valid_start_date');
          }
        }

        return undefined;
      }
    },
    finishTime: {
      required: `${t('form.end_time')} ${t('validations.is_required')}`,
      validate(value, values: NewSessionFormType) {
        const startTime = values?.startTime ? dayjs(values.startTime) : null;
        const finishTime = value ? dayjs(value as string) : null;

        if (startTime?.isValid?.() && finishTime?.isValid?.()) {
          if (finishTime.isSameOrBefore(startTime)) {
            return t('validations.valid_finish_date');
          }
        }

        return undefined;
      }
    },
    limitQuote: {
      required: `${t('form.limitQuote')} ${t('validations.is_required')}`
    },
    // @ts-ignore
    'repeatSettings.repeat': {
      required: `${t('form.repeat')} ${t('validations.is_required')}`
    },
    'repeatSettings.finishedRepeatAt': {
      validate(value: string, values: NewSessionFormType) {
        const frequency = values?.repeatSettings?.frequency;
        const finishType = values?.repeatSettings?.finishType;

        if (finishType === FinishEnum.DATE && !value) {
          return `${t('validations.is_required')}`;
        } else if (
          finishType === FinishEnum.DATE &&
          value &&
          frequency === SESSION_FREQUENCY_ENUM.EVERY_WEEK
        ) {
          const daysRepeat = values?.repeatSettings?.daysRepeat;

          const day = dayjs(value).format('dddd');
          if (
            !daysRepeat
              ?.map((item) => item.toLowerCase())
              ?.includes(day.toLowerCase())
          ) {
            return `${t('validations.valid_day_in_week')}`;
          }
        }

        return undefined;
      }
    },
    'repeatSettings.finishedRepeatAfter': {
      validate(value: string, values: NewSessionFormType) {
        const finishType = values.repeatSettings?.finishType;
        if (finishType === FinishEnum.COUNT) {
          if (!value) {
            return `${t('validations.is_required')}`;
          }

          if (
            (!isNaN(Number(value)) && Number(value) < 1) ||
            !Number.isInteger(Number(value))
          ) {
            return t('validations.valid_value');
          }
        }
        return undefined;
      }
    },
    'repeatSettings.repeatEveryNumber': {
      required: `${t('form.repeat_every_number')} ${t('validations.is_required')}`,
      validate(value: string) {
        if (!value) {
          return `${t('form.repeat_every_number')} ${t('validations.is_required')}`;
        }

        if (
          (!isNaN(Number(value)) && Number(value) < 1) ||
          !Number.isInteger(Number(value))
        ) {
          return t('validations.valid_value');
        }

        return undefined;
      }
    }
  };
};
