import Form, { FormItem } from 'reactivity-hook-form';

import Input from '@/lib/ui/input.tsx';
import Modal from '@/lib/ui/modal.tsx';
import {
  ClassOfferedCreateInput,
  ClassOfferedFragmentFragment
} from '@/__generated__/graphql.ts';
import {
  useCreateClass,
  useUpdateClass
} from '@/app/app/settings/store/mutation/class.mutation.ts';
import { Textarea } from '@/lib/ui/textarea.tsx';
import WeekDays from '@/lib/ui/week-days.tsx';
import { TimePicker } from '@/lib/ui/time-picker.tsx';
import Upload from '@/lib/ui/upload.tsx';
import { useTranslation } from '@/hooks/useTranslation.ts';

const FORM_NAME = 'new-class-form';

type FormValues = Pick<
  ClassOfferedCreateInput,
  'name' | 'short_summary' | 'startTime' | 'endTime'
> & {
  days: Array<string>;
  image: File | string | null;
};

export default function NewOrUpdateClassModal({
  item,
  open,
  onClose
}: {
  item?: ClassOfferedFragmentFragment | null;
  open: boolean;
  onClose: () => void;
}) {
  const { t } = useTranslation();
  const { onCreateClass, loading: cLoading } = useCreateClass();
  const { onUpdateClass, loading: uLoading } = useUpdateClass();

  const loading = cLoading || uLoading;

  const onSubmit = async (_payload: FormValues) => {
    const image =
      _payload.image instanceof File ? { upload: _payload.image } : undefined;

    const payload = {
      image,
      days: _payload?.days?.join?.(',') || '',
      name: _payload.name,
      short_summary: _payload.short_summary,
      startTime: _payload.startTime,
      endTime: _payload.endTime
    };
    console.log({ item, payload });
    if (item) {
      await onUpdateClass(item.id, payload);
    } else {
      await onCreateClass(payload);
    }
    // onClose();
  };

  return (
    <Modal
      isOpen={open}
      onClose={onClose}
      maskClosable={false}
      title={t(item ? 'settings.update_class' : 'settings.new_class')}
      actions={[
        {
          label: t('cancel'),
          color: 'danger',
          variant: 'light',
          onClick: onClose
        },
        {
          label: t('save'),
          color: 'primary',
          form: FORM_NAME,
          type: 'submit',
          isLoading: loading
        }
      ]}
    >
      <Form<FormValues>
        id={FORM_NAME}
        key={item?.id || 'default'}
        onSubmit={onSubmit}
        defaultValues={{
          ...item,
          days: item?.days?.split?.(','),
          image: item?.image?.url ?? null
        }}
        validations={{
          name: {
            required: t('validations.required')
          },
          short_summary: {
            required: t('validations.required')
          },
          days: {
            required: t('validations.required')
          },
          startTime: {
            required: t('validations.required')
          },
          endTime: {
            required: t('validations.required')
          }
        }}
      >
        <FormItem<FormValues>
          name="image"
          className="flex flex-row justify-center"
        >
          <Upload className="mx-auto block" />
        </FormItem>
        <FormItem<FormValues> name="name">
          <Input label={t('form.class_name')} />
        </FormItem>
        <FormItem<FormValues> name="short_summary">
          <Textarea label={t('form.short_summary')} />
        </FormItem>
        <FormItem<FormValues> name="startTime">
          <TimePicker label={t('form.start_time')} />
        </FormItem>
        <FormItem<FormValues> name="endTime">
          <TimePicker label={t('form.end_time')} />
        </FormItem>
        <FormItem<FormValues> name="days">
          <WeekDays label={t('_days')} />
        </FormItem>
      </Form>
    </Modal>
  );
}
