import { getCountries } from 'node-countries';
import Autocomplete, { AutocompleteProps } from '@/lib/ui/autocomplete.tsx';

const countries = getCountries();

export const countriesCodeMapOptions = countries.map((country) => ({
  label: country.name,
  value: country.name
}));

export function SelectCountry(props: Omit<AutocompleteProps, 'options'>) {
  return <Autocomplete {...props} options={countriesCodeMapOptions} />;
}
