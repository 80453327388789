import { toast } from 'sonner';
import { useMutation } from '@apollo/client';

import {
  CREATE_REPEAT_SESSION_MUTATION,
  CREATE_SESSION_MUTATION,
  DELETE_REPEAT_SESSION_MUTATION,
  DELETE_SESSION_MUTATION,
  UPDATE_ALL_SESSIONS_MUTATION,
  UPDATE_SESSION_MUTATION
} from '@/app/app/sessions/store/mutation/graphql/session.graphql';
import { GET_ALL_SESSIONS } from '@/app/app/sessions/store/query/graphql/session.graphql';
import { useCalendar } from '@/context/calendar-provider.tsx';
import { useAuth } from '@/app/auth/_context';
import { NewSessionFormType } from '@/app/app/sessions/common/types.ts';
import SessionRequestMapper from '@/app/app/sessions/common/api/mapper/session-request.mapper.ts';
import RepeatSessionRequestMapper from '@/app/app/sessions/common/api/mapper/repeat-session-request.mapper.ts';
import { SessionFragmentFragment } from '@/__generated__/graphql.ts';
import { useTranslation } from '@/hooks/useTranslation.ts';

export function useCreateSession() {
  const { t } = useTranslation();
  const { activeSpace } = useAuth();
  const { time, date } = useCalendar();

  const [onFunction, restProps] = useMutation(CREATE_SESSION_MUTATION, {
    awaitRefetchQueries: true,
    onCompleted: ({ createSession }) => {
      if (!createSession) {
        toast.error(t('errors_message.create_class'), {
          description: t('errors_message.unexpected_error')
        });
      }
    },
    onError: (error) => {
      toast.error(t('errors_message.create_class'), {
        description: error?.message
          ? t(
              'errors_message.original_error',
              `Original error: ${error?.message}`,
              {
                error: error?.message
              }
            )
          : t('errors_message.unexpected_error')
      });
    },
    refetchQueries: [
      {
        query: GET_ALL_SESSIONS,
        variables: {
          day: date,
          time,
          spaceId: activeSpace?.space?.id
        }
      }
    ]
  });

  const onCreateSession = async (data: NewSessionFormType) => {
    return onFunction({
      variables: new SessionRequestMapper(data, activeSpace?.space).toRequest()
    });
  };

  return {
    onCreateSession,
    onCreateFunction: onFunction,
    ...restProps
  };
}

export function useCreateRepeatSession() {
  const { activeSpace } = useAuth();
  const { t } = useTranslation();
  const { time, date } = useCalendar();

  const [onFunction, restProps] = useMutation(CREATE_REPEAT_SESSION_MUTATION, {
    awaitRefetchQueries: true,
    onCompleted: ({ createRepeatSession }) => {
      if (!createRepeatSession) {
        toast.error(t('errors_message.create_class'), {
          description: t('errors_message.unexpected_error')
        });
      }
    },
    onError: (error) => {
      toast.error(t('errors_message.create_class'), {
        description: error?.message
          ? t(
              'errors_message.original_error',
              `Original error: ${error?.message}`,
              {
                error: error?.message
              }
            )
          : t('errors_message.unexpected_error')
      });
    },
    refetchQueries: [
      {
        query: GET_ALL_SESSIONS,
        variables: {
          day: date,
          time,
          spaceId: activeSpace?.space?.id
        }
      }
    ]
  });

  const onCreateRepeatSession = (data: NewSessionFormType) =>
    onFunction({
      variables: new RepeatSessionRequestMapper(
        data,
        activeSpace?.space
      ).toRequest()
    });

  return {
    onCreateRepeatSession,
    ...restProps
  };
}

export function useUpdateRepeatSession() {
  const { activeSpace } = useAuth();
  const { t } = useTranslation();
  const { time, date } = useCalendar();

  const [onFunction, restProps] = useMutation(UPDATE_ALL_SESSIONS_MUTATION, {
    awaitRefetchQueries: true,
    onCompleted: ({ updateAllSessionForEventBackoffice }) => {
      if (!updateAllSessionForEventBackoffice) {
        toast.error(t('errors_message.update_class'), {
          description: t('errors_message.unexpected_error')
        });
      }
    },
    onError: (error) => {
      toast.error(t('errors_message.update_class'), {
        description: error?.message
          ? t(
              'errors_message.original_error',
              `Original error: ${error?.message}`,
              {
                error: error?.message
              }
            )
          : t('errors_message.unexpected_error')
      });
    },
    refetchQueries: [
      {
        query: GET_ALL_SESSIONS,
        variables: {
          day: date,
          time,
          spaceId: activeSpace?.space?.id
        }
      }
    ]
  });

  const onUpdateRepeatSession = async (
    session: SessionFragmentFragment,
    data: NewSessionFormType
  ) => {
    return onFunction({
      variables: new RepeatSessionRequestMapper(data).toEditRequest(session)
    });
  };

  return {
    onUpdateRepeatSession,
    ...restProps
  };
}

export function useDeleteSession() {
  const { activeSpace } = useAuth();
  const { t } = useTranslation();
  const { time, date } = useCalendar();

  const [onFunction, restProps] = useMutation(DELETE_SESSION_MUTATION, {
    awaitRefetchQueries: true,
    onCompleted: ({ updateSession }) => {
      if (!updateSession) {
        toast.error(t('errors_message.delete_class'), {
          description: t('errors_message.unexpected_error')
        });
      }
    },
    onError: (error) => {
      toast.error(t('errors_message.delete_class'), {
        description: error?.message
          ? t(
              'errors_message.original_error',
              `Original error: ${error?.message}`,
              {
                error: error?.message
              }
            )
          : t('errors_message.unexpected_error')
      });
    },
    refetchQueries: [
      {
        query: GET_ALL_SESSIONS,
        variables: {
          day: date,
          time,
          spaceId: activeSpace?.space?.id
        }
      }
    ]
  });

  const onDeleteSession = async (session: SessionFragmentFragment) => {
    return onFunction({
      variables: {
        id: session.id
      }
    });
  };

  return {
    onDeleteSession,
    ...restProps
  };
}

export function useDeleteRepeatSession() {
  const { activeSpace } = useAuth();
  const { t } = useTranslation();
  const { time, date } = useCalendar();

  const [onFunction, restProps] = useMutation(DELETE_REPEAT_SESSION_MUTATION, {
    awaitRefetchQueries: true,
    onCompleted: ({ deleteAllSessionForEventBackoffice }) => {
      if (!deleteAllSessionForEventBackoffice) {
        toast.error(t('errors_message.delete_class'), {
          description: t('errors_message.unexpected_error')
        });
      }
    },
    onError: (error) => {
      toast.error(t('errors_message.delete_class'), {
        description: error?.message
          ? t(
              'errors_message.original_error',
              `Original error: ${error?.message}`,
              {
                error: error?.message
              }
            )
          : t('errors_message.unexpected_error')
      });
    },
    refetchQueries: [
      {
        query: GET_ALL_SESSIONS,
        variables: {
          day: date,
          time,
          spaceId: activeSpace?.space?.id
        }
      }
    ]
  });

  const onDeleteRepeatSession = async (session: SessionFragmentFragment) => {
    return onFunction({
      variables: {
        id: session?.id
      }
    });
  };

  return {
    onDeleteRepeatSession,
    ...restProps
  };
}

export function useUpdateSession() {
  const { activeSpace } = useAuth();
  const { t } = useTranslation();
  const { time, date } = useCalendar();

  const [onFunction, restProps] = useMutation(UPDATE_SESSION_MUTATION, {
    awaitRefetchQueries: true,
    onCompleted: ({ updateSession }) => {
      if (!updateSession) {
        toast.error(t('errors_message.update_class'), {
          description: t('errors_message.unexpected_error')
        });
      }
    },
    onError: (error) => {
      toast.error(t('errors_message.update_class'), {
        description: error?.message
          ? t(
              'errors_message.original_error',
              `Original error: ${error?.message}`,
              {
                error: error?.message
              }
            )
          : t('errors_message.unexpected_error')
      });
    },
    refetchQueries: [
      {
        query: GET_ALL_SESSIONS,
        variables: {
          day: date,
          time,
          spaceId: activeSpace?.space?.id
        }
      }
    ]
  });

  const onUpdateSession = async (
    session: SessionFragmentFragment,
    data: NewSessionFormType
  ) => {
    return onFunction({
      variables: new SessionRequestMapper(data).toEditRequest(session)
    });
  };

  return {
    onUpdateSession,
    ...restProps
  };
}
