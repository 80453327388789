export enum UserStateEnum {
  INCOMPLETE = 'INCOMPLETE',
  ACTIVE = 'ACTIVE',
  DISABLED = 'DISABLED'
}

export const CLIENT_STATUS_COLOR: Record<string, ColorStatus> = {
  [UserStateEnum.ACTIVE]: 'success',
  [UserStateEnum.INCOMPLETE]: 'warning',
  [UserStateEnum.DISABLED]: 'danger'
};
