import {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useState
} from 'react';

type LocationContextType = {
  lang: 'en' | 'es';
  setLang: (lang: 'en' | 'es') => void;
};

const LocationContext = createContext<LocationContextType | null>(null);

export const LANG_KEY_STORE = '@lang';

export function LocationProvider({ children }: PropsWithChildren) {
  const [lang, setLang] = useState<LocationContextType['lang']>(() => {
    if (typeof window !== 'undefined') {
      const storedLang = (
        window.localStorage.getItem(LANG_KEY_STORE) ||
        navigator.language ||
        // @ts-expect-error
        navigator?.userLanguage
      )?.split?.('-')?.[0];

      if (storedLang) {
        return storedLang as LocationContextType['lang'];
      }
    }

    return 'es';
  });

  const onSetItem = useCallback((_lang: LocationContextType['lang']) => {
    window.localStorage.setItem(LANG_KEY_STORE, _lang);
    setLang(_lang);
  }, []);

  return (
    <LocationContext.Provider
      value={{
        lang,
        setLang: onSetItem
      }}
    >
      {children}
    </LocationContext.Provider>
  );
}

export function useLocation() {
  const context = useContext(LocationContext);

  if (!context) {
    throw new Error('useLocation must be used within a LocationProvider');
  }

  return context;
}
