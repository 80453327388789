import { gql } from '@/__generated__/gql.ts';

export const GET_COACHES_QUERY = gql(`
  query getCoaches($spaceId: ID!) {
    coaches(where: { space: { id: { equals: $spaceId } } }) {
      ...CoachFragment
    }
  }
`);

export const GET_COACH_BY_ID_QUERY = gql(`
  query getCoachById($coachId: ID!) {
    coach: coach(where: { id: $coachId }) {
      ...CoachFragment
    }
  }
`);

export const GET_SESSION_COACH_BY_ID_QUERY = gql(`
  query getSessionCoachById($coachId: ID!, $take: Int, $skip: Int, $date: DateTime) {
    totalCount: sessionsCount(where: { coach: { id: { equals: $coachId } }, finishedAt: { lte: $date } })

    sessions: sessions(where: { coach: { id: { equals: $coachId } }, finishedAt: { lte: $date } }, orderBy: [{ finishedAt: desc }], take: $take, skip: $skip) {
      ...SessionFragment
    }
  }
`);
