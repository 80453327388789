import { FormItem } from 'reactivity-hook-form';

import { useTranslation } from '@/hooks/useTranslation';
import { NewSessionFormType } from '@/app/app/sessions/common/types.ts';
import { Textarea } from '@/lib/ui/textarea.tsx';

export function DescriptionField() {
  const { t } = useTranslation();

  return (
    <FormItem<NewSessionFormType> name="summary">
      <Textarea
        minRows={8}
        placeholder={t('form_placeholder.summary')}
        label={t('form.summary')}
      />
    </FormItem>
  );
}
