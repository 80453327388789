import { useMemo } from 'react';
import { useSessionList } from '@/app/app/sessions/store/query/session.query';
import MonthSchedule from '@/app/app/sessions/components/sessions-list/month-schedule';
import WeekSchedule from '@/app/app/sessions/components/sessions-list/week-schedule';
import { EventSchedule } from '@/app/app/sessions/components/sessions-list/types';
import { useCalendar } from '@/context/calendar-provider.tsx';

export default function SessionsList() {
  const calendar = useCalendar();

  const { data, loading } = useSessionList();

  const events = useMemo<EventSchedule[]>(() => {
    return (data?.sessionsListBackoffice as EventSchedule[]) ?? [];
  }, [data?.sessionsListBackoffice]);

  return (
    <div className="flex flex-col gap-5">
      {calendar.time === 'WEEK' ? (
        <WeekSchedule events={events} loading={loading} />
      ) : (
        <MonthSchedule loading={loading} events={events} />
      )}
    </div>
  );
}
