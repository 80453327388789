import { Card, CardBody, CardHeader } from '@nextui-org/react';
import { cn } from '@/lib/utils.ts';
import Text from '@/lib/ui/text.tsx';
import { useGetActivityByCoach } from '@/app/app/dashboard/store/query/dashboard.query.ts';
import { useTranslation } from '@/hooks/useTranslation.ts';
import Title from '@/lib/ui/title.tsx';
import LinearChart from '@/lib/ui/charts/linear-chart.tsx';

export function ActivityByCoachGraph({ className }: { className?: string }) {
  const { t } = useTranslation();
  const { activity, loading } = useGetActivityByCoach();

  console.log(activity);

  return (
    <Card className={cn('relative block', className)}>
      <CardBody>
        <CardHeader className="flex-col items-start">
          <div className="flex gap-1 justify-between items-end">
            <Title size="lg" className="mb-0">
              {t('activity.title_by_coach')}
            </Title>
            {/*<Text className="text-sm mb-1">{dayjs().format('MMM, YYYY')}</Text>*/}
          </div>
          <Text className="mb-4">{t('activity.sub_title')}</Text>
        </CardHeader>

        <LinearChart
          loading={loading}
          name={t('classes')}
          {...activity}
        />
      </CardBody>
    </Card>
  );
}
